import { getAllByPlaceholderText, waitForElementToBeRemoved } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import { ReactSortable } from "react-sortablejs";

function getTemplate() {
    return {        
        content: "",
        value: undefined,
        isCorrect: false
    };
}

export default function TextContentList(props) {

    const [list, setNewList] = useState(props.list);

    const setChecked = (isChecked, value) =>  {
        let tmp = list.map( e => { 
            if ( e.value === value )
                e.isCorrect = isChecked;

            return e;    
        });
        setNewList(tmp);
        onChange();
    }

    const inputChanged = (content, value) =>  {
        let tmp = list.map( e => { 
            if ( e.value === value )
                e.content = content;

            return e;    
        });
        setNewList(tmp);
        onChange();
    }

    const removeItem = (value) => {
        let tmp = list.filter(x => x.value != value);
        setNewList(tmp);
        onChange();
    }

    const addRow = () => {
        
        let vals = list.map( x => x.value );
        if (list.length == 0 ) 
            vals = [0];
        let n = getTemplate();
        n.value = Math.max(...vals)+1;
        setNewList([...list, n]);
        onChange();
    }



    const onChange = () => {        
        if ( props.onChange != null ) {            
             props.onChange(list);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col">         

                    <ReactSortable list={list} setList={setNewList} >

                        {list.map((e, i) =>
                            <div key={e.value}>

                                <div className="sortable">
                                    <div className="row">
                                        <div className="col-2">
                                            <input type="text" className="form-control" readOnly defaultValue={e.value} />
                                        </div>
                                        <div className="col-7">
                                            <input type="text" className="form-control" placeholder="Answer content" defaultValue={e.content} onChange={(ev) => {inputChanged(ev.target.value,e.value )}} />
                                        </div>
                                        <div className="col-2">
                                            <input type="checkbox" value="1" id={e.value} onChange={(ev) => setChecked(ev.target.checked, e.value)} /> <label htmlFor={e.value}>Is correct</label><br />
                                        </div>
                                        <div className="col-1">
                                            <button className="btn btn-sm btn-primary" onClick={() => { removeItem(e.value) }}>x</button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        )}
                    </ReactSortable>
                </div>
            </div>
            
            <div className="row mb-3">
                <div className="col-1"></div>
                <div className="col">
                    <button onClick={addRow} className="btn btn-sm btn-primary">Add</button>                    
                </div>
            </div>            
            
        </>
    );

}