import React from "react";
import Form from "./Form";

export default function ChangePasswordPage() {
  return (
    <div>
      <Form/>
    </div>
  );
}
