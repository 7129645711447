import React, { useState } from 'react';
import ContentEditor from './ContentEditor';
export default function HeaderAndContent(props) { 

    const [data, setData] = useState( {header: props.header, content : props.content});

    const handleEditorChange = () => {
        props.onChange(data);
    }

    const editorOnChange = (value) => {
        let d = data;
        d.content = value;
        setData(d);

        handleEditorChange();
    }
    const inputOnChange = (e) => {        
        let d = data;
        d.header = e.target.value;
        setData(d);
        handleEditorChange();
    }

    return (
        <>
        <div className="col-md-12 grid-margin stretch-card">
            <h4>{props.title}</h4>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
            <input type="text" className="form-control"  defaultValue={props.header} onChange={inputOnChange} />
        </div>
        <div className="col-md-12 grid-margin stretch-card">
            <ContentEditor onChange={editorOnChange} content={props.content}/>
        </div>
        </>
    );
}