import React, { useState, useEffect, useContext } from 'react';
import BreadCrumb from '../../../../components/BreadCrumb';
import { useHistory } from "react-router-dom";
import { Insert } from '../../../../backend';
import {ParseQueryString} from '../../../../helpers/querystring';
import { TrainingContext } from '../../../../components/Contexts/TrainingContext';
import { CourseContext } from '../../../../components/Contexts/CourseContext';

export default function StructureForm() {
  
  const [sendToServer, setSendToServer] = useState(false);
  const [structure/*, setStructure*/] = useState({});  

  const training = useContext(TrainingContext);
  const course = useContext(CourseContext);

  // let params = useParams();
  let history = useHistory();

  useEffect(() => {
    async function save() {
      structure.courseId = course.id;
      
      let queryParts = ParseQueryString(history.location.search);  
      if (queryParts["parentStructureId"] != null ) {
        structure.parentStructureId = queryParts["parentStructureId"];
      } 
      var result = await Insert("/coursestructure", structure);
      if ( result.ok ) {
        history.push(`/training/${training.id}/course/${course.id}/structureform/${result.body.id}`);
        
      } else {
        alert("Someting failed while creating a new structure object");
      }
    }
    if ( sendToServer ) {
      save();
    }
  }, [sendToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onNameChange = function(e) {
    structure.name = e.target.value
  }

  const onTypeChange = function(e) {
    structure.type = e.target.value
  }

  const save = function(e) {
    setSendToServer(true);
  }
  
  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Create structure</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="col-md-12 grid-margin stretch-card">
                <h4>Content</h4>
              </div>
              <div className="col-md-12 grid-margin stretch-card">
                <input type="text" className="form-control"  defaultValue={structure.name} onChange={onNameChange} />
              </div>
              <div className="col-md-12 grid-margin stretch-card">
                <select className="form-control"  onChange={onTypeChange}>
                  <option>-- Choose --</option>
                  <option value="1">Content</option>
                  <option value="2">Task</option>
                  <option value="10">Advanced</option>
                  <option value="3">Result</option>
                </select>
              </div>
              <div className="col-md-12 grid-margin stretch-card">
                <button className="btn btn-primary" onClick={save}>Create</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}