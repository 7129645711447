import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,  
} from "react-router-dom";
import App from './App';
import Authentication from './components/Auth/Authentication';
import { UIProvider } from './components/Contexts/UIContext';
import { UserProvider } from './components/Contexts/UserContext';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <UIProvider>
          <Authentication>
            <App />
          </Authentication>
        </UIProvider>
      </UserProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to  register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
