import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'


let ls = window.localStorage.getItem("breadcrumbState");
let initialState = {training: null, course: null};

if ( ls != null ) {
    initialState = JSON.parse(ls);       
}

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);
export default Store;