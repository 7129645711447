import React from 'react'


export default function WidgetContainer(props) {

    
    return (
    <div className="widgetContainer">
        <div className="widgetHeader" onClick={props.resetContentType}>Change type</div>
        {props.children}
    </div>
    )

}