import React from 'react';
import {Link} from "react-router-dom";

export default function OverviewButton(props) {

    return (
        <Link to={{pathname: props.path}}>
            <button type="button" className="btn btn-sm btn-outline-info btn-icon-text">
            <i className="icon-list btn-icon-prepend"></i>Overview</button>
        </Link>
    );
  }