import React, { useState, useEffect, useContext } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
import { TrainingContext } from '../../../components/Contexts/TrainingContext';
import { Link } from "react-router-dom";
import { useCourse } from '../../../components/Contexts/CourseContext';

export default function Index(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [structure, setStructure] = useState([]);

    const training = useContext(TrainingContext);
    const course = useCourse();

    useEffect(() => {

        async function Load() {
            setIsLoading(true);
            let result = await Get(`/coursestructures/course/${course.id}`);
            setStructure(result.body);
            setIsLoading(false);
        }
        Load();

    }, [course.Id]);

    if (isLoading) {
        return <Loader />
    }
    const row = (idx, elem, depth = 0) => {

        let children = [];
        if (elem.children != null && elem.children.length > 0) {
            children = elem.children.map((child, idx) => {
                return row(idx, child, depth + 1)
            });
        }

        let addon = null;

        let editLink = <Link to={{ pathname: `/training/${training.id}/course/${course.id}/structureform/${elem.id}` }} className="text-dark ml-auto mb-3 mb-sm-0">
            <button type="button" className="btn btn-sm btn-outline-dark btn-icon-text"><i className="icon-doc btn-icon-prepend"></i>Edit</button>
        </Link>

        let childLink = <Link to={{ pathname: `/training/${training.id}/course/${course.id}/structureform/`, search: `parentStructureId=${elem.id}` }} className="text-dark ml-auto mb-3 mb-sm-0">
            <button type="button" className="btn btn-sm btn-outline-dark btn-icon-text"><i className="icon-doc btn-icon-prepend"></i>Add child</button>
        </Link>
        if (depth > 0) {
            addon = <div style={{ display: "inline-block", marginRight: "0.5em", transform: "translateY(-0.25rem)", borderLeft: "1px solid black", borderBottom: "1px solid black", width: "1rem", height: "1rem" }}></div>
            childLink = null;
        }

        let type = null;
        if (elem.structureType === 1) {
            type = <label className="badge badge-info">Material</label>
        } else if (elem.structureType === 2) {
            type = <label className="badge badge-warning">Task</label>
        } else if (elem.structureType === 3) {
            type = <label className="badge badge-success">Result</label>
        } else if (elem.structureType === 10) {
            type = <label className="badge badge-danger">Advanced</label>
        }

        return (
            <>
                <div className="row mt-3" style={{ borderBottom: "1px solid #e8ecf1" }}>

                    <div className="col-4">
                        {addon}
                        {idx + 1}. {elem.name}
                    </div>
                    <div className="col-4 text-left">
                        {type}
                    </div>
                    <div className="col-1">
                        {editLink}
                    </div>
                    <div className="col-2">
                        {childLink}
                    </div>
                </div>
                {children}
            </>
        )
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">{training.name} - {course.name} - Overview</h3>
                <BreadCrumb />
            </div>

            <div className="row">
                <div className="col-lg-6 grid-margin stretch-card">

                    <div className="card card-secondary">

                        <span className="card-body ">
                            <h4 className="card-title mb-sm-0">Links</h4>

                            <Link to={{ pathname: `/training/${training.id}/course/${props.course.id}/structureform` }} className="text-dark ml-auto mb-3 mb-sm-0">
                                <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text mt-2 d-block w-100"><i className="icon-people btn-icon-prepend"></i> Add course part</button>
                            </Link>

                            <Link to={{ pathname: `/training/${training.id}/course/${props.course.id}/structuresort` }} className="text-dark ml-auto mb-3 mb-sm-0">
                                <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text mt-2 d-block w-100"><i className="icon-equalizer btn-icon-prepend"></i> Sort</button>
                            </Link>

                            <Link to={{ pathname: `/training/${training.id}/course/${props.course.id}/form` }} className="text-dark ml-auto mb-3 mb-sm-0">
                                <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text mt-2 d-block w-100"><i className="icon-settings btn-icon-prepend"></i> Configure</button>
                            </Link>


                        </span>
                    </div>
                </div>

                <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card card-secondary">

                        <span className="card-body ">
                            <h4 className="card-title mb-sm-0"></h4>
                        </span>
                    </div>
                </div>

            </div>


            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                            {structure && structure.map((elem, idx) => {
                                return <div key={idx}> {row(idx, elem)}</div>
                            }
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}