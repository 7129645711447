import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

const containerStyle = {
  position: "relative",
};

const popupStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  width: "300px",
  heigth: "200px",
  backgroundColor: "#c2c2c2",
  border: "1px solid black",
  padding: "1rem",
};

export default function Popup(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [topOffset, setTopOffset] = useState(0);

  const popupRef = useRef(null);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useLayoutEffect(() => {
    if (popupRef.current === null) return;
    if (isOpen) {
      let bounds = popupRef.current.getBoundingClientRect();
      let bottom = bounds.y + bounds.height;
      let windowHeight = window.innerHeight;

      console.log(bottom, windowHeight);

      if (bottom > windowHeight) {
        popupRef.current.style.top = windowHeight - bottom + "px";
      }

      // console.log(windowHeight, popupRef.current.getBoundingClientRect());
    }
  }, [isOpen]);

  if (!props.isOpen) {
    return null;
  }

  const close = () => {
    setIsOpen(false);
    props.close();
  };

  return (
    <div style={containerStyle}>
      <div style={popupStyle} ref={popupRef}>
        <div style={{ textAlign: "right" }} onClick={close}>
          &#10006;
        </div>
        {props.children}
      </div>
    </div>
  );
}
