
import { createContext, useState } from 'react';
export const UIContext = createContext({
    refetchTrainings: false
});

export const UIProvider = ({children}) => {
    const [refetchTrainings, setRefetchTrainings] = useState(false);
    const response = {
        refetchTrainings,
        setRefetchTrainings
    };
  
    return (
      <UIContext.Provider value={response}>
        {children}
      </UIContext.Provider>
    )
  }