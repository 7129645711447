import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { Get, Insert, Update } from "../../../backend";
import StatusMessage from "../../../components/StatusMessage";

// import UserForm from './userform';

export default function Index() {
  const [isBusy, setIsBusy] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const errMessages = [];
    if (!formData.password) {
        errMessages.push("Password is empty")
    }
    if (!formData.newPassword1) {
        errMessages.push("New password is empty")
    }
    if (!formData.newPassword2) {
        errMessages.push("Confirm new password is empty")
    }
    setErrorMessages(errMessages);
    if (errMessages.length > 0) {
        return;
    }

    let data = {
        password: formData.password,
        newPassword1: formData.newPassword1,
        newPassword2: formData.newPassword2,
    };

    let r = await Insert("/me/change-password", data);
    if (r.ok) {
        setIsComplete(true);
    } else {
        setIsComplete(false);
        if (r.errors) {
            setErrorMessages(getServerErrors(r.errors));
        } else if (r.body) {
            setErrorMessages(getServerErrors(r.body));
        }
    }
  };

  function getServerErrors(errors) {
    const errMessages = [];
    for (let i in errors) {
        if (Array.isArray(errors[i])) {
            for (let j in errors[i]) {
                if (typeof errors[i][j] === 'string') {
                    errMessages.push(errors[i][j]);
                }
            }
        } else if (typeof errors[i] === 'string') {
            errMessages.push(errors[i]);
        }
    }
    return errMessages;
  }

  if (isBusy) {
    return <Loader />;
  }

//   if (isComplete) {
//     return (
//       <div className="content-wrapper">
//         <div className="page-header">
//           <h3 className="page-title">Change password</h3>
//           <BreadCrumb />
//         </div>
//         <div className="card">
//           Password changed!
//           <br />
//           Logout & login to see changes
//         </div>
//       </div>
//     );
//   }

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Change password</h3>
        <BreadCrumb />
      </div>
      <div className="card">
        <div className="card-body">
          {serverStatusMessage}
          <div className="row">
            <div className="col-6">
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Current password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Current password"
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                    }}
                    defaultValue={formData?.password}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">New password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword1"
                    name="newPassword1"
                    placeholder="New password"
                    onChange={(e) => {
                      setFormData({ ...formData, newPassword1: e.target.value });
                    }}
                    defaultValue={formData?.newPassword}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="apikey">Confirm new password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword2"
                    name="newPassword2"
                    placeholder="Confirm new password"
                    onChange={(e) => {
                      setFormData({ ...formData, newPassword2: e.target.value });
                    }}
                    defaultValue={formData?.newPassword2}
                  />
                </div>

                {isComplete && 
                    <div className="alert alert-success" role="alert">
                        Password changed!
                        <br />
                        Logout & login to see changes
                    </div>
                }
                {errorMessages && errorMessages.length > 0 && 
                    <div className="alert alert-danger" role="alert">
                        {errorMessages.map((x, idx) => {
                            return <div key={idx}>{x}</div>
                        })}
                    </div>
                }

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
