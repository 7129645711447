import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { Get, DownloadUsingGet, DownloadExcelUsingGet } from "../../backend";
import Loader from "../../components/Loader";

export default function Index() {
  // useEffect(() => {});

  return (
    <Page>
      <TrainingGroupsList />
      <TrainingsList />
      {/*<UserGroupsList /> */}
    </Page>
  );
}

const TrainingGroupsList = () => {
  const [rows, setRows] = useState([]);
  const [isBusy, setIsBusy] = useState(true);

  const [includeDeletedUsers, setIncludeDeletedUsers] = useState(true);

  useEffect(() => {
    async function load() {
      let data = await Get("/trainings/collections");
      if (data.ok) setRows(data.body);
      setIsBusy(false);
    }
    load();
  }, []);

  if (rows.length == 0) return null;

  return (
    <div>
      <h2 className="pt-2 pb-2">TrainingCollections</h2>
      <div className="mb-2">
        <input
          type="checkbox"
          checked={includeDeletedUsers}
          onChange={() => {
            setIncludeDeletedUsers(!includeDeletedUsers);
          }}
        />{" "}
        Include deleted users
      </div>
      {isBusy && <Loader />}
      {rows.map((row) => {
        return (
          <div key={row.id} className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-header d-block d-md-flex">
                  <h5 class="mb-0">{row.trainingCollection.name}</h5>
                  <small className="px-2">
                    ({row.trainings.map((x) => x.name).join(",")})
                  </small>
                </div>
                <div className="card-body">
                  <TrainingCollectionReport
                    trainingCollectionId={row.trainingCollection.id}
                    includeDeletedUsers={includeDeletedUsers}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TrainingCollectionReport = (props) => {
  const [showList, setShowList] = useState(false);
  const [rows, setRows] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const includeDeletedUsers = props.includeDeletedUsers;

  useEffect(() => {
    async function load() {
      setIsBusy(true);
      let data = await Get(
        `/reporting/getusersfortrainingcollection/${
          props.trainingCollectionId
        }?includeDeletedUsers=${includeDeletedUsers === true ? 1 : 0}`
      );
      setRows(data.body);
      setIsBusy(false);
    }
    load();
  }, [props.trainingCollectionId, includeDeletedUsers]);

  const downloadReport = () => {
    let data = DownloadExcelUsingGet(
      `/reportingdownload/download/getusersfortrainingcollection/${
        props.trainingCollectionId
      }?includeDeletedUsers=${includeDeletedUsers === true ? 1 : 0}`
    );
  };

  if (isBusy) {
    return <Loader />;
  }

  let numCompleted = 0;

  if (rows.length > 0) {
    numCompleted = rows.reduce((a, b) => {
      if (b.completedAt != null && b.completedAt.length > 1) {
        return a + 1;
      }
      return a;
    }, 0);
  }

  return (
    <div>
      <div class="row">
        <div class="col-2">Number of users</div>
        <div class="col">{rows.length}</div>
      </div>
      <div class="row">
        <div class="col-2">Training completed by</div>
        <div class="col">{numCompleted}</div>
      </div>

      <div class="row">
        <div class="col-2">User list</div>
        <div class="col">
          {" "}
          [
          <a
            className="text-danger pointer"
            onClick={() => {
              setShowList(!showList);
            }}
          >
            {!showList && <>Show</>}
            {showList && <>Hide</>}
          </a>
          ] [
          <a
            className="text-danger pointer"
            onClick={() => {
              downloadReport();
            }}
          >
            Download
          </a>
          ]
        </div>
      </div>

      {showList && (
        <div>
          <table className="mt-4 table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>
                  <strong>loginName</strong>
                </th>

                <th>
                  <strong>displayName</strong>
                </th>
                <th>
                  <strong>completedAt</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((e, idx) => {
                return (
                  <tr key={idx}>
                    {/* <td>{e.userId}</td> */}
                    <td>{e.loginName}</td>
                    <td>{e.displayName}</td>
                    <td>{e.completedAt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const TrainingsList = () => {
  const [rows, setRows] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [includeDeletedUsers, setIncludeDeletedUsers] = useState(true);
  useEffect(() => {
    async function load() {
      setIsBusy(true);
      let data = await Get("/trainings");
      setRows(data.body);
      setIsBusy(false);
    }
    load();
  }, []);

  return (
    <div>
      <h3>Trainings</h3>
      <div className="mb-2">
        <input
          type="checkbox"
          checked={includeDeletedUsers}
          onChange={() => {
            setIncludeDeletedUsers(!includeDeletedUsers);
          }}
        />{" "}
        Include deleted users
      </div>
      {isBusy && <Loader />}

      {rows.map((row) => {
        return (
          <div key={row.id} className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-header d-block d-md-flex">
                  <h5 class="mb-0">{row.name}</h5>
                </div>
                <div className="card-body">
                  <TrainingReport
                    trainingId={row.id}
                    includeDeletedUsers={includeDeletedUsers}
                  ></TrainingReport>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TrainingReport = ({ trainingId, includeDeletedUsers }) => {
  const [showList, setShowList] = useState(false);
  const [rows, setRows] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    async function load() {
      setIsBusy(true);
      let data = await Get(
        `/reporting/getusersfortraining/${trainingId}?includeDeletedUsers=${
          includeDeletedUsers ? "1" : "0"
        }`
      );
      setRows(data.body);
      setIsBusy(false);
    }
    load();
  }, [trainingId, includeDeletedUsers]);

  const downloadReport = () => {
    let data = DownloadExcelUsingGet(
      `/reportingdownload/download/getusersfortraining/${trainingId}?includeDeletedUsers=${
        includeDeletedUsers ? "1" : "0"
      }`
    );
  };

  if (isBusy) {
    return <Loader />;
  }

  let numCompleted = 0;

  if (rows.length > 0) {
    numCompleted = rows.reduce((a, b) => {
      if (b.completedAt != null && b.completedAt.length > 1) {
        return a + 1;
      }
      return a;
    }, 0);
  }

  return (
    <div>
      <div class="row">
        <div class="col-2">Number of users</div>
        <div class="col">{rows.length}</div>
      </div>
      <div class="row">
        <div class="col-2">Training completed by</div>
        <div class="col">{numCompleted}</div>
      </div>

      <div class="row">
        <div class="col-2">User list</div>
        <div class="col">
          {" "}
          [
          <a
            className="text-danger pointer"
            onClick={() => {
              setShowList(!showList);
            }}
          >
            {!showList && <>Show</>}
            {showList && <>Hide</>}
          </a>
          ] [
          <a
            className="text-danger pointer"
            onClick={() => {
              downloadReport();
            }}
          >
            Download
          </a>
          ]
        </div>
      </div>

      {showList && (
        <div>
          <table className="mt-4 table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>
                  <strong>loginName</strong>
                </th>
                <th>
                  <strong>displayName</strong>
                </th>
                <th>
                  <strong>companyName</strong>
                </th>
                <th>
                  <strong>personalName</strong>
                </th>
                <th>
                  <strong>completedAt</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((e, idx) => {
                return (
                  <tr key={idx}>
                    {/* <td>{e.userId}</td> */}
                    <td>{e.loginName}</td>
                    <td>{e.displayName}</td>
                    <td>{e.companyName}</td>
                    <td>{e.personalName}</td>
                    <td>{e.completedAt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const UserGroupsList = () => {
  const [rows, setRows] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    async function load() {
      setIsBusy(true);

      let data = await Get("/usergroups/withusercount");
      setRows(data.body);
      setIsBusy(false);
    }
    load();
  }, []);

  return (
    <div>
      <strong>User groups</strong>
      {isBusy && <Loader />}
      {rows.map((row) => {
        return <div key={row.id}>{row.name}</div>;
      })}
    </div>
  );
};
