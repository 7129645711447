
/**
 * Converts html hex color values and rgb(x,x,x) and rgba(x,x,x,x) values into object * 
 * 
 * Input example values:
 *  #ff00ff
 *  rgb(121,231,123);
 *  rgba(121,231,123,0.4); 
 * 
 * @param {color} value 
 * @returns {}
 */
export function parseColorValue(value) {
    let color = {
        r: 127,
        g: 120,
        b: 13,
        a: 1
    }

    if ( typeof value != 'string') {
        console.error("WAHT!?", value);
        return;
    }


    
    if (value.startsWith("#") && value.length === 7) { // convert hex
        var rhex = value.substr(1, 2);
        var ghex = value.substr(3, 2);
        var bhex = value.substr(5, 2);

        color.r = parseInt(rhex, 16);
        color.g = parseInt(ghex, 16);
        color.b = parseInt(bhex, 16);
        color.a = 1;
    }
    if (value.startsWith("rgba")) { // convert from rgba      
        value = value.replace(/\s/g, ''); // remove whitespaces.
        //let parts = value.match(/(\d*)\,(\d*),(\d*),(\d*\.?\d{1,5})/);
        let parts = value.match(/(\d*),(\d*),(\d*),(\d*\.?\d{1,5})/);
        if (parts.length > 3) {
            color.r = parts[1];
            color.g = parts[2];
            color.b = parts[3];
            color.a = parts[4];
        }
    } else if (value.startsWith("rgb")) {
        value = value.replace(/\s/g, ''); // remove whitespaces.
        let parts = value.match(/(\d*),(\d*),(\d*)/);
        if (parts.length > 3) {
            color.r = parts[1];
            color.g = parts[2];
            color.b = parts[3];
            color.a = 1;
        }
    }
    return color;
}

