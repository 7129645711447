import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { useParams } from "react-router-dom";
import { Get, Update, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function Index() {

  const [isLoading, setIsLoading] = useState(true);
  const [userGroup, setUserGroup] = useState({});
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);

  let params = useParams();

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      if (params.id != null) {
        let data = await Get(`/usergroup/${params.id}`);
        setUserGroup(data.body);
      }
      setIsLoading(false);
    }

    async function Save() {
      setIsLoading(true);

      let result = null;
      if (params.id != null) {
        result = await Update(`/usergroup/${params.id}`, userGroup);
      } else {
        result = await Insert(`/usergroup/`, userGroup);
      }

      setIsLoading(false);
      setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>);
    }
    if (updateToServer) {
      Save()
      setUpdateToServer(false);
    } else {
      Load();
    }
  }, [updateToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const nameOnChange = function (e) {
    userGroup.name = e.target.value;
  }

  const onSubmit = function (e) {
    e.preventDefault();
    setUpdateToServer(true);
  }

  if (isLoading) {
    return <Loader />
  }
  
  return (

    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">User form</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {serverStatusMessage}
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="groupname">Group Name</label>
                  <input type="text" className="form-control" id="groupname" placeholder="User group name" onChange={nameOnChange} defaultValue={userGroup.name} />
                </div>
                <button type="submit" className="btn btn-primary mr-2">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}