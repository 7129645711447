export function ParseQueryString(search) {

    let query = {};
    if (search.startsWith("?")) {
        search = search.substr(1);
    }
  
    let parts = search.split("&");
    if (parts != null && parts.length === 0) {
        return query;
    }


    for (let i = 0; i < parts.length; i++) {
        let pparts = parts[i].split("=");
        if (pparts !== null && pparts.length === 2) {
            query[decodeURIComponent(pparts[0])] = decodeURIComponent(pparts[1]);        
        }
    }
    return query;
}