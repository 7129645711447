import React, { useState, useEffect, useContext } from 'react';
import { ChromePicker } from 'react-color'
import { useParams } from "react-router-dom";
import { Insert, Get } from '../../../backend';
import Loader from '../../../components/Loader';
import Gradient from '../../../components/Gradient';
import StatusMessage from '../../../components/StatusMessage';
import { CourseContext } from '../../../components/Contexts/CourseContext';

// const iconPreview = {
//     marginTop: "10px",
//     border: "1px solid black",
//     background: "#c1c1c1",
//     width: "100px",
//     height: "100px"
// }


export default function ConfigurationTab() {
    // let params = useParams();

    const [configData, setConfigData] = useState({});
    const [updateToServer, setUpdateToServer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    //const [iconPreviewSrc, setIconPreviewSrc] = useState("");

    const course = useContext(CourseContext);

    const getSetting = function (key) {
        if (configData === null || configData === undefined )  return null;
        if (configData.data === null || configData.data === undefined ) return null;
        return configData.data[key];
    }

    // const setSetting = function (key, value) {
        
    //     if (configData.data === undefined || configData.data === null) {
    //         configData.data = {};
    //     } 
    //     configData.data[key] = value;
    // }

    useEffect(() => {
        
        async function Load() {

            setIsLoading(true);

            let data = await Get(`/configurations/course/${course.id}`);
            if (data.ok) {                
                setConfigData({ data: data.body });
                //setIconPreviewSrc(data.body.icon);
            }
            setIsLoading(false);
        }

        async function Save() {

            setIsLoading(true);            
            let result = await Insert("/configurations/courses", configData);

            if (result.ok) {
                setServerStatusMessage(<StatusMessage success={true}>Save ok</StatusMessage>)
            } else {
                setServerStatusMessage(<StatusMessage success={false}>Save failed</StatusMessage>)
            }
            setIsLoading(false);
            setUpdateToServer(false);
        }

        if (updateToServer) {
            Save();
        }
        if (course.id) {
            Load();
        }
    }, [updateToServer, course.id]);  // eslint-disable-line react-hooks/exhaustive-deps


    // const onChangeComplete = function (color) {
    //     var colorString = "rgba(" + color.rgb.r + ", " + color.rgb.g + ", " + color.rgb.b + ", " + color.rgb.a + ")";
    //     setSetting("themeColor", colorString);
    // }

    // const gradientOnChange = function (values) {
    //     let gradientParts = values.map(x => x.color + " " + x.offset * 100 + "%");
    //     let gradientString = "linear-gradient(180deg, " + gradientParts.join(",") + ")";
    //     setSetting("themeGradient", gradientString);
    // }

    // const iconPathOnChange = function (e) {
    //     let v = e.target.value;
    //     setSetting("icon", v);
    //     setIconPreviewSrc(v);
    // }

    // const parseToGradientArray = function (input) {
    //     if (input === undefined || input === null) return [];

    //     const regexp = /(rgba(.*?)%)/g;
    //     let parts = [...input.matchAll(regexp)];
    //     let gradients = [];

    //     if (parts.length > 0) {
    //         for (let i = 0; i < parts.length; i++) {
    //             let s = parts[i][0];
    //             if (s.lastIndexOf(" ") > -1) {
    //                 let color = s.substring(0, s.lastIndexOf(" "));
    //                 let pos = s.substring(color.length);
    //                 gradients.push({ offset: (parseInt(pos, 10) / 100), color: color });
    //             }
    //         }
    //     }
    //     return gradients;
    // }

    const onSubmit = function (e) {
        e.preventDefault();
        // collect settings
        let settings = {
            showResultAfterTask: e.target.showResultAfterTask.value === "1" ? true : false,
            showResultAfterCourse: e.target.showResultAfterCourse.value === "1" ? true : false,
            showTaskResultBeforeCourseResult : e.target.showTaskResultBeforeCourseResult.value === "1" ? true : false,
            allowWrongAnswers: e.target.allowWrongAnswers.value  === "1"? true : false,
            storeAnswers: e.target.storeAnswers.value  === "1" ? true : false,
            themeColor: getSetting("themeColor"),
            themeGradient: getSetting("themeGradient"),
            icon: getSetting("icon")
        }

        let data = {
            courseId: course.id,
            data: settings
        }

        setConfigData(data);
        setUpdateToServer(true);
    }

    if (isLoading) {
        return <Loader />
    }

    let showResultAfterTask  = getSetting('showResultAfterTask');
    let showResultAfterCourse = getSetting('showResultAfterCourse');
    let showTaskResultBeforeCourseResult = getSetting('showTaskResultBeforeCourseResult');
    let allowWrongAnswers     = getSetting('allowWrongAnswers');
    let storeAnswers          = getSetting('storeAnswers');

    return (
        <>
            {serverStatusMessage}
            <form onSubmit={onSubmit}>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Show result after task</legend>
                        <div className="col-sm-10">
                            <div className="form-check  mt-0">

                                <input className="form-check-input" type="radio" name="showResultAfterTask" id="showResultAfterTask_1" value="1" defaultChecked={showResultAfterTask} />
                                <label className="form-check-label" htmlFor="showResultAfterTask_1">Yes</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="showResultAfterTask" id="showResultAfterTask_0" value="0" defaultChecked={!showResultAfterTask} />
                                <label className="form-check-label" htmlFor="showResultAfterTask_0">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Show result after course</legend>
                        <div className="col-sm-10">
                            <div className="form-check  mt-0">
                                <input className="form-check-input" type="radio" name="showResultAfterCourse" id="showResultAfterCourse_1" value="1" defaultChecked={showResultAfterCourse} />
                                <label className="form-check-label" htmlFor="showResultAfterCourse_1">Yes</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="showResultAfterCourse" id="showResultAfterCourse_0" value="0" defaultChecked={!showResultAfterCourse} />
                                <label className="form-check-label" htmlFor="showResultAfterCourse_0">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Show task result before course result</legend>
                        <div className="col-sm-10">
                            <div className="form-check  mt-0">

                                <input className="form-check-input" type="radio" name="showTaskResultBeforeCourseResult" id="showTaskResultBeforeCourseResult_1" value="1" defaultChecked={showTaskResultBeforeCourseResult} />
                                <label className="form-check-label" htmlFor="showTaskResultBeforeCourseResult_1">Yes</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="showTaskResultBeforeCourseResult" id="showTaskResultBeforeCourseResult_0" value="0" defaultChecked={!showTaskResultBeforeCourseResult} />
                                <label className="form-check-label" htmlFor="showTaskResultBeforeCourseResult_0">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Allow wrong answers</legend>
                        <div className="col-sm-10">
                            <div className="form-check  mt-0">
                                <input className="form-check-input" type="radio" name="allowWrongAnswers" id="allowWrongAnswers_1" value="1" defaultChecked={allowWrongAnswers} />
                                <label className="form-check-label" htmlFor="allowWrongAnswers_1">Yes</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="allowWrongAnswers" id="allowWrongAnswers_0" value="0" defaultChecked={!allowWrongAnswers} />
                                <label className="form-check-label" htmlFor="allowWrongAnswers_0">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Store answers</legend>
                        <div className="col-sm-10">
                            <div className="form-check  mt-0">
                                <input className="form-check-input" type="radio" name="storeAnswers" id="storeAnswers_1" value="1" defaultChecked={storeAnswers} />
                                <label className="form-check-label" htmlFor="storeAnswers_1">Yes</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="storeAnswers" id="storeAnswers_0" value="0" defaultChecked={!storeAnswers} />
                                <label className="form-check-label" htmlFor="storeAnswers_0">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                {/* <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Icon</legend>
                        <div className="col-sm-10">
                            <input type="text" name="iconpath" defaultValue={getSetting("icon")} onChange={iconPathOnChange} />

                            <div style={iconPreview}>
                                <img alt="icon preview" src={iconPreviewSrc} style={{ "width": "100%" }} />
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Theme Color</legend>
                        <div className="col-sm-10">
                            <ChromePicker color={getSetting("themeColor") ? getSetting("themeColor") : "#ffffff"} onChange={onChangeComplete} />
                        </div>
                    </div>
                </fieldset>

                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-form-label col-sm-2 pt-0">Theme Gradient</legend>
                        <div className="col-sm-10">
                            <Gradient onChange={gradientOnChange} value={parseToGradientArray(getSetting("themeGradient"))} />
                        </div>
                    </div>
                </fieldset> */}

                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </form>
        </>
    );
}