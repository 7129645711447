import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
// import { useParams } from "react-router-dom";
import { Upload,Get } from '../../../backend';
import Loader from '../../../components/Loader';
// import StatusMessage from '../../../components/StatusMessage';

export default function Index() {

  const [isLoading, setIsLoading] = useState(false);    
  // const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);
  const fileUpload = useRef(null);
  const [uploadData, setUploadData] = useState(null);
  const [userGroups, setUserGroups] = useState("");

  const [action, setAction] = useState("");
  const [importResult, setImportResult] = useState([]);
  // let params = useParams();

  useEffect(() => {     
    async function Save() {
      if ( action === "preview") {      
        let r = await Upload("/importusers?act=preview", uploadData);
        
        if ( r.ok ) {          
          setImportResult(r.body);
        }
        
      }
      if ( action === "save") {      
        let r = await Upload("/importusers?act=import", uploadData);
        if ( r.ok ) {
          setImportResult(r.body);
        }
      }
      setAction("");
      setUpdateToServer(false);
    } 
    
    async function load() {
      setIsLoading(true);
      let data = await Get("/usergroups");
      setUserGroups(data.body);
      setIsLoading(false);
    }
     load();
    
    if (updateToServer && uploadData !== null ) {
      setIsLoading(true);
      Save();
      setIsLoading(false);
    }

  }, [updateToServer, uploadData,action]); // eslint-disable-next-line react-hooks/exhaustive-deps

  


  const fileChanged = (e) => {    
    const formData = new FormData(); 
    formData.append( 
        "files", 
        e.target.files[0], 
        e.target.files[0].name 
      );       
      setAction("preview");
      setUploadData(formData);
      setUpdateToServer(true);
  }

  const submit = (e) => {
    e.preventDefault();
    setAction("save");
    setUpdateToServer(true);
  }

  // if ( isLoading ) {
  //   return <Loader />
  // }
  
  let rows = [];  
  
  if (isLoading) {
    rows = <Loader />
  }

  if (importResult.length > 0 ) {
    rows = [];
    rows = importResult.map(
      (e,i) => {  
        return (
        <div className="row">
          <div className="col">{e.item1}</div>
          <div className="col">{e.item2}</div>
          <div className="col">{e.item3}</div>
        </div>
        )
      }
    );
  }

  let userGroupSelect;
  if( userGroups !== undefined && userGroups.length > 0 ) {    
    let options = userGroups.map((obj,idx) => {
      return (
          <option value={obj.id} key={idx}>{obj.name}</option>
      );
    });
    userGroupSelect = <select className="form-control" >{options}</select>  
  }

  return (

    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Import users</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* {serverStatusMessage} */}
              <form className="forms-sample" onSubmit={submit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">User group</label>
                  {userGroupSelect}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">File (xls, xlsx)</label>
                  <input type="file" className="form-control" ref={fileUpload} onChange={fileChanged}/>
                </div>

                {/* <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Contains header row</label>
                  
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                    <label className="form-check-label" for="exampleRadios1">
                      Data contains a header row
                      <small>Ignore first row on import</small>
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                    <label className="form-check-label" for="exampleRadios2">
                      Data does not contain a header row
                    </label>
                  </div>
                </div> */}
                <button type="submit" className="btn btn-primary mr-2">Submit</button>                                
              </form>
            </div>
            
            {rows}

          </div>          
        </div>
      </div>      
    </div>
  );
}