import React from 'react';

export default function DateDisplay(props) {
    if ( !props.children || props.children.length < 10 ) 
        return null;

    let dateString = props.children;
    let d = new Date(dateString);

    let short = d.getDate() + "." + (d.getMonth()+1) + "." + d.getFullYear();
    let long = short + " " + d.getHours().toString().padStart(2,"0") + ":" + d.getMinutes().toString().padStart(2,"0") + ":" + d.getSeconds().toString().padStart(2,"0");
    return <div title={long}>{d.getDate()}.{d.getMonth()+1}.{d.getFullYear()}</div>
}