import React, { useState, useEffect, useContext } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { useHistory, useParams } from "react-router-dom";
import { Get, Delete,Update } from '../../../backend';
import Loader from '../../../components/Loader';
import TemplateContentManager from '../../../components/TemplateContentManager';
import Content from './Content';
import Task from './Task';
import StructureDataContainer from './StructureDataContainer';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TrainingContext } from '../../../components/Contexts/TrainingContext';
import { CourseContext } from '../../../components/Contexts/CourseContext';


export default function StructureForm() {
    const [deleteCourseStructure, setDeleteCourseStructure] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [structure, setStructure] = useState({});

    const [updateCourse, setUpdateCourse] = useState(false);


    const training = useContext(TrainingContext);
    const course = useContext(CourseContext);

    let params = useParams();
    let history = useHistory();

    useEffect(() => {
        
        async function doDeleteRequest() {
            let result = await Delete(`/coursestructure/${params.structureId}`);
            if (result.ok) {
                history.push(`/training/${training.id}/course/${course.id}/overview/`);
            } else {
                alert("Delete failed"); // TODO: Fix this !
            }
        }

        async function Load() {            
            setIsLoading(true);            
            let data = await Get(`/coursestructures/${params.structureId}`);
            if (data.ok) {
                setStructure(data.body);
            } else {
                // do some error handling
            }
            setIsLoading(false);
        }

        async function SaveTemplateContentId( ) {            
            setIsLoading(true);
            let data = {} //structure;            
            data.structureId = structure.id;
            data.name = structure.name;
            data.templateContentId = structure.templateContentId;
            let result = await Update(`/coursestructure`,data);
            setUpdateCourse(false);
            setIsLoading(false);
        }

        if (updateCourse) {
            SaveTemplateContentId();

        } else {
            if (deleteCourseStructure && params.structureId != null) {
                doDeleteRequest();
            }
        } 
        
        
        Load();
        
    }, [params.structureId, deleteCourseStructure, updateCourse]); // eslint-disable-next-line react-hooks/exhaustive-deps




    const deleteStructureOnClick = function () {
        confirmAlert({
            title: 'Delete?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setDeleteCourseStructure(true);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const onContentSave = (templateContentId) => {        
        structure.templateContentId = templateContentId;
        setUpdateCourse(true);
    }

    const updateName = () => {
        setUpdateCourse(true);
    }


    let content = "Content not available!";
    let type = "";
    if (isLoading) {
        return <Loader />
    } else {
        if (parseInt(structure.structureType, 10) === 1) {
            content = <div>                
                <TemplateContentManager contentId={structure.templateContentId} onContentSave={onContentSave} ></TemplateContentManager>
                <Content contentId={structure.structureTypeId} useOverridenWithTemplateContentManagerWarning={true}></Content>
            </div>
            type = "Material";
        }
        else if (parseInt(structure.structureType, 10) === 2) {
            content = <Task structure={structure} onContentSave={onContentSave} ></Task>
            type = "Task";
        } else if (parseInt(structure.structureType, 10) === 10) {
            content = <StructureDataContainer structure={structure} onContentSave={onContentSave} ></StructureDataContainer>
            type = "Advanced";
        }
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">Structure : {type}</h3>
                <BreadCrumb />
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                        <div className="form-group row">
                            
                                <label className="col-sm-2 col-form-label">Name</label>
                                <div className="col-sm-8">
                                    <input className="form-control" defaultValue={structure.name} onChange={(e) => { structure.name = e.target.value}}/>
                                </div>
                                <div className="col-sm-2">
                                    <button onClick={updateName} className="btn btn-secondary">Update name</button>
                                </div>
                            
                        </div>
                            {content}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <button className="btn btn-danger" onClick={deleteStructureOnClick}>
                        Delete structure
                    </button>
                </div>
            </div>
        </div>
    );
}