import React, { useState, useEffect } from 'react';
import ContentEditor from "../../../../components/ContentEditor";
import { Get, Update } from '../../../../backend';
import Loader from '../../../../components/Loader';

export default function Index(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [sendToServer, setSendToServer] = useState(false);

    const useObsoleteWarningMessage = props.useOverridenWithTemplateContentManagerWarning ?? false;

    const [obsoleteFormVisible, setObsoleteFormVisible] = useState(false);


    const [content, setContent] = useState({});
    useEffect(() => {

        async function Load() {
            setIsLoading(true);
            let data = await Get(`/content/${props.contentId}`);             
            setContent(data.body);
            setIsLoading(false);
        }

        async function Save() {
            setIsLoading(true);
            let data = content;
            let response = await Update(`/content/${props.contentId}`, data);             
            if( response.ok )
            {

            } else {

            }
             
            setIsLoading(false);
        }
        if( sendToServer) {
            setIsLoading(true);            
            Save();
            setSendToServer(false);
            setIsLoading(false);
        }
        Load();        
    }, [props.contentId, sendToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

    const onInputChange = function(e) {
        content.header = e.target.value;
    }

    const onEditorChange = function(e) {
        content.htmlContent = e;    
    }
    const save = function() {
        setSendToServer(true)
    }

    if(isLoading) {
        return <Loader/>
    }

    if ( useObsoleteWarningMessage && (content.header?.length == 0 && content.htmlContent?.length == 0))
        return "";

    let warningMessage = '';

    if( useObsoleteWarningMessage ) {
        warningMessage = <div className="col-md-12 grid-margin stretch-card">
          <strong>This editor is obsolete. Use TemplateContentManager tool!</strong>
        </div>

        if (!obsoleteFormVisible) {
            return (
                <>
                    {warningMessage}                                           
                    <button onClick={ () => {setObsoleteFormVisible(true)}}>Toggle old editor</button>
                </>
            );
        }


        return (
            <>
                {warningMessage}                            
                <div className="col-md-12 grid-margin stretch-card">
                    <input type="text" className="form-control"  defaultValue={content.header} onChange={onInputChange} />
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <ContentEditor content={content.htmlContent} onChange={onEditorChange} />
                </div>
                {!useObsoleteWarningMessage && <button onClick={save} className="btn btn-primary">Update</button>}
            </>
        );



    }

    return (
        <>
        <div className="col-md-12 grid-margin stretch-card">
          <input type="text" className="form-control"  defaultValue={content.header} onChange={onInputChange} />
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <ContentEditor content={content.htmlContent} onChange={onEditorChange} />
        </div>
        <button onClick={save} className="btn btn-primary">Update</button>
        </>
    );

}