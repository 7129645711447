import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { UploadFileBlob } from '../backend';
import FileBrowserModal from './FileBrowserModal';

function eduapi_image_upload_handler(blobInfo, success, failure, progress) {
    ; (async () => {
        let r = await UploadFileBlob(blobInfo, "/");
        if (r.ok) {
            success(r.body.path);
        } else {
            failure(r.body.error, { remove: true });
        }
    })();
};

function isYoutubeUrl(url) {
    let match = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;  /// https://stackoverflow.com/questions/2964678/jquery-youtube-url-validation-with-regex
    return url.match(match);
}
function isVimeoUrl(url) {
    let match = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    return url.match(match);
}


function isVideoUrl(url) {

    if ( isYoutubeUrl(url)) return true;
    if ( isVimeoUrl(url)) return true;    

    return false;
} 

function getVideoEmbed(url) {
    return '<div class="iframeContainer"><iframe title="Video player" src="'+url+'" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>';
}

function handlePaste(args) {
    if ( isVideoUrl(args.content) ) {
        args.content = getVideoEmbed(args.content);
    } 
    return args;
}

export default function ContentEditor(props) {

    const [fileBrowser, setFileBrowser] = useState(null);

    const handleEditorChange = (content) => {
        props.onChange(content);
    }

    async function filebrowser(callback, value, meta) {       
        setFileBrowser(null); 
        setFileBrowser(<FileBrowserModal onSelectFile={(e) => {callback(e); setFileBrowser(null);}} visible={true} />)     
    }

    return <div style={{ width: '100%' }}>
        {fileBrowser}
        
        <Editor
            apiKey='f32avfy6q6wjjkcj49cy60a9fuzeqtfx56p7gn6xwsoxra7h'
            content={props.content}
            initialValue={props.content}

            init={{
                // setup: function(editor) {
                //     editor.on('init', function(e) {
                //       console.log('The Editor has initialized.');
                //     });
                //   },
                //   init_instance_callback: function(editor) {
                //     editor.on('ExecCommand', function(e) {
                //         if ( e.command === 'mceInsertContent')
                //             handleMceInsertContentCommand(editor, e);
                //     });
                //   },
                

                
                height: 500,
                menubar: props.menu??true,
                paste_data_images: true,
                images_reuse_filename: true,
                images_upload_handler: eduapi_image_upload_handler,
                file_picker_callback: async function (callback, value, meta) {                    
                    await filebrowser(callback, value, meta);
                },


                paste_preprocess: function (plugin, args) {
                    args = handlePaste(args)                    
                },
                media_url_resolver: function (data, resolve/*, reject*/) {
                    if ( isVideoUrl(data.url) ) {
                        resolve({html: getVideoEmbed(data.url)});
                    } else {
                        resolve({html: ''});  
                    }
                  },



                // audio_template_callback: function(data) {
                //     return '<p>VIIDEO<video width="' + data.width + '" height="' + data.height + '"' + (data.poster ? ' poster="' + data.poster + '"' : '') + ' controls="controls">\n' + '<source src="' + data.source + '"' + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n' + (data.altsource ? '<source src="' + data.altsource + '"' + (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') + ' />\n' : '') + '</video></p>';
                // },
                //  file_picker_callback: function(callback, value, meta) {
                //   // Provide file and text for the link dialog
                //   if (meta.filetype == 'file') {
                //     callback('mypage.html', {text: 'My text'});
                //   }

                //   // Provide image and alt text for the image dialog
                //   if (meta.filetype == 'image') {
                //     callback('myimage.jpg', {alt: 'My alt text'});
                //   }

                //   // Provide alternative source and posted for the media dialog
                //   if (meta.filetype == 'media') {
                //     callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
                //   }
                // },
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor media',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | sourcecode | code | removeformat | image | media | help'
            }}
            onEditorChange={(newValue,editor) => handleEditorChange(newValue)}
        ></Editor>
    </div>
}
