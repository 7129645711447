import React, { useState, useEffect, useRef, Suspense } from 'react'
import { Get, Insert } from '../../backend';

import Loader from '../../components/Loader';
import Empty from './Widgets/Empty.js'
import WidgetContainer from './Widgets/WidgetContainer.js'
import './styles.css';
import { get } from 'sortablejs';

export const CONTENT_TYPES = [
    {
        type:'Html',
        name:'Content editor', 
        icon: '/assets/vendors/bootstrap-icons-1.5.0/card-heading.svg'
    },
    {
        type:'Image',
        name:'Single image',        
        icon: '/assets/vendors/bootstrap-icons-1.5.0/image.svg'
    },
    {
        type:'ImageCollection',
        name:'Collection of images',        
        icon: '/assets/vendors/bootstrap-icons-1.5.0/images.svg'
    },
    {
        type:'TextRow',
        name:'Single line text',        
        icon: '/assets/vendors/bootstrap-icons-1.5.0/file-font.svg'
    }
];



/**
 * @type TemplateContentManager  
 * @property {string} [contentId=null] - Load content using contentId 
 * @property {Function} [onContentSave] - Set a callback function
 * triggered after saving. Returns ID of the content 
 */
export default function Index(props) {
   
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [contentId, setContentId] = useState(props.contentId);
    const [contents, setContents] = useState([]);

    const [template, setTemplate] = useState([]);
    const [templateId, setTemplateId] = useState(null);

    useEffect(() => {
        async function Load() {            
            setIsLoading(true);

            let result = await Get(`/cms/templatecontent/${contentId}`);
            if (result.ok) {
                setContentId(result.body.id);
                setTemplateId(result.body.templateid);
                setContents(JSON.parse(result.body.content));
            }
            setIsLoading(false);
        }

        if (isLoading || (contentId != null && contentId.length > 30)) {
            Load();
        }

    }, [])
    //}, [props.contentId, isLoading])

    useEffect(() => {
        async function Save() {
            let data = {};
            let jsonString = JSON.stringify(contents);

            if (contentId == null)
                data = {
                    content: jsonString,
                    templateId: templateId
                }
            else
                data = {
                    id: contentId,
                    content: jsonString,
                    templateId: templateId
                }

            let result = await Insert("/cms/templatecontent", data);
            if (result.ok) {
                if (typeof props.onContentSave == 'function') {
                    props.onContentSave(result.body.id);
                }
            }
            setIsSaving(false);
        }
        if (isSaving) {
            Save();
        }
    }, [isSaving])





    // if ( template == null ) {
    //     return <div>Template code is null. Has the template been removed?</div>
    // }

    const setContentType = (contentPlaceId, contentType) => {

        let data = [...contents];

        let n = data.find(x => {
            if (contentPlaceId == x.id)
                return x;
        });

        if (n == undefined) {
            data.push({ id: contentPlaceId, contentType: contentType });
        } else {
            n.contentType = contentType;
        }

        setContents(data);
    }

    const resetContentType = (contentPlaceId) => {
        setContentType(contentPlaceId, null);
    }

    const onSubmit = () => {
        setIsSaving(true);
    }

    const onChange = (contentPlaceId, contentType, content) => {

        let tmp = contents.map(x => {
            if (x.id == contentPlaceId) {
                x.content = content;
                setIsDirty(true);
            }
            return x;
        });
    }

    const onTemplateChanged = (tmplObj) => {
        setTemplate(tmplObj.template);
        setTemplateId(tmplObj.id);
        setIsDirty(true);
    }


    if (typeof props.onContentSave != 'function') {
        return <strong>props.onContentSave function not passed</strong>
    }



    if (isLoading || isSaving)
        return <Loader />


    if ( !template ) {
        return "<p>Template json in invalid<p>";
    }    



    if (!Array.isArray(template) ) {
        return (
            <div className="templateContentEditor">
                <TemplateSelector onTemplateChanged={onTemplateChanged} templateId={templateId}></TemplateSelector>
                <p>Template json is not an array</p>
            </div>
        );
    }    


    return (
        <div className="templateContentEditor">
            <TemplateSelector onTemplateChanged={onTemplateChanged} templateId={templateId}></TemplateSelector>
            {template && template.map((r, idx) => {
                return <Row key={idx} columns={r.columns} content={contents} setContentType={setContentType} onChange={onChange} resetContentType={resetContentType}></Row>
            })}

            {isDirty && <button className="btn btn-primary" onClick={onSubmit}>Save</button>}
        </div>
    )
}

export function Row(props) {

    const style = {
        display: "flex",
    }

    return (
        <div style={style}>
            {props.columns.map((c) => {
                let storedContent = props.content.find(x => x.id == c.id);
                return <Column key={c.id} column={c} content={storedContent} setContentType={props.setContentType} onChange={props.onChange} resetContentType={props.resetContentType}></Column>
            })}
        </div>
    )
}

export function Column(props) {

    const renderWidget = (contentPlaceId, contentType, content) => {
        const Component = React.lazy(() => import(`./Widgets/${contentType}.js`));

        return (
            <Suspense fallback={<div>Loading...</div>}>
                <WidgetContainer resetContentType={() => props.resetContentType(contentPlaceId)} >
                    <Component onChange={(e) => props.onChange(contentPlaceId, contentType, e)} content={content} />
                </WidgetContainer>
            </Suspense>
        )
    }

    const style = {
        width: props.column.width,
        margin: '0.5rem',
    }

    if (props.content?.contentType == null) {
        return (
            <div style={style}>
                <div className="column">
                    <Empty onChange={(name) => props.setContentType(props.column.id, name)}></Empty>
                </div>
            </div>
        )
    }

    return (
        <div style={style}>
            <div className="column">
                {renderWidget(props.column.id, props.content.contentType, props.content.content)}
            </div>
        </div>
    )
}

export function TemplateSelector(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    //const [template, setTemplate] = useState(null);
    const [templateId, setTemplateId] = useState(props.templateId);
    const tmpl = useRef(null);

    useEffect(() => {
        async function Load() {
            let result = await Get("/cms/templates");
            if (result.ok) {
                setTemplates(result.body);
            }
            setIsLoading(false);
        }
        Load();
    }, [])

    useEffect(() => {
        async function LoadTemplate() {
            if (templateId != null) {
                let templateResult = await Get(`/cms/template/${templateId}`);
                if (templateResult.ok) {
                    //setTemplate(JSON.parse(templateResult.body.templateJson));
                    if (typeof props.onTemplateChanged == 'function') {
                        props.onTemplateChanged({ id: templateId, template: JSON.parse(templateResult.body.templateJson) });
                    }
                }
            }
        }
        LoadTemplate();
    }, [templateId]);

    const onChange = (e) => {
        let idx = e.target.options.selectedIndex;
        let id = e.target.options[idx].value;
        setTemplateId(id);
    }
    if ( isLoading ) 
        return <Loader></Loader>


    return (
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <label htmlFor="title">Template</label>
                    <select ref={tmpl} className="form-control" id="template" onChange={onChange} value={templateId??""}>
                        {templateId == null && <option value="">Choose template</option>}                        
                        {templates.map(x => {
                            return <option value={x.id} key={x.id}>{x.name}</option>
                        })}
                    </select>                    
                </div>
            </div>
        </div>
    )
}