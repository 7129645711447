import React from 'react';

import EditButton from '../../../components/EditButton';
import OverviewButton from '../../../components/OverviewButton';
export default function CourseListRow(props) {

  return ( 
          <tr>
            <td>{props.index}</td>
            
            <td> {props.data.name}</td>            
            <td className="text-right">
              <EditButton path={`/training/${props.training.id}/course/${props.data.id}/form`} />
              &nbsp;
              <OverviewButton path={`/training/${props.training.id}/course/${props.data.id}/overview`} />
            </td>
          </tr>
        
  );
}