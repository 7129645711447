export default function Progress(props) {
  let style = {
    width: props.value + "%",
  };

  let color = props.completed ? "bg-primary" : "bg-danger";
  let classes = "progress-bar " + color;

  return (
    <div className="progress">
      <div
        className={classes}
        role="progressbar"
        aria-valuenow={props.value}
        aria-valuemin="0"
        aria-valuemax="100"
        style={style}
      ></div>
    </div>
  );
}
