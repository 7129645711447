import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Get, Update } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function DetailsTab() {

  const [isLoading, setIsLoading] = useState(true);
  const [training, setTraining] = useState({});

  const [, setPublishDate] = useState(new Date())
  const [, setUnPublishDate] = useState(new Date())

  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);

  let params = useParams();

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let data = await Get(`/training/${params.trainingId}`);  
      
      
      setTraining(data.body);
      setIsLoading(false);
    }

    async function Save() {
      setIsLoading(true);
      training.sorting = parseInt(training.sorting, 10);

      let result = await Update(`/training/${params.trainingId}`, training);
      if (result.ok) {
        setServerStatusMessage(<StatusMessage success={true}>Save ok</StatusMessage>)
      } else {
        setServerStatusMessage(<StatusMessage success={false}>Save failed</StatusMessage>)
      }
      setIsLoading(false);
    }
    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    }

    Load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateToServer]); 

  if (isLoading) {
    return <Loader />
  }

  const onSubmit = function (e) {
    e.preventDefault();
    setUpdateToServer(true);
  }

  const fixDateValue = function (value) {
    return new Date(value);
  }
  const dateTimeToString = function (date) {

    let y = date.getFullYear();
    let m = (date.getMonth() + 1).toString().padStart(2, '0');
    let d = date.getDate().toString().padStart(2, '0');

    let h = date.getHours().toString().padStart(2, '0');
    let i = date.getMinutes().toString().padStart(2, '0');
    let s = date.getSeconds().toString().padStart(2, '0');

    return y + "-" + m + "-" + d + "T" + h + ":" + i + ":" + s;
  }


  const changePublishDate = function (e) {
    setPublishDate(e);    
    
    training.publishDate = dateTimeToString(e);
    setTraining(training)
  }

  const changeUnPublishDate = function (e) {
    setUnPublishDate(e);
    training.unpublishDate = dateTimeToString(e);
    setTraining(training)
  }

  const changeName = function (e) {
    training.name = e.target.value;
    setTraining(training)
  }
  const changeSorting = function (e) {
    training.sorting = e.target.value;
    setTraining(training)
  }

  return (
    <>
      {serverStatusMessage}

      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input type="text" className="form-control" id="name" placeholder="name" onChange={changeName} defaultValue={training.name} />
      </div>

      <div className="form-group">
        <label htmlFor="name">Sorting</label>
        <input type="number" className="form-control" id="sorting" placeholder="name" onChange={changeSorting} defaultValue={training.sorting} />
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label>Publish at</label>
            <div>
              <DatePicker dateFormat="dd.MM.yyyy" onChange={changePublishDate} selected={fixDateValue(training.publishDate)} />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label>Unpublish at</label>
            <div>
              <DatePicker dateFormat="dd.MM.yyyy" onChange={changeUnPublishDate} selected={fixDateValue(training.unpublishDate)} />
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={onSubmit} className="btn btn-primary mr-2">Submit</button>
    </>
  );
}