import React, {useState, useEffect} from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert, Update, Delete } from '../../../backend';
import Loader from '../../../components/Loader';
import { useParams, Redirect } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; 
import JSONEditor from '../../../components/JsonEditor';
import Help from '../../../components/Help';

export default function Index(props) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isRemovingComplete, setIsRemovingComplete] = useState(false);
    const [template,setTemplate] = useState({});
    const [templateObj, setTemplateObj] = useState({});
    const [dirty,setDirty] = useState(false);

    let params = useParams();

    useEffect(() => {
        async function load() {
        
            let result = await Get(`/cms/template/${params.templateId}`);
            if ( result.ok ) {
                setTemplate(result.body);
                setTemplateObj(JSON.parse( result.body.templateJson ));
            }                         
            setIsLoading(false);
        }
        load();
    },[]) 

    useEffect( () => {
        async function save() {
            let result = null;
            let data = {
                name:template?.name,
                templateJson:JSON.stringify(templateObj)
            }
            if(template?.id != undefined &&template?.id != null)
                result = await Update(`/cms/template/${template.id}`, data);
            else
                result = await Insert(`/cms/template`,data);
             
            if( result.ok ) {
                setTemplate(result.body);
                setTemplateObj(JSON.parse(result.body.templateJson));
            }
        
            setDirty(false);
            setIsSaving(false);
        }
        if (dirty)
            save();
    }, [isSaving] );

    useEffect( () => {
        async function remove() {
            if (template?.id != undefined ) {
                let result = await Delete(`/cms/template/${template.id}`);
                setIsRemovingComplete(true);
            }
        }
        if(isRemoving)
            remove();
    }, [isRemoving]); 


    const onSave = (e) => {
        e.preventDefault();
        setIsSaving(true);
    }

    const onDelete = (e) => {
        e.preventDefault();
         
        confirmAlert({
            title: 'Delete?',
            message: 'Are you sure to do this. This cannot be undone!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsRemoving(true);
                    }
                },
                {
                label: 'No',
                }
        ]
        });
    }

    const onNameChange = (e) => {
        setDirty(true);
        template.name = e.target.value;
    }
    const onEditorChange = (e) => {
        setDirty(true);
        setTemplateObj(e);
    }

    if(isLoading || isSaving) {
        return <Loader />
    }
    if(isRemovingComplete) {
        return <Redirect to="/settings/templates"/>;
    }
    
    return (
        <>
        <form>
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">Settings </h3>
                    <BreadCrumb />
                </div>            
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <div className="float-right"><TemplateOptionsHelp /></div>
                                <h4 className="card-title">Template</h4>
                                

                                <fieldset className="form-group">
                                    <div className="row">
                                        <legend className="col-form-label col-sm-2 pt-0">Template name</legend>
                                        <div className="col-sm-10">              
                                            <input type="text" className="form-control" defaultValue={template.name}  onChange={onNameChange}/>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="form-group">
                                    <div className="row">
                                        <legend className="col-form-label col-sm-2 pt-0">Data11</legend>
                                        <div className="col-sm-10">
                                            <JSONEditor value={templateObj} onChange={onEditorChange}/>
                                            {/* <Editor value={templateObj}  ace={ace} theme="ace/theme/github" mode={Editor.modes.code} onChange={onEditorChange}/> */}
                                            
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col">
                                        {dirty&&<button type="button" className="btn btn-primary mr-2" onClick={onSave}>Save</button>}
                                    </div>
                                    {template?.id != undefined && <div className="col text-right"><button type="button" className="btn btn-danger mr-2" onClick={onDelete}>Delete page</button></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </form>        
        </>
    );
}

export function TemplateOptionsHelp() {

    let help = `
[
    {
        "options": {
        "width": "ROW_SIZE_ENUM.FULL"
        },
        "columns": [
        {
            "id": 1,
            "width": "100%"
        }
        ]
    },
    {
        "options": {
        "width": "ROW_SIZE_ENUM.FULL"
        },
        "columns": [
        {
            "id": 2,
            "width": "50%"
        },
        {
            "id": 3,
            "width": "50%"
        }
        ]
    }
]
    `;


    return (
        <Help>
            <p>Template json</p>
            <pre>            
                {help}                       
            </pre>
        </Help>
    );
}

