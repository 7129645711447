import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { Get, DownloadUsingGet, DownloadExcelUsingGet } from "../../backend";
import Loader from "../../components/Loader";

export default function Index() {
  useEffect(() => {});

  return (
    <Page header="Training collections">
      <div>No management tools yet.</div>
    </Page>
  );
}
