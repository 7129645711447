
import React, { useState, useEffect, useRef } from 'react';
import './autocomplete.css';


export default function AutoComplete(props) {
    const inputRef = useRef(null);
    const [list,] = useState(props.list);

    const [doNotListItems, setDoNotListItems] = useState(props.ignore || []);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect( () => {
        setDoNotListItems(props.ignore);
    }, [props.ignore]); 


    const selectItem = (idx) => {
        let item = searchResults[idx];
        if ( typeof(props.onSelect) ===  'function') {
            props.onSelect(item);
        }
        clearAll();
    }

    const buildAutoComplete = (e) => {        
        let searchString = e.target.value.toString();
        if (searchString === null || searchString.length === 0 ) {
            setSearchResults([]);
            return;
        }
        let found = list.filter( (val) => {
            let label = val.label.toString();
            if ( label.toLowerCase().includes(searchString.toLowerCase())) {
                if ( doNotListItems.find( x => x.id === val.id )) {
                    return false;
                }
                return true;
            }
            return false;
        });
        setSearchResults(found);        
    }

    const setCaretToEnd = () => {
        if ( inputRef.current != null ) {
            let len = inputRef.current.value.length;
            if (inputRef.current.setSelectionRange) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(len, len);
            }
        }
    }

    const clearAll = () => {
        if ( inputRef.current != null ) {
            inputRef.current.value = "";
        }
        setSelectedIndex(-1);
        setSearchResults([]);
    }


    const onKeyUp = (e) => {
        let newIndex;
        switch(e.key) {
            case "Escape" :             
                clearAll();                
            break;
            case "Enter" :                             
                selectItem(selectedIndex);
            break;
            case "ArrowUp" : 
                setCaretToEnd();                
                newIndex = selectedIndex - 1;                
                if ( newIndex < 0 ) 
                    newIndex = searchResults.length - 1;
                setSelectedIndex(newIndex);
                break;
            case "ArrowDown" : 
                newIndex = selectedIndex + 1;
                if ( newIndex > searchResults.length -1 ) 
                    newIndex = 0;
                setSelectedIndex(newIndex);
                break;
            default:
                break;
        }
    }

    if (list === null || list.length === 0 ) {
        return <p>Nothing to show</p>
    }

    let classNames = "results";
    if ( searchResults.length > 0 ) 
        classNames += " has-results";

    return (
        <div className="autocomplete">
            <div className="search">
                <input ref={inputRef} type="text" className="form-control" placeholder={props.label} onBlur={clearAll} onChange={(e) => {e.preventDefault(); buildAutoComplete(e)}} onKeyUp={(e) => {e.preventDefault(); onKeyUp(e)}}></input>
            </div>
            <div className={classNames}>
                {searchResults.map((e, idx) => {
                    if (idx === selectedIndex) {
                        return (
                            <div key={e.id} onClick={() => selectItem(idx)} className="selected">
                                {e.label}
                            </div>
                        )
                    } else {
                        return (
                            <div key={e.id} onClick={() => selectItem(idx)}>
                                {e.label}
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )

}