import React from 'react';
import { Route, Switch } from "react-router-dom";
import List from './List';
import Form from './Form';
import Overview from './Overview';
import SortCourses from './SortCourses';
import CreateNewTrainingForm from './CreateNewTrainingForm';
export default function TrainingsPage() {

  return (
    <div>
      <Switch>
        <Route path="/trainings/form/:trainingId?">
          <Form />
        </Route>
        <Route path="/trainings/overview/:trainingId?">
          <Overview />
        </Route>
        <Route path="/trainings/sortcourses/:trainingId?">
          <SortCourses />
        </Route>
        <Route path="/trainings/create">
          <CreateNewTrainingForm />
        </Route>
        <Route path="/trainings">
          <List />
        </Route>
      </Switch>
    </div>
  );
}