import React, { useState, useEffect } from 'react';
import TabContainer from '../../../../components/TabContainer';
import { Get } from '../../../../backend';
import Content from '../Content';

import Loader from '../../../../components/Loader';
import JsonEditor from './JsonEditor';
import TemplateContentManager from '../../../../components/TemplateContentManager';
export default function Index(props) {
    
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        async function Load() {
            setIsLoading(true);
            let data = await Get(`/CourseStructureDataContainer/${props.structure.structureTypeId}`);                                     
            setData(data.body);            
            setIsLoading(false);
        }
        Load();
    }, [props.structure.structureTypeId]);

    if ( isLoading ) {
      return <Loader />;
    }
    return (
        <>

          <TabContainer>
            <div TabName="Content" TabId="1" TabActive={true} key="1">
              <TemplateContentManager contentId={data.templateContentId} onContentSave={props.onContentSave} ></TemplateContentManager>
              <Content useOverridenWithTemplateContentManagerWarning={true}  contentId={data.contentId} /> 
            </div>
            {/* <Content TabName="Content" TabId="1" TabActive={true} key="1" contentId={data.contentId} />  */}
            <JsonEditor TabName="Data" TabId="2" TabActive={false} key="2" data={data}  /> 
          </TabContainer>
        </>
    );
}