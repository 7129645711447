import React, {useState, useEffect} from 'react';
import FileBrowser from './FileBrowser';
import './FileBrowserModal.css'



export default function FileBrowserModal(props) {
    
    const [visible,setVisible] = useState(props.visible);
    
    useEffect( () => {
            setVisible(props.visible)
    }, [props.visible])


    const mySelectFile = function() {
        props.onSelectFile(...arguments);
        setVisible(false);        
    }
    
    const onClose = function() {        
        setVisible(false);
    }
    
    if( !visible) {
        return null;
    }

    return (
        <div style={{display: visible?'block':'none'}}className="file-browser-modal">
             <span className="modal-close" onClick={onClose}><i className="icon-close"></i></span>
             <FileBrowser onSelectFile={mySelectFile} {...props} />
         </div>
    );
}
