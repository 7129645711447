import React, { useEffect, useState } from 'react';
import Pages from './Pages';
import { Link } from "react-router-dom";

export default function Index(props) {

    const createRootPage = '/content/form';

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">Content management</h3>                
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 ">
                            <h4 className="card-title">Pages</h4>
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{width: "80%"}}>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Pages />
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <Link to={createRootPage}>
                                <button type="button" className="btn btn-sm btn-outline-info btn-icon-text">
                                    <i className="icon-doc btn-icon-prepend"></i>Create new page
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}