import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./Nav";
import { useUser } from "./components/Auth/Authentication";

import DashboardPage from "./containers/DashboardPage";
import TrainingsPage from "./containers/TrainingsPage";
import UsersPage from "./containers/UsersPage";
import UserGroupsPage from "./containers/UserGroupsPage";
import CoursesPage from "./containers/CoursesPage";
import CMSPage from "./containers/CMSPage";
import TenantSettingsPage from "./containers/TenantSettingsPage";
import TaskEditor from "./containers/CoursesPage/StructureForm/Task/Generator/TaskDataGenerator";
import ReportingPage from "./containers/ReportingPage";
import FileBrowserPage from "./containers/FileBrowserPage";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb";
import ManagementUsersPage from "./containers/ManagementUsersPage";
import TenantPage from "./containers/TenantPage";
import { tenantContext } from "./components/Auth/Authentication";
import { setActiveTenant } from "./backend";
import ScrollToTop from "./hooks/ScrollTopTop";
import { UserContext } from "./components/Contexts/UserContext";
import ChangePasswordPage from "./containers/ChangePasswordPage";
import ReportingMainPage from "./containers/ReportingMainPage";
import TrainingCollectionsPage from "./containers/TrainingCollections";

function App() {
  //const userDetails = useUser();
  const { user } = useContext(UserContext);

  const [showTenantDropDown, setShowTenantDropDown] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const tenantCtx = useContext(tenantContext);

  const toggleTenantDropDown = function () {
    setShowTenantDropDown(!showTenantDropDown);
  };

  const toggleMobileNav = function () {
    setShowMobileNav(!showMobileNav);
  };
  const selectTenant = function (tenantId) {
    tenantCtx.setTenantId(tenantId);
    let tenant = user.tenants.find((x) => x.id == tenantId);
    setActiveTenant(tenant);
    setRedirectTo("/");
  };

  if (redirectTo != null) {
    window.location = "/";
    return null;
  }

  return (
    // <Router>
    <>
      <ScrollToTop />
      <div className="container-scroller">
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="navbar-brand-wrapper d-flex align-items-center">
            <a className="navbar-brand brand-logo" href="/">
              <img
                src="/assets/images/logo.png"
                alt="Taitaen logo"
                className="logo-dark"
              />
            </a>
            <a className="navbar-brand brand-logo-mini" href="/">
              <img src="/assets/images/logo.png" alt="Taitaen logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">
            {/* <h5 className="mb-0 font-weight-medium d-none d-lg-flex">
              Welcome to Taitaen
            </h5> */}
            <ul className="navbar-nav navbar-nav-right ml-auto">
              {/* <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="icon-basket-loaded"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="icon-chart"></i>
                </a>
              </li> */}
              <li className="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  onClick={toggleTenantDropDown}
                >
                  <span className="font-weight-normal">
                    {tenantCtx.tenant.name}
                  </span>
                </span>

                <div
                  className={
                    showTenantDropDown
                      ? "dropdown-menu dropdown-menu-left navbar-dropdown py-2 show"
                      : "dropdown-menu dropdown-menu-left navbar-dropdown py-2"
                  }
                  aria-labelledby="LanguageDropdown"
                >
                  {user.tenants.map((obj, idx) => {
                    return (
                      <span
                        className="dropdown-item"
                        onClick={() => selectTenant(obj.id)}
                        key={idx}
                      >
                        <i className="icon-home"></i> {obj.name}
                      </span>
                    );
                  })}
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
              onClick={toggleMobileNav}
            >
              <span className="icon-menu"></span>
            </button>
          </div>
        </nav>

        <div className="container-fluid page-body-wrapper">
          <BreadCrumb>
            <Nav user={user} mobileNavVisible={showMobileNav} />
            <div className="main-panel">
              <Switch>
                <Route exact path="/" component={DashboardPage} />
                <Route path="/settings" component={TenantSettingsPage} />
                <Route path="/trainings" component={TrainingsPage} />
                <Route
                  path="/training/:trainingId/course"
                  component={CoursesPage}
                />
                <Route path="/users" component={UsersPage} />
                <Route path="/usergroups" component={UserGroupsPage} />
                <Route exact path="/reporting" component={ReportingMainPage} />
                <Route
                  path="/reporting/:trainingId"
                  component={ReportingPage}
                />
                <Route path="/filebrowser" component={FileBrowserPage} />
                <Route path="/test" component={TaskEditor} />
                <Route path="/content" component={CMSPage} />
                <Route
                  path="/managementusers"
                  component={ManagementUsersPage}
                />
                <Route
                  path="/trainingcollections"
                  component={TrainingCollectionsPage}
                />
                <Route path="/tenant" component={TenantPage} />
                <Route path="/change-password" component={ChangePasswordPage} />
                <Route path="*">
                  <p>404 - not found (App.js)</p>
                </Route>
              </Switch>
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2017{" "}
                    <a
                      href="https://www.bootstrapdash.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bootstrap Dash
                    </a>
                    . All rights reserved.
                  </span>
                  <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                    Hand-crafted & made with{" "}
                    <i className="icon-heart text-danger"></i>
                  </span>
                </div>
              </footer>
            </div>
          </BreadCrumb>
        </div>
      </div>
      {/* </Router> */}
    </>
  );
}

export default App;
