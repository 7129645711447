var baseUrl = process.env.REACT_APP_API_END_POINT;

// function getTenant() {
//     if (window.tenant != null) {
//         return window.tenant;
//     }

//     let ls = window.localStorage.getItem("userState");
//     let data = JSON.parse(ls);
//     if (data != null) {
//         window.tenant = data.tenant;
//         return window.tenant;
//     } else {
//         return null;
//     }
// }

// function getTenantId() {
//     let tenant = getTenant();

//     if ( tenant != null)
//         return tenant.id;
//     return "";
// }

// function storeToken(tokenString) {
//     window.managerToken = tokenString;
//     window.localStorage.setItem("token", tokenString);
// }

async function setActiveTenant(tenant) {
  window.activeTenant = tenant;
  window.localStorage.setItem("activeTenant", JSON.stringify(tenant));

  let result = await Get("/me/getacl");
  // .catch(() => {
  //     return false;
  // });
  // console.log('setActiveTenantRESULTTI', result)
  if (!result.ok) {
    return false;
  }
  let aclList = [];
  if (result.ok) aclList = result.body;

  window.tenantACL = aclList;
  return true;
}

async function getActiveTenant() {
  if (window.activeTenant == undefined || window.activeTenant == null) {
    let tenant = JSON.parse(window.localStorage.getItem("activeTenant"));
    if (tenant != null) {
      const result = await setActiveTenant(tenant);
      if (result) {
        return tenant;
      }
      return false;
    }
    return null;
  } else {
    return window.activeTenant;
  }
}

function getTenantId() {
  if (window.activeTenant === undefined || window.activeTenant === null) {
    console.error("Active tenant is null");
    return null;
  }

  return window.activeTenant?.id;
}

function getToken() {
  if (
    window.managerUserDetails != null &&
    window.managerUserDetails.token != null
  ) {
    return window.managerUserDetails.token;
  } else {
    let userDetails = JSON.parse(
      window.localStorage.getItem("manageruserdetails")
    );
    if (userDetails == null) {
      return null;
    }
    return userDetails.token;
  }
  return null;
}

function getUser() {
  if (window.managerUserDetails != null) {
    return window.managerUserDetails;
  } else {
    let userDetails = JSON.parse(
      window.localStorage.getItem("manageruserdetails")
    );
    if (userDetails == null) {
      return null;
    }
    return userDetails;
  }
  return null;
}

async function storeUserDetails(userDetails) {
  window.managerUserDetails = userDetails;
  window.localStorage.setItem(
    "manageruserdetails",
    JSON.stringify(userDetails)
  );
}

function logout() {
  window.managerUserDetails = null;
  window.localStorage.clear(); // fix this!!!!!
  //window.location = "/logout";
}

window._unAuthorizedApiCallCount = 0;

async function unifyResponse(response) {
  if (response === undefined) {
    return response;
  }
  if (response.status === 401) {
    window._unAuthorizedApiCallCount++;
    if (window._unAuthorizedApiCallCount > 10) {
      console.log("Unauthorized request. Status = 401. ");
      window.localStorage.clear(); // fix this!!!!!
      window.location = "/logout";
    }
    let r = {
      ok: false,
      status: 401,
      message: "Unauthorized",
    };
    return r;
  }

  if (response.status === 404) {
    //alert("Endpoint not found!!!");
  }

  let r = {};
  r.ok = response.ok;
  r.status = response.status;
  r.body = null;
  r.errors = null;
  if (response.body != null) {
    try {
      var json = await response.json();
      if (json.errors) {
        r.errors = json.errors;
      }
      r.body = json;
    } catch (err) {
      r.body = null;
    }
  }
  return r;
}

async function Login(user, password) {
  let data = { username: user, password: password };

  const result = await fetch(baseUrl + "/authentication", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).catch(() => {
    return false;
  });

  if (result && result.status == 200) {
    await storeUserDetails(await result.json());
    return true;
  }
  return false;

  // let result = await Insert("/authentication", { username: user, password: password });
  // if (result.status === 200) {
  //     await storeUserDetails(result.body);
  //     return true;
  // }
  // return false;
}

async function Get(url) {
  const response = await fetch(baseUrl + url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  }).catch(() => {});
  return await unifyResponse(response);
}

async function Update(url, data) {
  data.TenantId = getTenantId();
  const response = await fetch(baseUrl + url, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return await unifyResponse(response);
}

async function Upload(url, data) {
  data.TenantId = getTenantId();
  const response = await fetch(baseUrl + url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: data,
  });
  return await unifyResponse(response);
}

async function Post(url, data) {
  return await Insert(url, data);
}

async function Insert(url, data) {
  data.TenantId = getTenantId();
  const response = await fetch(baseUrl + url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return await unifyResponse(response);
}

async function DownloadUsingGet(url) {
  var filename = "unknown.csv";
  await fetch(baseUrl + url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
  })
    //.then(response => response.blob())
    .then((response) => {
      if (response.status === 200) {
        // response.headers.forEach(console.log)

        let d = response.headers.get("content-disposition");
        if (d != null || d.length > 0) {
          // filename = d.match("filename=\(.*?)\;")[1];
          filename = d.match("filename=(.*?);")[1];
        }
        return response.blob();
      }
    })
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}

async function DownloadExcelUsingGet(url) {
  var filename = "unknown.xlsx";
  await fetch(baseUrl + url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
  })
    //.then(response => response.blob())
    .then((response) => {
      if (response.status === 200) {
        // response.headers.forEach(console.log)

        console.log("daata", response.headers);

        console.log(response.data);
        console.log(response.headers["Content-Disposition"]);

        // let d = response.headers.get("Content-Disposition");
        // console.log('deeep', d)
        // if (d != null || d.length > 0) {
        //     // filename = d.match("filename=\(.*?)\;")[1];
        //     filename = d.match("filename=(.*?);")[1];
        // }
        return response.blob();
      }
    })
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      //a.download = filename;
      a.download = "testi.xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}

async function Delete(url, data) {
  if (data == null) {
    data = {};
  }
  data.TenantId = getTenantId();
  const response = await fetch(baseUrl + url, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return await unifyResponse(response);
}

async function UploadFileBlob(file, path) {
  let url = "/file";

  let postData = new FormData();
  if (typeof file.blob === "function") {
    postData.append("file", file.blob(), file.filename());
  } else {
    postData.append("file", file);
  }
  postData.append("path", path);

  const response = await fetch(baseUrl + url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: "Bearer " + getToken(),
      "X-Tenant-Id": getTenantId(),
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: postData,
  });

  return await unifyResponse(response);
}

export {
  Get,
  Post,
  Insert,
  Update,
  Delete,
  Upload,
  Login,
  DownloadUsingGet,
  DownloadExcelUsingGet,
  UploadFileBlob,
  getToken,
  getUser,
  setActiveTenant,
  getActiveTenant,
  logout,
};
