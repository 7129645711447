import React, { useState } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './TabContainer.css';

export default function TabContainer(props) {
    if (props.children.length != null && props.children.length > 0 ) {
        return (
            <div className="card">
                <div className="card-body">
                    <Tabs>
                        <TabList>
                            {props.children.map((child) => {
                                return <Tab key={child.props.TabId}>{child.props.TabName}</Tab>
                            })}
                        </TabList>
                        {props.children.map((child) => {
                            return <TabPanel key={child.props.TabId} >{child}</TabPanel>
                        })}
                    </Tabs>
                </div>            
            </div>
        )
    } else {
        return (
        <div className="card">
            <div className="card-body">
                {props.children}
            </div>
        </div>
        )
    }
}

// export default function TabContainer(props) {
//     const [activeTab, setActiveTab] = useState("");

//     const selectTab = function(e) {        
//         setActiveTab(e);
//     }
//     if ( props.children == null ) {
//         return <div>TabContainer has no children</div>
//     }

//     if (activeTab === "" && props.children.length > 0 ) {        
//         setActiveTab(props.children[0].props.TabId)
//     }

//     let tabs = [];
//     let tabContents = [];
//     if (props.children.length != null && props.children.length > 0 ) {
//         for( let x in props.children ) {
//             let isActive = true;
//             if ( props.children.length > 1 ) {
//                 isActive = activeTab === props.children[x].props.TabId;
//             }
//             tabs.push(
//                 <li className="nav-item" key={x}>
//                     <span className={isActive?'nav-link active':'nav-link'} onClick={() => selectTab(props.children[x].props.TabId)}>{props.children[x].props.TabName} </span>
//                 </li>
//             ) 
//             tabContents.push(
//                 <div className={isActive?'card-body':'card-body d-none'}  key={x}>
//                     {props.children[x]}
//                 </div>
//             );
//         }
//     } else {
//         tabs.push(
//             <li className="nav-item" key="1">
//                 <span className='nav-link active' onClick={() => selectTab(props.children.props.TabId)}>{props.children.props.TabName} </span>
//             </li>
//         ) 
//         tabContents.push(
//             <div className='card-body' key="1">
//                 {props.children}
//             </div>
//         );
//     }

//     return (
//         <div className="card">
//             <ul className="nav nav-tabs">
//                 {tabs}            
//             </ul>
//             {tabContents}
//           </div>
//     );
//   }