import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import AutoComplete from '../../../components/Autocomplete';


export default function Index() {

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [availableUserGroups, setAvailableUserGroups] = useState([]);    
    const [registrationCodes, setRegistrationCodes] = useState([]);
    const [registrationEnabled, setRegistrationEnabled] = useState(false);
    const [serverStatusMessage, setServerStatusMessge] = useState("");
    useEffect(() => {
        async function LoadUserGroups() {
            setIsLoading(true);
            let result = await Get("/usergroups");
            if (result.ok) {
                let userGroupsForAutoComplete = result.body.map(
                    (e) => {
                        return {
                            id: e.id,
                            label: e.name
                        }
                    }
                );
                setAvailableUserGroups(userGroupsForAutoComplete);
            }            
            setIsLoading(false);
        }

        async function LoadPreviousData() {            
            setIsLoading(true);
            let result = await Get("/configurations/gettenantregistrationoptions");
            if (result.ok) {
                if ( result.body != null ) {
                    if( result.body.RegistrationEnabled != null)                
                        setRegistrationEnabled(result.body.RegistrationEnabled);
                    if( result.body.RegistrationCodes != null) 
                        setRegistrationCodes(result.body.RegistrationCodes.map((e) => {
                            let tmp = e;
                            tmp._internalId = Math.random() * 1000
                            return tmp;
                        } ));
                }
            }
            setIsLoading(false);            
        }
        
        LoadUserGroups();
        LoadPreviousData();
        
    }, []);

    useEffect( () => {

        async function save() {
            let data = {
                RegistrationEnabled: registrationEnabled,
                RegistrationCodes: registrationCodes
            }

            let result = await Insert("/configurations/settenantregistrationoptions",data);
            setServerStatusMessge(<StatusMessage serverResponse={result} />)
            setIsSaving(false);
        }


        if ( isSaving) {
            save();
        }
    }, [isSaving, registrationCodes, registrationEnabled]); 


    const onRegistrationRowUpdate = (newData) => {
        let temp = registrationCodes;

        for( let i=0;i<temp.length;i++) {
            if ( temp[i]._internalId == newData._internalId) {
                temp[i] = newData;
            }
        }
        setRegistrationCodes(temp);
    }

    const removeNewRegistrationRow = (internalId) => {
        setRegistrationCodes(
            registrationCodes.filter( x => x._internalId != internalId )
        );
    }

    const addNewRegistrationRow = () => {
        let template = {
            _internalId : Math.random() * 10000,
            registrationCode : "kissa" + Math.ceil(Math.random() * 100),
            userGroupIds : []
        };
        setRegistrationCodes([...registrationCodes, template]);
    }

    const save = () => {
        setIsSaving(true);
    }


    if (isLoading || isSaving ) {
        return <Loader />
    }

    if ( availableUserGroups == null || availableUserGroups.length == 0 ) 
        return <p>No user groups</p>


    
    return (

        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">Settings</h3>
                <BreadCrumb />
            </div>
            {serverStatusMessage}
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Registration options</h4>
                            
                            <div className="form-check">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="registration_toggle" checked={registrationEnabled?true:false} value="" onChange={(e) => {setRegistrationEnabled(e.target.checked)}} /> 
                                    <label className="custom-control-label" htmlFor="registration_toggle">Registration {registrationEnabled?"enabled":"disabled"}</label>
                                </div>
                            </div>

                            {registrationCodes.map((data, idx) => 
                               <RegistrationOptionRow availableUserGroups={availableUserGroups} {...data} key={data._internalId} removeRow={removeNewRegistrationRow} onChange={(newData) => onRegistrationRowUpdate(newData)}></RegistrationOptionRow>
                            )}

                        <button className="btn btn-primary" onClick={() => addNewRegistrationRow()}>Add registration key</button>
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary" onClick={save}>Save</button>
        </div>
    );
}



export function RegistrationOptionRow(props) {

    const [registrationCode, setRegistrationCode] = useState(props.RegistrationCode || "");
    const [selectedUserGroupIds, setSelectedUserGroupIds] = useState(props.UserGroupIds || [] );

    useEffect(() => {
        messageToParent();
    }, [selectedUserGroupIds, registrationCode]);

    const onGroupSelect = (selectedGroup) => {                
        if ( !selectedUserGroupIds.find(x => x == selectedGroup.id )) {
            setSelectedUserGroupIds([...selectedUserGroupIds, selectedGroup.id]);        
        }
    }

    const messageToParent = () => {
        props.onChange( {
            _internalId: props._internalId,
            registrationCode: registrationCode,
            userGroupIds: selectedUserGroupIds
        })            
    }

    const removeSelectedGroup = (id) => {
        setSelectedUserGroupIds(selectedUserGroupIds.filter((x) => { return x != id }));        
    }

    let selectedUserGroups =  selectedUserGroupIds.map( (id) => props.availableUserGroups.find(x => x.id == id) );

    return (
        <div className="row mt-5 border-bottom">
            <div className="col-sm-3">                                    
                <input type="text" className="form-control" placeholder="Activation code" value={registrationCode} onChange={(e) => {setRegistrationCode(e.target.value);}}></input>
            </div>
            <div className="col-sm-8">
                <AutoComplete onSelect={onGroupSelect} list={props.availableUserGroups} ignore={selectedUserGroups} label="Search for user group" />
                <div>
                    <p>User groups</p>
                    {selectedUserGroups.map((e) => {
                        return (
                            <div key={e.id} className="badge badge-pill badge-secondary p-1 pl-3 mr-2"> {e.label}
                                <span className="badge badge-pill badge-primary ml-3 mr-1" onClick={() => { removeSelectedGroup(e.id) }}>X</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="col-sm-1">                                    
                <button className="btn btn-danger" title="Remove activation" onClick={() => props.removeRow(props._internalId)}>X</button>
            </div>
        </div>
    )
}