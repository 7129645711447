import React, { useState, useEffect } from 'react';
import TabContainer from '../../../components/TabContainer';
import BreadCrumb from '../../../components/BreadCrumb';
import Loader from '../../../components/Loader';
import { useParams, Redirect } from "react-router-dom";
import { Get, Insert, Update } from '../../../backend';
import StatusMessage from '../../../components/StatusMessage';


// import UserForm from './userform';


export default function Index() {
    let params = useParams();

    const [user, setUser] = useState({});
    
    const [isNewUser, setIsNewUser] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [aclList, setAclList] = useState([]);
    const [serverStatusMessage, setServerStatusMessage] = useState("");

    useEffect(() => {
        async function LoadUser(userId) {
            if (userId.length < 30) {                   
                setErrorMessage("Invalid userid")
            } else {
                let data = await Get(`/managementuser/${userId}`);

                if (data.ok) {
                    setUser(data.body.user);
                    setAclList(data.body.acl);

                    if (data.body.user.id != null) {
                        setIsNewUser(false);
                    }
                } else {
                    setErrorMessage("User load failed!");
                }
            }
        }

        if (params.userId) {
            LoadUser(params.userId);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        async function SaveUser() {
            
                let data = user;
                data.acl = aclList;
                let saveResult = null;
                if ( isNewUser) 
                    saveResult = await Insert("/managementuser", data);
                else 
                    saveResult = await Update("/managementuser", data);

                // console.log("Save with theese values", data, saveResult);
                setServerStatusMessage(<StatusMessage serverResponse={saveResult} />)
                setIsSaving(false);

                //let data = await Get(`/managementuser/${userId}`);
                // if (data.ok) {
                //     setUser(data.body);
                //     // if (data.body.id != null) {
                //     //     setIsNewUser(false);
                //     // }
                // } else {
                //     setErrorMessage("User load failed!");
                // }
         }
        

        if( isSaving )
            SaveUser();
        
        
    }, [isSaving]);

    const save = () => {
        setIsSaving(true);
    }
    
    if (isLoading || isSaving ) {
        return <Loader />
    }

    if (errorMessage != null) {
        return <div>{errorMessage}</div>
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">User details</h3>
                <BreadCrumb />
            </div>
            <div className="card">
                <div className="card-body">
                    {serverStatusMessage}
                    <div className="row">
                        <div className="col-6">
                            <UserDetailsForm user={user} setUser={setUser}/>
                        </div>
                        <div className="col-6">
                            <ACLList aclList={aclList} onChange={setAclList} />
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <button type="button" onClick={save} className="btn btn-primary mr-2">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function UserDetailsForm(props) {
    const [user, setUser] = useState({});
    useEffect(() => { setUser(props.user) }, [props.user])

    const setLogin = (e) => {
        user.login = e.target.value;
        props.setUser(user);
    }

    const setName = (e) => {
        user.name = e.target.value;
        props.setUser(user);
    }

    const setEmail = (e) => {
        user.email = e.target.value;
        props.setUser(user);
    }

    const setPassword = (e) => {
        user.password = e.target.value;
        props.setUser(user);
    }

    const setRepeatPassword = (e) => {
        user.passwordrepeat = e.target.value;
        props.setUser(user);
    }
    
    return (
        <form>
            <div className="form-group">
                <label htmlFor="loginname">Username</label>
                <input type="text" className="form-control" id="loginname" placeholder="Username" defaultValue={user.login} onChange={setLogin} />
            </div>

            <div className="form-group">
                <label htmlFor="displayname">Display name</label>
                <input type="text" className="form-control" id="displayname" placeholder="Username" defaultValue={user.name} onChange={setName} />
            </div>
            
            <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input type="email" className="form-control" id="email" placeholder="Email" defaultValue={user.email} onChange={setEmail}/>
            </div>
            
             <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Password" onChange={setPassword}/>
            </div>
            <div className="form-group">
                <label htmlFor="passwordrepeat">Repeat password</label>
                <input type="password" className="form-control" id="passwordrepeat" placeholder="Password repeat" onChange={setRepeatPassword}/>
            </div> 
        </form>
    );

}

function ACLList(props) {
    const [acl,setACL] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allowedACL, setAllowedACL] = useState(props.aclList);

    useEffect(() => {
        setAllowedACL(props.aclList)
        
    }, [props.aclList])


    const setACLState = (key, state) => {
        let newList = [];

        if ( state ) 
            newList = [...allowedACL, key];            
        else 
            newList = allowedACL.filter( k => {return k != key });
        
        setAllowedACL(newList);
        props.onChange(newList);
    }

    useEffect( () => {
        async function LoadACL() {            
            let data = await Get(`/managementuser/aclresources`);            
            if (data.ok) {
                setACL(data.body);                    
            } 
            setIsLoading(false);
        }    
        LoadACL();
    }, [])

    if ( isLoading ) {
        return <Loader />
    }

    if ( acl === undefined || acl === null || acl.length === 0 ) {
        return <p>No acl list found</p>
    }

    let structuredACL = {};
    acl.forEach((element) => {

        if ( structuredACL[element.resourceGroupName] === undefined) 
            structuredACL[element.resourceGroupName] = [];

        structuredACL[element.resourceGroupName].push(element);    
    }) 

    return (
        <div>
            {Object.keys(structuredACL).map( x => {            
                return <ACLLeaf key={x} name={x} values={structuredACL[x]}  keys={allowedACL} toggleFunc={setACLState}></ACLLeaf>
            })}
        </div>
    )
}

// function ACLRoot(props) {    
//     return (
//         <div>
//             {props.name}
//             {Object.keys(props.values).map( x => {            
//                 return <ACLLeaf key={x}  name={x} values={props.values[x]}  keys={props.keys} toggleFunc={props.toggleFunc} ></ACLLeaf>
//             })}
//         </div>
//     );
// } 

function ACLLeaf(props) {
    return (
        <>
        <div>{props.name}</div>
        <div>
            <ul style={{listStyleType:"none", marginLeft:0, paddingLeft:0}}>
            {props.values.map( (e) => {
                let toggled = false;
                if (props.keys != undefined && props.keys != null ) {
                    if (props.keys.indexOf(e.resourceKey) > -1 ) 
                        toggled = true;
                }
                return <li key={e.resourceKey}>
                            <SlideToggle label={e.resourceDescription} value={e.resourceKey} toggle={toggled} onChange={props.toggleFunc} />
                        </li>
            })}
            </ul>
        </div>
        </>
    )
}

function SlideToggle(props) {

    let _id = props.id ?? "custom_slide_toggle_id_" + Math.floor(Math.random() * 1000000);
    let _value = props.value;
    let _checked = props.toggle ?? false
    let _name = props.label;

    const onChange = (e) => {
        props.onChange(e.target.value, e.target.checked);        
    }

    return (
        <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id={_id} value={_value}  defaultChecked={_checked} onChange={onChange} />
            <label className="custom-control-label" htmlFor={_id}>{_name} <small>{_value} </small></label>
        </div>
    );

}