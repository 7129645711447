import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import { Get, Post } from "../../../backend";
import Loader from "../../../components/Loader";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [searchWord, setSearchWord] = useState("");

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      let data = await Get(
        `/users?skip=0&limit=500&groupid=${groupId}&searchword=${searchWord}`
      );
      let groupsdata = await Get("/usergroups");
      setUsers(data.body);
      setGroups(groupsdata.body);
      setIsLoading(false);
    }
    load();
  }, [groupId, searchWord]);

  const searchInputOnChange = (event) => {
    if (event.key == "Enter") {
      setSearchWord(event.target.value);
    }
    event.preventDefault();
  };

  if (isLoading) {
    return <Loader />;
  }

  const setActiveGroupId = (id) => {
    setGroupId(id);
  };

  const checkboxManipulator = (action) => {
    if (tableRef.current == null) return;

    let checkboxes = tableRef.current.querySelectorAll("input[type=checkbox]");

    switch (action) {
      case "all":
        checkboxes.forEach((element) => {
          element.checked = true;
        });
        break;
      case "none":
        checkboxes.forEach((element) => {
          element.checked = false;
        });
        break;
      case "invert":
        checkboxes.forEach((element) => {
          element.checked = !element.checked;
        });
        break;
    }

    let ids = [];
    checkboxes.forEach((element) => {
      if (element.checked) ids.push(element.value);
    });
    setSelectedUserIds(ids);
  };

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Users</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card card-secondary">
            <span className="card-body ">
              <h4 className="card-title mb-sm-0">Links</h4>
              <Link to={{ pathname: `/users/form` }}>
                <button
                  type="button"
                  className="btn btn-outline-info btn-icon-text btn-icon-text m-2 "
                >
                  <i className="icon-doc btn-icon-prepend"></i> Create a user
                </button>
              </Link>
            </span>
          </div>
        </div>

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card card-secondary">
            <span className="card-body">
              <h4 className="card-title mb-sm-0">Details </h4>

              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    <td>Number of users</td>
                    <td>n/a</td>
                  </tr>
                </tbody>
              </table>
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-sm-0">Filter by search word</h4>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={searchInputOnChange}
                  defaultValue={searchWord}
                  placeholder="Search for user"
                  aria-label="Search for user"
                />
                <span className="input-group-text">
                  <i className="icon-magnifier  btn-icon-prepend"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-sm-0">Filter by user group</h4>
              {groups.map((e, idx) => {
                if (groupId === e.id) {
                  return (
                    <span
                      className="badge badge-info mr-2 p-2"
                      onClick={() => setActiveGroupId("")}
                      key={idx}
                    >
                      {e.name}
                    </span>
                  );
                } else {
                  return (
                    <span
                      className="badge badge-secondary mr-2 p-2"
                      onClick={() => setActiveGroupId(e.id)}
                      key={idx}
                    >
                      {e.name}
                    </span>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      <BulkActions selectedUserIds={selectedUserIds} groups={groups} />
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <table className="table" ref={tableRef}>
                <thead>
                  <tr>
                    <th> # </th>
                    <th>
                      <button
                        onClick={() => {
                          checkboxManipulator("all");
                        }}
                      >
                        A
                      </button>
                      <button
                        onClick={() => {
                          checkboxManipulator("none");
                        }}
                      >
                        N
                      </button>
                      <button
                        onClick={() => {
                          checkboxManipulator("invert");
                        }}
                      >
                        I
                      </button>
                    </th>
                    <th>LoginName</th>
                    <th>AuthSource</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th className="text-right"> Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((obj, idx) => {
                    return (
                      <tr key={idx}>
                        <td> {idx + 1} </td>
                        <td>
                          <input
                            type="checkbox"
                            value={obj.id}
                            checked={
                              selectedUserIds.indexOf(obj.id) > -1
                                ? true
                                : false
                            }
                            onChange={(val) => {
                              if (val.target.checked)
                                setSelectedUserIds([
                                  ...selectedUserIds,
                                  obj.id,
                                ]);
                              else {
                                let filtered = selectedUserIds.filter(
                                  (x) => x != obj.id
                                );
                                setSelectedUserIds(filtered);
                              }
                            }}
                          />
                        </td>
                        <td> {obj.loginName}</td>
                        <td> {obj.authenticationSource}</td>
                        <td> {obj.displayName}</td>
                        <td> {obj.emailAddress}</td>
                        <td className="text-right">
                          <Link to={{ pathname: `/users/form/${obj.id}` }}>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-dark btn-icon-text"
                            >
                              <i className="icon-doc btn-icon-prepend"></i>
                              Configure
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BulkActions = ({ selectedUserIds, groups }) => {
  const [usrGrpForAdd, setUsrGrpForAdd] = useState("");
  const [usrGrpForRemove, setUsrGrpForRemove] = useState("");
  const history = useHistory();

  const deleteUserOnClick = () => {
    confirmAlert({
      title: `Delete ${selectedUserIds.length} users?`,
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            var payload = {
              UserIds: selectedUserIds,
            };
            Post(`/BulkUserManagement/delete`, payload).then((e) => {
              history.push("/users");
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const addToGroup = () => {
    var payload = {
      UserIds: selectedUserIds,
      GroupId: usrGrpForAdd,
    };
    Post(`/BulkUserManagement/add-group`, payload);
  };
  const removeFromGroup = () => {
    var payload = {
      UserIds: selectedUserIds,
      GroupId: usrGrpForRemove,
    };
    Post(`/BulkUserManagement/remove-group`, payload);
  };

  //   confirmAlert({
  //     title: "Delete user?",
  //     message: "Are you sure to do this.",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           Get(`/user/${params.userId}/softdeleteuser`).then((e) => {
  //             history.push("/users");
  //           });
  //         },
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });

  //if (!selectedUserIds || selectedUserIds.length < 1) return null;

  return (
    <div className="row mb-6">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card p-2">
          <div className="card-body">
            <h4 className="card-title mb-sm-0">Bulk actions</h4>
            <div>Selected user:{selectedUserIds.length}</div>
            <div className="row mb-4">
              <div className="col-4">
                <div className="my-3">
                  User group
                  <br />
                  <select
                    onChange={(ev) => {
                      setUsrGrpForAdd(ev.target.value);
                    }}
                  >
                    <option value="">Choose group</option>
                    {groups.map((g) => {
                      return (
                        <option key={g.id} value={g.id}>
                          {g.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <button
                  className={
                    selectedUserIds.length < 1
                      ? "btn btn-secondary"
                      : "btn btn-primary"
                  }
                  onClick={addToGroup}
                >
                  Add to group
                </button>
              </div>
              <div className="col-4">
                <div className="my-3">
                  User group
                  <br />
                  <select
                    onChange={(ev) => {
                      setUsrGrpForRemove(ev.target.value);
                    }}
                  >
                    <option value="">Choose group</option>
                    {groups.map((g) => {
                      return (
                        <option key={g.id} value={g.id}>
                          {g.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <button
                  className={
                    selectedUserIds.length < 1
                      ? "btn btn-secondary"
                      : "btn btn-primary"
                  }
                  onClick={removeFromGroup}
                >
                  Remove from group
                </button>
              </div>
              <div className="col-4 position-relative">
                <div className="position-absolute" style={{ bottom: 0 }}>
                  <button
                    className={
                      selectedUserIds.length < 1
                        ? "btn btn-secondary"
                        : "btn btn-danger"
                    }
                    onClick={deleteUserOnClick}
                  >
                    Delete users
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
