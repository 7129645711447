import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Get, Post } from "../../../backend";
import DatePicker from "react-datepicker";
import Loader from "../../../components/Loader";
import Popup from "../../../components/Popup";
import UserAdminNoteForm from "../../../containers/UsersPage/Form/useradminnote-form";
import "../reporting.css";
import DateDisplay from "../../../components/DateDisplay";
import { Link } from "react-router-dom";

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function FormatDate(dateobj) {
  return (
    dateobj.getFullYear() +
    "-" +
    (dateobj.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateobj.getDate().toString().padStart(2, "0")
  );
}

export default function Index() {
  let params = useParams();
  const trainingId = params.trainingId;

  let ed = new Date();
  let sd = addDays(ed, -130);

  const [training, setTraining] = useState({});
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [startDate, setStartDate] = useState(sd);
  const [endDate, setEndDate] = useState(ed);
  const [result, setResult] = useState(null);

  useEffect(() => {
    async function Load(trainingId) {
      let data = await Get(`/training/${trainingId}`);
      setTraining(data.body);

      let data2 = await Get(`/courses/${trainingId}`);
      setCourses(data2.body);
    }
    Load(trainingId);
  }, []);

  useEffect(() => {
    async function LoadResults() {
      let data = {
        trainingId: trainingId,
        courseId: courseId,
        startDate: FormatDate(startDate),
        endDate: FormatDate(endDate),
      };

      let results = null;
      if (courseId === undefined || courseId.length == 0) {
        results = await Post("/reporting/completedtrainingdetails", data);
      } else {
        results = await Post("/reporting/completedcoursedetails", data);
      }
      setResult(results);
      setIsSearching(false);
    }
    if (isSearching) {
      LoadResults();
    }
  }, [isSearching]);

  const DoSearch = () => {
    setIsSearching(true);
  };

  if (!trainingId || trainingId.length < 30) {
    return (
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">Invalid training</div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{training.name}</h4>

          <div className="form-inline">
            <DatePicker
              dateFormat="dd.MM.yyyy"
              onChange={(e) => setStartDate(e)}
              selected={startDate}
            />
            <DatePicker
              dateFormat="dd.MM.yyyy"
              onChange={(e) => setEndDate(e)}
              selected={endDate}
            />
            <select
              className="form-control mb-2"
              onChange={(e) => {
                setCourseId(e.target.value);
              }}
            >
              <option value="">{training.name}</option>
              {courses.map((e, idx) => {
                return (
                  <option value={e.id} key={e.id}>
                    &raquo; {e.name}
                  </option>
                );
              })}
            </select>
            <button
              type="button"
              className="btn btn-primary ml-3 mb-2"
              onClick={() => DoSearch()}
            >
              Search
            </button>
          </div>
          {isSearching && <Loader />}
          {!isSearching && <Results data={result}></Results>}
        </div>
      </div>
    </div>
  );
}

export function Results(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!props || !props.data) return null;

  if (!props.data.ok) {
    return "Search failed!";
  }
  if (props.data.body.length == 0) {
    return "Nothing found";
  }

  return (
    <table className="mt-4 table table-striped table-bordered">
      <thead className="table-dark">
        <tr>
          <th>
            <strong>Date</strong>
          </th>

          <th>
            <strong>User</strong>
          </th>
          <th>
            <strong>Company</strong>
          </th>
          <th>
            <strong>City</strong>
          </th>
          <th>
            <strong>Admin note</strong>
          </th>
          <th>
            <strong>Count</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.data.body.map((e, idx) => {
          return (
            <tr key={idx}>
              <ReportRow
                {...e}
                idx={idx}
                isPopupOpen={isPopupOpen}
                setIsPopupOpen={setIsPopupOpen}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ReportRow({
  idx,
  userId,
  name,
  emailAddress,
  personalName,
  startedAt,
  completedAt,
  completionCount,
  companyName,
  companyCity,
  adminNote,
  isPopupOpen,
  setIsPopupOpen,
}) {
  const [openPopUp, setOpenPopUp] = useState(null);
  const [noteContent, setNoteContent] = useState(adminNote);

  const onNoteSave = (newNoteString) => {
    setIsPopupOpen(false);
    setNoteContent(newNoteString);
    setOpenPopUp(null);
  };

  const open = () => {
    if (!isPopupOpen) {
      setOpenPopUp(openPopUp === idx ? null : idx);
      setIsPopupOpen(true);
    }
  };

  let displayName = "";
  if (personalName) {
    displayName = personalName;
  } else {
    if (emailAddress) {
      displayName = emailAddress;
    } else {
      displayName = name;
    }
  }

  return (
    <>
      <td>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DateDisplay>{startedAt}</DateDisplay>&nbsp;&nbsp;-&nbsp;&nbsp;
          <DateDisplay>{completedAt}</DateDisplay>
        </div>
      </td>

      <td className="py-1">
        <Link to={{ pathname: `/users/form/${userId}` }}>
          {displayName || <i>No name info</i>}
        </Link>
      </td>

      <td>{companyName}</td>
      <td>{companyCity}</td>
      <td>
        <LongText>{noteContent}</LongText>
        <span
          className="ml-2 icon-pencil"
          onClick={(event) => {
            event.preventDefault();
            open();
          }}
        ></span>

        <Popup
          isOpen={idx === openPopUp}
          close={() => {
            setIsPopupOpen(false);
            setOpenPopUp(null);
          }}
        >
          <UserAdminNoteForm userId={userId} onSave={onNoteSave} />
        </Popup>
      </td>
      <td>{completionCount}</td>
    </>
  );
}

function LongText(props) {
  const [toggle, setToggle] = useState(false);

  if (!props.children || props.children.length === 0) return null;

  let text = props.children.toString();
  let shortText = "";
  if (text.length > 30) {
    if (toggle) {
      return (
        <span
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {text}
        </span>
      );
    } else {
      shortText = text.substring(0, 30) + "...";
      return (
        <span
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {shortText}
        </span>
      );
    }
  }
  return text;
}
