import React,{ useEffect, useState }  from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
import EditButton from '../../../components/EditButton';
import { Link } from "react-router-dom";


export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const [usergroups, setUserGroups] = useState([]);
  useEffect(() => {
    async function load() {
      setIsLoading(true);
      let data = await Get("/usergroups/withusercount");
      setUserGroups(data.body);
      setIsLoading(false);
    }
    load();
  }, []);

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Usergroups</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-lg-6 grid-margin stretch-card">          
          <div className="card card-secondary">            
            <span className="card-body ">
              <h4 className="card-title mb-sm-0">Links</h4>
              <Link  to={{pathname: `/usergroups/form`}}>
                <button type="button" className="btn btn-outline-info btn-icon-text btn-icon-text m-2 "><i className="icon-doc btn-icon-prepend"></i> Create a group</button>
              </Link> 
            </span>
          </div>
        </div>

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card card-secondary">
            <span className="card-body">
            <h4 className="card-title mb-sm-0">Details </h4>

              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    
                  </tr>                
                </tbody>
              </table>
            </span>
          </div>
        </div>
      </div>
      <div className="row">        
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <table className="table ">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Name </th>                   
                    <th> User count </th>          
                    <th className="text-right"> Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {usergroups.map((obj,idx) => {
                    return (
                      <tr key={idx}>
                        <td> {idx+1} </td>
                        <td> {obj.name}</td>                        
                        <td> {obj.userCount}</td>
                        <td className="text-right"><EditButton path={`/usergroups/form/${obj.id}`}></EditButton></td>                      
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
      </div>
      </div>      
    
  );
}