import React, { useState, useEffect, useContext } from "react";
// import { Link, useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Get, logout } from "./backend";
import { HasAccess } from "./helpers/Acl";
import { useHistory } from "react-router-dom";
import { useUser } from "./components/Auth/Authentication";
import { UserContext } from "./components/Contexts/UserContext";
import { UIContext } from "./components/Contexts/UIContext";

const VerifyLinks = (links) => {
  return links.filter((link) => {
    return HasAccess(link.aclName);
  });
};

const userLinks = [
  {
    title: "Users",
    icon: "icon-user menu-icon",
    url: "/users",
    aclName: "Management.Users.View",
  },
  {
    title: "User groups",
    icon: "icon-people menu-icon",
    url: "/usergroups",
    aclName: "Management.UserGroups.View",
  },
];

const settingLinks = [
  {
    title: "Login options",
    icon: "icon-screen-desktop menu-icon",
    url: "/settings/loginoptions",
    aclName: "Management.TenantSettings.View",
  },
  {
    title: "Registration options",
    icon: "icon-screen-desktop menu-icon",
    url: "/settings/registrationoptions",
    aclName: "Management.ConfigurationRegistration.View",
  },
  {
    title: "Templates",
    icon: "icon-screen-desktop menu-icon",
    url: "/settings/templates",
    aclName: "Management.TenantSettings.ViewTemplates",
  },
  {
    title: "Tenant settings",
    icon: "icon-screen-desktop menu-icon",
    url: "/settings/tenantsettings",
    aclName: "Management.TenantSettings.View",
  },
  {
    title: "Management users",
    icon: "icon-screen-desktop menu-icon",
    url: "/managementusers",
    aclName: "Management.ManagerUser.View",
  },
  {
    title: "Create Tenant",
    icon: "",
    url: "/tenant/create-new-form",
    aclName: "Management.Tenant.Create",
  },
  ,
  {
    title: "Training collections",
    icon: "",
    url: "/trainingcollections",
    aclName: "Management.Tenant.Create", // TODO FIX THIS
  },
];

export default function Nav(props) {
  // let history = useHistory();
  // let params = useParams();
  const history = useHistory();
  const { setUser } = useContext(UserContext);
  const { refetchTrainings, setRefetchTrainings } = useContext(UIContext);
  const [trainings, setTrainings] = useState([]);

  async function loadTrainings() {
    let data = await Get("/trainings");
    setTrainings(data.body);
  }

  useEffect(() => {
    loadTrainings();
  }, []);

  useEffect(() => {
    if (refetchTrainings) {
      loadTrainings();
      setRefetchTrainings(false);
    }
  }, [refetchTrainings]);

  function handleLogout() {
    logout();
    history.push("/");
    setUser(null);
  }

  let styles = {};
  if (props.mobileNavVisible) {
    styles = {
      right: 0,
    };
  }

  let validUserLinks = VerifyLinks(userLinks);
  let validSettingsLinks = VerifyLinks(settingLinks);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar" style={styles}>
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a className="nav-link" href="javacript:void(0);">
            <div className="profile-image">
              <div className="dot-indicator bg-success"></div>
            </div>
            <div className="text-wrapper">
              <p className="profile-name">
                {props.user.name ? props.user.name : props.user.login}
              </p>
              <p className="designation">
                Administrator {props.mobileNavVisible ? "yep" : "no"}
              </p>
            </div>
            <div className="icon-container">
              <i className="icon-bubbles"></i>
              <div className="dot-indicator bg-danger"></div>
            </div>
          </a>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link">Dashboard</span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <span className="menu-title">Dashboard</span>
            <i className="icon-screen-desktop menu-icon"></i>
          </Link>
        </li>
        {HasAccess("Management.Training.View") && (
          <>
            <li className="nav-item nav-category">
              <span className="nav-link">
                Trainings
                <Link to="/trainings/create" style={{ marginLeft: "auto" }}>
                  <i className="icon-plus menu-icon"></i>
                </Link>
              </span>
            </li>
            <TrainingsList list={trainings} />
          </>
        )}

        <li className="nav-item nav-category">
          <span className="nav-link">Content Management</span>
        </li>
        {HasAccess("Management.CMS.View") && (
          <li className="nav-item">
            <Link className="nav-link" to="/content">
              <span className="menu-title">Content</span>
              <i className="icon-user menu-icon"></i>
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link className="nav-link" to="/filebrowser">
            <span className="menu-title">File browser</span>
            <i className="icon-screen-desktop menu-icon"></i>
          </Link>
        </li>

        {validUserLinks.length > 0 && (
          <li className="nav-item nav-category">
            <span className="nav-link">Users</span>
          </li>
        )}
        <LinkList links={validUserLinks} />

        <li className="nav-item nav-category">
          <span className="nav-link">Reporting</span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/reporting">
            <span className="menu-title">Trainings overview</span>
            <i className="icon-screen-desktop menu-icon"></i>
          </Link>
        </li>

        {validSettingsLinks.length > 0 && (
          <li className="nav-item nav-category">
            <span className="nav-link">Settings</span>
          </li>
        )}
        <LinkList links={validSettingsLinks} />

        <li className="nav-item mt-5 nav-category">
          <span className="nav-link" style={{ borderTop: "none" }}>
            My settings
          </span>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/change-password">
            <span className="menu-title">Change password</span>
          </Link>
        </li>
        <li className="nav-item">
          <div
            className="nav-link"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          >
            <span className="menu-title">Logout</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}

const LinkList = ({ links }) => {
  return links.map((link, idx) => {
    return (
      <li key={idx} className="nav-item">
        <Link className="nav-link" to={link.url}>
          <span className="menu-title">{link.title}</span>
          <i className={link.icon}></i>
        </Link>
      </li>
    );
  });
};

const TrainingsList = (props) => {
  if (Array.isArray(props.list)) {
    return props.list.map((obj, idx) => {
      return (
        <li className="nav-item" key={idx}>
          <Link
            className="nav-link"
            to={{ pathname: `/trainings/overview/${obj.id}` }}
          >
            <span className="menu-title">{obj.name}</span>
            <i className="icon-layers menu-icon"></i>
          </Link>
        </li>
      );
    });
  }

  // if( typeof(props.list) === "string") {
  //     return <p>{props.list}</p>
  // }

  return null;
};
