import React, {useState} from 'react';
import './help.css';

export default function Index(props) {

    const [visible,setVisible] = useState(false);

    let classes = "help-background";
    if ( visible )
        classes += " visible";

    return (
        <div>
            <div onClick={() => {setVisible(!visible)}}><i className="icon-directions  mr-0"></i></div> 
            <div className={classes}>
                <div className="help-main">
                    <div className="header">
                        Help
                        <div className="close-icon" onClick={ () => {setVisible(false)}}><i className="icon-close  mr-0"></i></div>
                    </div>
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}