import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import { Get, Post, UploadFileBlob } from '../backend';
import Loader from './Loader';
import './FileBrowser.css'
import FileDetails from './FileDetails';

export default function FileBrowser(props) {

    const [directories, setDirectories] = useState([]);
    const [files, setFiles] = useState([]);
    const [directory, setDirectory] = useState("/");
    const [forceReload, setForceReload] = useState(false);
    const [filesLoading, setFilesLoading] = useState(false);
    const [directoryAction, setDirectoryAction] = useState(null);
    const [file, setFile] = useState(null);

    const [selectMultipleFiles, setSelectMultipleFiles] = useState(props.selectMultipleFiles || false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {

        async function LoadDirectories() {
            let data = await Get("/file/directories")
            if (data.ok) {
                let dirs = ["/", ...data.body]
                setDirectories(dirs);
            }
        }

        async function LoadDirectoryContent() {
            setForceReload(false);
            setFilesLoading(true);
            let data = await Get(`/file/ls?path=${directory}`)
            if (data.ok) {
                setFiles(data.body);
            }
            setFilesLoading(false);
        }

        async function DirectoryActions(act) {
            var data;
            if (act.action == "create") {
                data = await Post("/file/createdirectory", { path: act.path });
            } else if (act.action == "remove") {
                data = await Post("/file/removedirectory", { path: act.path });
            }
            if (data.ok) {
                setDirectoryAction(null);
            } else {
                console.error(data, act);
            }

        }
        if (directoryAction != null) {
            DirectoryActions(directoryAction)
        } else {
            LoadDirectories();
            LoadDirectoryContent();
        }

    }, [directory, forceReload, directoryAction]);

    async function onDrop(acceptedFiles) {
        if (acceptedFiles.length > 0) {

            for (let x = 0; x < acceptedFiles.length; x++) {
                let f = acceptedFiles[x];
                setFilesLoading(true);
                let r = await UploadFileBlob(f, directory);
                if (!r.ok) {
                    alert(r.body.error);
                }
                setFilesLoading(false);
                setForceReload(true);
                setDirectory(directory);
            }
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const selectDirectory = function (dir) {
        setDirectory(dir);
    }

    const multipleFilesSelected = () => {
        if ( props.onSelectFile)
            props.onSelectFile(selectedFiles);
    }

    const pickFile = (fileobject) => {
        console.log(selectMultipleFiles === true , props.onSelectFile)
        if (selectMultipleFiles === true ) {
            if (selectedFiles.indexOf(fileobject.view) == -1 ) { 
                setSelectedFiles([...selectedFiles, fileobject.view]);
            } else {
                setSelectedFiles(selectedFiles.filter( x => x != fileobject.view ));
            }
        } else {
            if (props.onSelectFile) {                
                props.onSelectFile(fileobject.view);
            } else {
                setFile(fileobject);
            }
        }
    }

    const _rootOnclick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    const createDirectory = () => {
        let name = prompt("New directory name:");
        name = name.replace(/\s/g, "");
        if (!name.startsWith("/")) {
            if (directory != "/")
                name = "/" + name;
        }
        if (name != null && name.length > 0) {
            setDirectoryAction({ action: "create", path: directory + name });
        }
    }
    const removeDirectory = () => {
        if (window.confirm("Really????"))
            setDirectoryAction({ action: "remove", path: directory });
        setDirectory("/");
    }

    const cd = <a onClick={createDirectory} title="Create subdirectory"><i className="icon-plus"></i></a>
    const rd = <a onClick={removeDirectory} title="Remove directory"><i className="icon-close" style={{ color: "red" }}></i></a>


    return (
        <div className="file-browser">
            <div className="directories">
                <h4>Directories</h4>
                <hr></hr>
                <div>

                    {directories.map((elem, idx) => {
                        let n = elem;
                        if (n === "/")
                            n = "[Root]"

                        if (directory == elem) {
                            if (elem === "/")
                                return <div key={idx} onClick={() => selectDirectory(elem)} className="directory-item selected"> &raquo; {n} {cd}</div>
                            return <div key={idx} onClick={() => selectDirectory(elem)} className="directory-item selected"> &raquo; {n} {cd}&nbsp;{rd}</div>

                        }
                        return <div key={idx} onClick={() => selectDirectory(elem)} className="directory-item">&raquo; {n}</div>
                    })}
                </div>
            </div>
            <div className="files">
                Directory content: {directory}
                <hr />
                <div {...getRootProps({ onClick: _rootOnclick })} style={{ position: 'relative' }}>
                    {
                        isDragActive ?
                            <div className="dz-active" style={{ width: '100%', minWidth: '200px' }}>Drop the files here ...</div> :
                            <div></div>
                    }
                    <div className="file-list">
                        {files.map((elem, idx) => {
                            let classList = "file";
                            if (selectedFiles.indexOf(elem.view) > -1) {
                                classList += " selected"
                            }


                            return (<div key={idx} className={classList} onClick={() => pickFile(elem)} title={elem.filename}>
                                <img src={elem.thumbnail} />
                                <span>{elem.filename}</span></div>)
                        })}
                        {files.length === 0 && <strong>No files</strong>}
                        {filesLoading && <Loader />}


                    </div>
                    <input {...getInputProps()} />
                </div>
            </div>
            <FileDetails file={file} onClose={() => { setFile(null); setForceReload(true) }} />

            {selectMultipleFiles && <button onClick={(e) => { multipleFilesSelected() }}>Selected {selectedFiles.length} images</button>}

        </div>
    );
}
