
import React, {useState, useContext} from 'react';
export const CourseContext = React.createContext();
export const CourseUpdateContext = React.createContext();



export function useCourse() {
    return useContext(CourseContext);
}


export function useCourseUpdate() {    
    return useContext(CourseUpdateContext)    
}


export default function CourseProvider(props) {
    const [course,setCourse] = useState(props.course)

    const updateCourse = (c) => {        
        setCourse(c);
    }

    return (
        <CourseContext.Provider value={course}>
            <CourseUpdateContext.Provider value={updateCourse}>
                {props.children}
            </CourseUpdateContext.Provider>
        </CourseContext.Provider>
    )
}