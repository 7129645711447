import React, { useState, useEffect } from 'react';
import TabContainer from '../../../../components/TabContainer';
import { Get } from '../../../../backend';
import Content from '../Content';
import TaskTab from './TaskData';
import Loader from '../../../../components/Loader';
import TemplateContentManager from '../../../../components/TemplateContentManager';
import ConfigurableContent from '../../../../components/ConfigurableContent';

export default function Index(props) {
    const [task, setTask] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        async function Load() {
            setIsLoading(true);
            let data = await Get(`/task/${props.structure.structureTypeId}`);                                     
            setTask(data.body);            
            setIsLoading(false);
        }
        Load();
    }, [props.structure.structureTypeId]);

    if ( isLoading ) {
      return <Loader />;
    }

    return (
        <>
          <TabContainer>
            <div TabName="Content" TabId="1" TabActive={true} key="1">
              <TemplateContentManager contentId={props.structure.templateContentId} onContentSave={props.onContentSave} ></TemplateContentManager>
              <Content useOverridenWithTemplateContentManagerWarning={true} contentId={task.contentId} /> 
            </div>
            
            <TaskTab TabName="Task Data" TabId="2" TabActive={false} key="2" structure={props.structure} />
            <ConfigurableContent TabName="Conf content" TabId="3" TabActive={false} key="3" OwnerName="CourseStructure" OwnerId={props.structure.id} ConfigurationPart="courseStructureConfiguration" ConfigurationPropertyName="task" />
          </TabContainer>
        </>
    );
}