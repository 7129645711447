import React from 'react';
import {Route} from "react-router-dom";
import List from './List';
import Form from './Form';

export default function ManagementUsersPage() {
  return (
    <div>
       <Route path="/managementusers/form/:userId?">
        <Form />
      </Route>
      
      <Route exact path="/managementusers">
        <List />
      </Route>
    </div>
  );
}