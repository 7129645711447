import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import { Login } from '../../backend';
// import { Context } from '../../components/Auth/AuthenticationContext';

export default function LoginPage(props) {

    const loginRef = useRef();
    const passwordRef = useRef();

    // const [,dispatch] = useContext(Context);
    // const [loginDetails, setLoginDetails] = useState({});
    // const [sendToServer, setSendToServer] = useState(false);
    // const [redirect, setRedirect] = useState("");
    // let history = useHistory();

    // useEffect(() => {
    //   async function SendLoginRequest(data) {
    //     var loginResult = await Login(data.username, data.password);
    //     if (loginResult != null) {        
    //       dispatch({ type: 'SET_LOGIN', payload: loginResult });                
    //       setRedirect("/");
    //       // history.push("/");
    //     }
    //   }

    //   if (sendToServer) {
    //     SendLoginRequest(loginDetails);
    //     setSendToServer(false);
    //   }

    // }, [sendToServer])   // eslint-disable-next-line react-hooks/exhaustive-deps

    const onSubmit = function (e) {
        e.preventDefault();
        if (props.isLoading) {
            return;
        }

        let details = {
            login : loginRef.current.value,
            password : passwordRef.current.value
        }
        props.onLogin(details);
        // setLoginDetails({ username: e.target.login.value, password: e.target.password.value });
        // setSendToServer(true);
    }

    // if ( redirect ) {
    //   return <Redirect to={redirect} />
    // }


    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo">
                                    <img src="/assets/images/logo_.png" alt="Taitaen" />
                                </div>
                                <form className="pt-3" onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <input ref={loginRef} type="text" name="login" className="form-control form-control-lg" placeholder="Username" />
                                    </div>
                                    <div className="form-group">
                                        <input ref={passwordRef} type="password" name="password" className="form-control form-control-lg" placeholder="Password" />
                                    </div>
                                    <div className="mt-3">
                                        <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">SIGN IN</button>
                                    </div>
                                </form>
                                {props.isLoading &&
                                    <div className="mt-3 alert alert-secondary" role="alert">
                                        Logging in, please wait...
                                    </div>
                                }
                                {!props.isLoading && props.loginFailed &&
                                    <div className="mt-3 alert alert-danger" role="alert">Kirjautuminen epäonnistui</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}