import React, { useState, useEffect, useRef } from "react";
import { Get, Update } from "../../../backend";
import Loader from "../../../components/Loader";

export default function UserAdminNoteForm(props) {
  const textRef = useRef(null);
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
  };

  useEffect(() => {
    async function LoadAdminNote(userId) {
      let result = await Get(`/user/${userId}/adminnote/`);
      if (result.ok) setNote(result.body.note);
    }
    if (props.user?.id !== undefined || props.user?.id !== null) {
      setIsLoading(true);
      LoadAdminNote(props.userId);
      setIsLoading(false);
    }
  }, [props.userId]);

  useEffect(() => {
    async function Save(userId) {
      let data = {
        note: textRef.current.value,
      };
      let result = await Update(`/user/${userId}/adminnote/`, data);
    }

    if (isSaving) {
      Save(props.userId);
      if (typeof props.onSave === "function") {
        props.onSave(textRef.current.value);
      }
      setIsSaving(false);
    }
  }, [isSaving]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form className="forms-inline" onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="personal_name">Note</label>
            <textarea
              className="form-control"
              rows="9"
              ref={textRef}
              defaultValue={note}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <button type="submit" className="btn btn-primary mr-2">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
