import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import FileBrowser from '../../components/FileBrowser';


export default function Index() {
      
  
    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">FileBrowser</h3>
                <BreadCrumb />
            </div>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <FileBrowser />
                    </div>
                </div>
            </div>
        </div>
    );
}