import React, { useEffect, useState, useContext } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import TabContainer from '../../../components/TabContainer';
import BreadCrumb from '../../../components/BreadCrumb';
import { useLocation } from "react-router-dom";
import FormTab from './FormTab';
import ConfigurationTab from './ConfigurationTab';
import ContentTab from './ContentTab';
import UserGroupsTab from './UserGroupsTab';

import { Delete } from '../../../backend';
import { ParseQueryString } from '../../../helpers/querystring';
import { TrainingContext } from '../../../components/Contexts/TrainingContext';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CourseContext } from '../../../components/Contexts/CourseContext';
import ConfigurableContent from '../../../components/ConfigurableContent';

// const findTrainingId = function (location) {
//     let queryParts = ParseQueryString(location.search);
//     return queryParts["trainingId"];
// }


export default function Index(props) {
    let location = useLocation();

    const [deleteCourse, setDeleteCourse] = useState(false);
    // const courseId = props.course != null ? props.course.id : null;

    const isNewCourse = props.isNewCourse;

    const training = useContext(TrainingContext);
    const course = useContext(CourseContext);    



    useEffect(() => {
        async function doDeleteRequest() {
            await Delete(`/course/${course.id}`);
            window.location = "/";
        }
        

        if (deleteCourse && course.id != null) {
            doDeleteRequest();
        }
    },[deleteCourse]);

    const deleteCourseOnClick = function () {
        confirmAlert({
            title: 'Delete?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setDeleteCourse(true);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    if (isNewCourse === undefined || isNewCourse === null) {
        return <div>isNewCourse property not defined!</div>
    }
    if (training == null || training.id == undefined) {
        return <div>Training context is invalid</div>
    }

    if (isNewCourse) {
        return (
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">Create new course for {training.name}</h3>
                    <BreadCrumb />
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <TabContainer>
                            <FormTab TabName="Details" TabId="1" TabActive={true} key="1" isNewCourse={props.isNewCourse} />
                        </TabContainer>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">{training.name} - {course.name}</h3>
                <BreadCrumb />
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <TabContainer>
                        <FormTab TabName="Details" TabId="1" TabActive={true} key="1" isNewCourse={props.isNewCourse} />

                        <ContentTab TabName="Contents" TabId="2" key="2" TabActive={false}  />
                        <ConfigurationTab TabName="Configuration" TabId="3" TabActive={false} key="3" />
                        <UserGroupsTab TabName="User groups" TabId="4" TabActive={false} key="4" />
                        <ConfigurableContent TabName="Conf content" TabId="5" TabActive={false} key="5" OwnerName="Course" OwnerId={course.id} ConfigurationPart="courseConfiguration" />
                    </TabContainer>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <button className="btn btn-danger" onClick={deleteCourseOnClick}>
                        Delete course
                    </button>
                </div>
            </div>
        </div>
    );
}