import React, {useContext, useEffect, useState} from 'react';
import LoginPage from '../../containers/LoginPage'
import { UserExtraDetails } from '../../containers/UsersPage/Form/userform';
import {getToken, Login, getUser, setActiveTenant, getActiveTenant, logout } from '../../backend';
import { UserContext, UserProvider } from '../Contexts/UserContext';
import { useHistory } from 'react-router-dom';


//const authContext = React.createContext();
export const tenantContext = React.createContext();

export function useUser() {
    const { user } = useContext(UserContext);
    return user;
}

export function useTenant() {
    let context = useContext(tenantContext);
    return context;
}



export default function Authentication(props) {
    const history = useHistory();
    const { user, setUser } = useContext(UserContext);
    //const [user,setUser] = useState(null);
    const [tenant,setTenant] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [loginFailed, setLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loginCredentials,setLoginCredentials] = useState(null);

    useEffect( () => {
        async function DoLoginCheck() {                        
            let token = getToken();
            if ( token != null) {                
                let u = getUser();                
                if ( u != null ) {
                    setUser(u);
                    let activeTenant = await getActiveTenant();
                    if (activeTenant === false) { // Logout user
                        console.log('activeTenant', activeTenant)
                        logout()
                        history.push("/");
                        setUser(null);
                    } else if ( activeTenant == null) {
                        await setActiveTenant(u.tenants[0]);
                        setTenant(u.tenants[0]);
                    } else {
                        setTenant(activeTenant);
                    }
                }
            }            
        }
        setIsLoading(true);
        DoLoginCheck();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        async function DoLogin(data) {
              
            var loginResult = await Login(data.login, data.password);
            if ( loginResult ) {
                setLoginFailed(false);
                let token = getToken();
                if ( token != null) {
                    let u = getUser();
                    if ( u != null ) {
                        setUser(u);
                        await setActiveTenant(u.tenants[0]);
                        setTenant(u.tenants[0]);
                    }
                }     
            } else {
                setLoginFailed(true);
            }
            setIsLoading(false);
        }

        if ( isLoading && loginCredentials != null ) {
            DoLogin(loginCredentials);
        }
    }, [isLoading, loginCredentials]) 

    // useEffect( () => {
    //     async function loadACLAndSetTenant(id) {
    //         let newTenant = user.tenants.find( t => t.id == id );
    //         if ( newTenant != null ) {
    //             await setActiveTenant(newTenant);
    //             setTenant(newTenant);
    //         }
    //     }

    //     if ( tenantId != null) {
    //         loadACLAndSetTenant(tenantId);
    //     }

    // }, [tenantId]);

    const onLogin = (details) => {
        setLoginCredentials(details);        
        setIsLoading(true);
    }

    const updateTenantId = (selectedTenantId ) => {
        setTenantId(selectedTenantId);
    }

    // if ( isLoading ) {
    //     return <div>Logging in, please wait.</div>
    // }
    
    if ( user == null || isLoading ) {
        return (
            <LoginPage onLogin={onLogin} loginFailed={loginFailed} isLoading={isLoading}/>
        );
    }

    if ( tenant == null ) {
        return (
            <tenantContext.Provider value={{tenant, setTenantId:updateTenantId}}>                
                <TenantList tenants={user.tenants} />
            </tenantContext.Provider>
        );

        // return (
        //     {/* <UserProvider>
        //         <authContext.Provider value={user}> */}
        //             <tenantContext.Provider value={{tenant, setTenantId:updateTenantId}}>                
        //                 <TenantList tenants={user.tenants} />
        //             </tenantContext.Provider>
        //         {/* </authContext.Provider>
        //     </UserProvider> */}
        // );
    }

    return (
        <tenantContext.Provider value={{tenant, setTenantId:updateTenantId}}>           
            {props.children}
        </tenantContext.Provider>
    );
    // return (
    //     <UserProvider>
    //         {/* <authContext.Provider value={user}> */}
    //             <tenantContext.Provider value={{tenant, setTenantId:updateTenantId}}>           
    //                 {props.children}
    //             </tenantContext.Provider>
    //         {/* </authContext.Provider> */}
    //     </UserProvider>
    // );
}


function TenantList(props) {
    const c = useContext(tenantContext);
    const selectTenant = (t) => {        
        c.setTenantId(t);
    }


    return (
        <div>
            {props.tenants.map( ( tenant ) => {
                return (
                <button key={tenant.id} onClick={() => {selectTenant(tenant.id)}}>
                    {tenant.name}
                </button>
                )
            })}
        </div>
    );
}
