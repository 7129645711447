import React from "react";

/**
 * Statusmessage
 *
 * @props.serverResponse Render status based on server response object
 */
export default function StatusMessage(props) {
  let success = props.success;
  let serverResponse = props.serverResponse;
  //let children = props.children;
  let children = [];

  if (serverResponse !== null && serverResponse !== undefined) {
    success = serverResponse.ok;
    if (success) {
      children = ["Save ok"];
    } else {
      children = ["Failed!"];

      if (serverResponse.errors !== undefined) {
        children = children.concat(
          Object.keys(serverResponse.errors).map((key, idx) => {
            let e = serverResponse.errors[key].join(", ");
            return e;
          })
        );
      }
      if (serverResponse.body.message != null) {
        children = [serverResponse.body.message];
      }
    }
  } else {
    children = [props.children];
  }

  let cls = success ? "alert alert-success" : "alert alert-danger";

  return (
    <div className={cls} role="alert">
      {children.map((child, idx) => (
        <div key={idx}>{child}</div>
      ))}
    </div>
  );
}
