import React, { useState } from 'react';
import { ChromePicker } from 'react-color'
import { GradientPicker } from 'react-linear-gradient-picker';
import {parseColorValue} from '../helpers/color';

const WrappedColorPicker = ({ onSelect, ...rest }) => (
    <ChromePicker {...rest} onChange={c => {        
        var col = parseColorValue(c.hex);
        var str = "rgba("+col.r+","+col.g+","+col.b+", "+c.rgb.a+")";
        onSelect(str,c.rgb.a);
	}}/>	
);

export default function Gradient(props) {

    let initial = props.value && props.value.length > 0  ? props.value : [
        { offset: '0.00', color: 'rgba(238, 241, 11,1)'},    
        { offset: '1.00', color: 'rgba(126, 32, 207,1)'}
    ];
    const [palette, setPalette] = useState(initial);

    const onPaletteChange = (p) => {
        
        setPalette(p);
        props.onChange(p);
    }

    return (
        <GradientPicker {...{
            width: 320,
            paletteHeight: 32,
            palette,
            onPaletteChange: onPaletteChange
        }}>
            <WrappedColorPicker/>
        </GradientPicker>
    );
};