import React, { useState, useEffect } from 'react';

import { Get } from '../../../backend';
import Loader from '../../../components/Loader';

export default function Index(props) {

    const [usergroups, setUserGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        async function load() {
            setIsLoading(true);
            let data = await Get("/usergroups");
            setUserGroups(data.body);
            setIsLoading(false);
        }
        load();
    }, []);

    if (isLoading) {
        return <Loader />
    }

    return (
        <select className="form-control"  onChange={(e) => {props.onChange(e.target.value)}} defaultValue={props.selected}>
            <option>Choose user group</option>
            {usergroups.map((elem,idx) => {
                // return <option value={elem.id} key={idx} selected={elem.id===props.selected?' selected':''}>{elem.name}</option>;
                return <option value={elem.id} key={idx}>{elem.name}</option>;
            })}
        </select>           
    );
}