import React, { useEffect, useState } from 'react';
import { Get } from '../../../backend';
import PageRow from './PageRow';

export default function Pages(props) {
  const ParentId = props.ParentId;

  const [pages, setPages] = useState([]);

  useEffect(() => {
    async function Load() {
      var result = null;
      if (ParentId == null) {
        result = await Get(`/cms/root`);
      } else {
        result = await Get(`/cms/pages/${ParentId}`);
      }
      if (result.ok && result.body.length) {
        setPages(result.body);
      }
    }
    Load();
  }, []);

  return (
    pages.map((elem, idx) => {
      return (
        <PageRow page={elem} key={idx} depth={props.depth || 0} />
      )
    })
  );
}