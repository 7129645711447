import React, { useState, useEffect, useRef } from "react";
import { Get, Update } from "../../../backend";
import Loader from "../../../components/Loader";
import StatusMessage from "../../../components/StatusMessage";
import UserAdminNoteForm from "./useradminnote-form";

export default function UserAdminNote(props) {
  const textRef = useRef(null);

  const [user, setUser] = useState({});
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSaving(true);
  // };

  // useEffect(() => {
  //   async function LoadAdminNote(userId) {
  //     let result = await Get(`/user/${userId}/adminnote/`);
  //     if (result.ok) setNote(result.body.note);
  //   }
  //   if (props.user?.id !== undefined || props.user?.id !== null) {
  //     setIsLoading(true);
  //     LoadAdminNote(props.user.id);
  //     setIsLoading(false);
  //   }

  //   // setUser(props.user);
  // }, [props.user]);

  // useEffect(() => {
  //   async function Save(userId) {
  //     let data = {
  //       note: textRef.current.value,
  //     };
  //     let result = await Update(`/user/${userId}/adminnote/`, data);
  //     setServerStatusMessage(<StatusMessage serverResponse={result} />);
  //   }

  //   if (isSaving) {
  //     Save(props.user.id);
  //     setIsSaving(false);
  //   }
  // }, [isSaving]);

  // if (isLoading) {
  //   return <Loader />;
  // }

  const onSave = () => {
    setServerStatusMessage(
      <StatusMessage success={true}>Save ok</StatusMessage>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        {serverStatusMessage}
        <UserAdminNoteForm userId={props.user.id} onSave={onSave} />
        {/* <form className="forms-inline" onSubmit={onSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="personal_name">Note</label>
                <textarea
                  className="form-control"
                  rows="9"
                  ref={textRef}
                  defaultValue={note}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-primary mr-2">
                Submit
              </button>
            </div>
          </div>
        </form> */}
      </div>
    </div>
  );
}
