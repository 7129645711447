import React, { useState, useEffect, useRef } from "react";
import { get } from "sortablejs";
import { Get, Insert } from "../../../backend";
import Switch from "../../../components/Form/Switch";
import Loader from "../../../components/Loader";
import StatusMessage from "../../../components/StatusMessage";

export default function UserOptions(props) {
  const textRef = useRef(null);

  // const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const [serverStatusMessage, setServerStatusMessage] = useState("");
  // const [updateToServer, setUpdateToServer] = useState(false);

  const [allowTestMode, setAllowTestMode] = useState(false);
  const [testModeEnabled, setTestModeEnabled] = useState(false);

  useEffect(() => {
    loadUserOptions(props.user.id);
  }, [props.user.id]);

  const loadUserOptions = async (userId) => {
    setIsLoading(true);
    let result = await Get(`/user/${userId}/getuseroptions`);
    if (result.ok) {
      setAllowTestMode(result.body.AllowTestMode === "true");
      setTestModeEnabled(result.body.TestModeEnabled === "true");
    }
    // console.log(result);
    setIsLoading(false);
  };

  const saveUserOptions = async (userId) => {
    let data = {
      userid: userId,
      options: {
        AllowTestMode: allowTestMode.toString(),
        TestModeEnabled: testModeEnabled.toString(),
      },
    };
    setIsLoading(true);
    let result = await Insert(`/user/${userId}/setuseroptions`, data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <Switch
          id="allow_test_mode"
          checked={allowTestMode}
          onChange={() => {
            setAllowTestMode(!allowTestMode);
          }}
        >
          Is user allowed to use test mode
        </Switch>{" "}
        <Switch
          id="test_mode_enabled"
          checked={testModeEnabled}
          onChange={() => {
            setTestModeEnabled(!testModeEnabled);
          }}
        >
          Test mode enabled for user
        </Switch>
        <button
          onClick={() => {
            saveUserOptions(props.user.id);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
