import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import { Link } from "react-router-dom";
export default function Index() {

    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        async function load() {
            let result = await Get("/cms/templates");
            if (result.ok) {
                setTemplates(result.body);
            }
            setIsLoading(false);
        }
        load();
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <form>
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">Settings</h3>
                    <BreadCrumb />
                </div>
                <div className="row">

                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Available templates</h4>
                                <table className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templates.map((o, idx) => {
                                            return (
                                                <tr key={o.id}>
                                                    <td>{idx + 1}</td>
                                                    <td>{o.name}</td>
                                                    <td>
                                                        <Link to={{ pathname: `/settings/templates/form/${o.id}` }}>
                                                            Edit
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="row mt-4">
                                    <div className="col">
                                        <Link to={{ pathname: `/settings/templates/form` }}>
                                            <button type="button" className="btn btn-sm btn-outline-info btn-icon-text">
                                                <i className="icon-bag btn-icon-prepend"></i>New template
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}