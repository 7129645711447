import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import { TrainingContext } from '../../../components/Contexts/TrainingContext';
import { CourseContext } from '../../../components/Contexts/CourseContext';

export default function UserGroupsTab(props) {

  // const courseId = props.courseId;

  const checkboxesEl = useRef(null);


  const [isLoading, setIsLoading] = useState(true);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);
  const [groups, setGroups] = useState([]);
  const [acl, setAcl] = useState([]);
  //let selectedGroups = [];
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isInherited, setIsInherited] = useState(false);

  // let params = useParams();

  const training = useContext(TrainingContext);
  const course = useContext(CourseContext);

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let usergroups = await Get(`/usergroups/`);      
      if ( usergroups.ok ) 
        setGroups(usergroups.body);

        let aclResponse = await Get(`/acl/Course/${course.id}/Training/${training.id}`);
        if ( aclResponse.ok ) {
          setAcl(aclResponse.body);          
          for(let x in aclResponse.body ) {            
            if ( aclResponse.body[x].access == 10 ) {
              setIsInherited(true);              
            }
          }
        }      
        setIsLoading(false);
    }

    async function Save() {
      let o = {}
      if ( isInherited ) {
        o = {
          Id: course.id,
          Type: 'Course',
          GroupIds: [],
          Access: 10,
          ParentType: 'Training',
          ParentId: training.id
        }
      } else {
        o = {
          Id: course.id,
          Type: 'Course',
          GroupIds: selectedGroups,
          Access: 1,
          ParentType: 'Training',
          ParentId: training.id
        }
      }
      setIsLoading(true);                  
      let result = await Insert(`/acl`,o); 
      setIsLoading(false);
      setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>)      
      await Load();
    }
    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    } else {
      Load();
    }
  }, [updateToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loader />
  }

  const onSubmit = function (e) {
    e.preventDefault();    
    let boxes = e.target.querySelectorAll(".custom-control-input:checked");
    if ( e.target.querySelector("#inherit").checked ) {
      setIsInherited(true);
    } else {
      setIsInherited(false);
    }
    
    let vals = Array.from(boxes).map( x => x.value );
    setSelectedGroups(vals);
    setUpdateToServer(true);
  }

  const onChangeInheritedCheckBox = (e) => {
    if ( e.checked ) {
      checkboxesEl.current.style.display = 'none';
    } else {
      checkboxesEl.current.style.display = 'block';
    }
  }
  
  return (
    <>
      {serverStatusMessage}      
      <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-check">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="inherit" value="inherit" onChange={ (e) => {onChangeInheritedCheckBox(e.target)}} defaultChecked={isInherited}/>
              <label className="custom-control-label" htmlFor="inherit">Inherited from Training</label>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row" ref={checkboxesEl} style={{'display':isInherited?'none':'block'}}>
        <div className="col">
          {groups.map( (elem,idx) => {            
            
            let _id = elem.id;            
            let _checked = false;
            acl.forEach( (a) => {
              if ( a.userGroupId == elem.id ) _checked = true;
            });
            
            return (
              <div className="form-check" key={idx}>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id={_id} value={elem.id}  defaultChecked={_checked} />
                  <label className="custom-control-label" htmlFor={_id}>{elem.name}</label>
                </div>
              </div>
            );
          })}
          
        </div>
      </div>
      <div className="row">
        <div className="col">
            <button className="btn btn-primary" type="submit">Save</button>          
        </div>
      </div>
      </form>
    </>
  );
}