import React, { useState, useEffect, useRef } from "react";
import { Get } from "../../backend";

const DashboardMeters = () => {
  const [busy, setBusy] = useState(true);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    setBusy(true);
    loadTrainings();
    setBusy(false);
    async function loadTrainings() {
      let data = await Get("/trainings");
      setTrainings(data.body);
    }
  }, []);

  if (busy) return <div>Ladataan.</div>;

  return (
    <div className="row">
      <div className="col-12">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3>Suoritusmäärät</h3>
              <div className="d-flex flex-wrap">
                {trainings.map((tr, i) => {
                  return (
                    <div
                      key={tr.id}
                      style={{ width: "220px", marginRight: "20px" }}
                    >
                      <DashBoardForTraining training={tr} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashBoardForTraining = ({ training }) => {
  const [busy, setBusy] = useState(true);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setBusy(true);
    load();
    setBusy(false);

    async function load() {
      let r = await Get(`/reporting/getusersfortraining/${training.id}`);
      if (!r) {
        console.error("Background api call failed");
        return;
      }
      if (r.ok) {
        if (r.body.length > 0) {
          let numUserCompletedTraining = r.body.filter((x) => {
            return x.completedAt != null;
          }).length;

          let totalCount = r.body.length;
          setPercentage((numUserCompletedTraining / totalCount) * 100);
        }
      }
    }
  }, []);

  return (
    <div>
      <p className="card-description"></p>
      {busy ? (
        <div>Loading...</div>
      ) : (
        <DounutChartUi fillPercentage={percentage} />
      )}
      <h5 className="text-center">{training.name}</h5>
    </div>
  );
};

const DounutChartUi = ({ fillPercentage }) => {
  const chart = useRef();
  const counter = useRef();

  useEffect(() => {
    if (chart == null) return;

    let d = chart.current.querySelector(".inner-line");

    let totalLen = d.getTotalLength();
    let current = 0;
    let steps = totalLen / 100;
    d.setAttribute("stroke-dasharray", totalLen);

    if (fillPercentage > 0 && totalLen > 0) {
      let interval = setInterval(() => {
        let p = current / totalLen;
        counter.current.innerHTML = parseInt(p * 100) + "%";
        if (current >= totalLen - steps || p >= fillPercentage / 100) {
          clearInterval(interval);
          return;
        }
        current += steps;
        d.setAttribute("stroke-dashoffset", -current);
      }, 10);
    }
  });

  return (
    <div className="d-flex position-relative">
      <div
        className="position-absolute"
        style={{
          width: 200,
          height: 200,
          //   background: "rgba(1, 1, 1, 0.3)",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <h3 ref={counter} className="">
            0%
          </h3>
        </div>
      </div>

      <div style={{ width: "200px" }}>
        <svg
          viewBox="0 0 208.337 182.786"
          xmlns="http://www.w3.org/2000/svg"
          ref={chart}
        >
          <g
            class="page showed"
            custom-element="page-svg"
            transform="matrix(0.955921, 0, 0, 0.955921, -329.970947, 0.831071)"
          >
            <g
              class="content"
              transform="matrix(0.692068, 0, 0, 0.692068, 354.161, 10.346)"
            >
              <g
                transform="matrix(1,0,0,1,0,0)"
                data-layer-name="untitled layer"
                data-layer-color="#ff7f00"
                class="layer"
                custom-element="layer-svg"
                active=""
              >
                <g
                  transform="matrix(1, 0, 0, 1, 144.267, 141.1)"
                  custom-element="arc-object"
                  stroke="#00ff00"
                  strokeWidth="40"
                  fill="#FFFFFF"
                  stroke-opacity="1"
                  fill-opacity="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  class=""
                >
                  <path
                    data-name="arc-stroke"
                    fill="none"
                    d="M -90.74590301513672 99.12234497070312 A 134.38771272934852 134.38771272934852 0 1 1 86.7247543334961 102.65901947021484"
                    transform="matrix(1 0 0 1 0 0)"
                    class=""
                  />
                </g>
                <g
                  transform="matrix(1, 0, 0, 1, 144.267, 141.1)"
                  custom-element="arc-object"
                  stroke="#ccc"
                  strokeWidth="40"
                  fill="#FFFFFF"
                  stroke-opacity="1"
                  fill-opacity="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                >
                  <path
                    class="inner-line"
                    data-name="arc-stroke"
                    fill="none"
                    d="M -90.74590301513672 99.12234497070312 A 134.38771272934852 134.38771272934852 0 1 1 86.7247543334961 102.65901947021484"
                    transform="matrix(1 0 0 1 0 0)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

//export default React.memo(DashboardMeters);
export default DashboardMeters;
