import React, { useRef, useState } from 'react';
import './sortable-style.css';
import Draggable from './Draggable';
import Container from './Container';




export default function SortableList(props) {

    const sortList = useRef(null);
    const [items,setItems] = useState(props.items||[]);
  
    if (items.length === 0) {
        return <p>Nothing to sort</p>;
    }

    const onDrop = (e) => {       
        console.log("DROPPED")
        let sorted = applyDrag(items, e);
        setItems(sorted);
        if ( props.onSortEnd != null ) {
            props.onSortEnd(sorted);
        }
    }

    const applyDrag = (arr, dragResult) => {
        const { removedIndex, addedIndex, payload } = dragResult
        if (removedIndex === null && addedIndex === null) return arr
      
        const result = [...arr]
        let itemToAdd = payload
      
        if (removedIndex !== null) {
          itemToAdd = result.splice(removedIndex, 1)[0]
        }
      
        if (addedIndex !== null) {
          result.splice(addedIndex, 0, itemToAdd)
        }
      
        return result
    }


    return (
        <Container onDrop={onDrop}>
            {items.map((item, idx) => (
                <Draggable key={item.id}>                    
                    <strong>#{idx+1}</strong>&nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                </Draggable>                
            ))}
        </Container>
    );
}