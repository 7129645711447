import React, { useState, useEffect } from 'react';

import { Get,Insert } from '../../../backend';
import Loader from '../../../components/Loader';


import BreadCrumb from '../../../components/BreadCrumb';
import SortableList from '../../../components/SortableList';

export default function Index(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendToServer, setSendToServer] = useState(false);
  const [structure, setStructure] = useState([]);
  const [sortedItemGuids, setSortedItemGuids] = useState([]);
  
  const course = props.course;
  const training = props.training;

  let sortableItems = [];
  
  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let result = await Get(`/coursestructures/course/${course.id}`);
      setStructure(result.body);
      setIsLoading(false);
    }

    async function SaveSorting() {
      let result = await Insert(`/courseStructure/sort/${course.id}`, sortedItemGuids);
      if ( result.ok) {
        //alert("containers/CoursesPage/SortStructures/index OK");
      } else {
        alert("Sorting failed");
      }
      setSendToServer(false);
    }

    if (sendToServer) {
      SaveSorting();
    } else {
      Load();
    }
  }, [sendToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps
 
  const onSortEnd = function(list) {

    let tmp = list.map(i => i.id);
    setSortedItemGuids(tmp);
    setSendToServer(true);
  }


  if (isLoading) {
    return <Loader />
  }

  if (structure.length > 0) {
    sortableItems = structure.map((item) => {
      return {
        id: item.id,
        name:item.name
      }
    });
  }


  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Sort course structures</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
             
              <h3>Course structures</h3>
              <SortableList items={sortableItems} onSortEnd={onSortEnd}></SortableList>
              
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}