import React, { useState, useEffect, useRef } from 'react';

import { Get, Update } from '../../../../backend';
import Loader from '../../../../components/Loader';
import StatusMessage from '../../../../components/StatusMessage';
// import { JsonEditor as Editor } from 'jsoneditor-react';
// import 'jsoneditor-react/es/editor.min.css';
// import ace from 'brace';
// import 'brace/mode/json';
// import 'brace/theme/github';
import JSONEditor from '../../../../components/JsonEditor';
import Help from '../../../../components/Help';
import TaskDataGenerator from './Generator/TaskDataGenerator';

export default function TaskData(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [sendToServer, setSendToServer] = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    const [task, setTask] = useState({});

    useEffect(() => {
        async function Load() {
            setIsLoading(true);
            let data = await Get(`/task/${props.structure.structureTypeId}`);                                                 
            setTask(data.body);
            setIsLoading(false);
        }

        async function Save() {
            setIsLoading(true);      
            task.type = task.type.toString();

            let data = await Update(`/task/${props.structure.structureTypeId}`, task);            
            setIsLoading(false);
            setSendToServer(false);

            if (data.ok) {
                setServerStatusMessage(<StatusMessage success={true}>Save ok</StatusMessage>)
              } else {
                let m = JSON.stringify(data.errors);
                setServerStatusMessage(<StatusMessage success={false}>Save failed<br />{m}</StatusMessage>)
              }
        }
        if( sendToServer) {            
            Save();
        }
        Load();
    }, [props.structure.structureTypeId, sendToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

    const onInputChange = function(e) {
        task.type = e.target.value;                        
        console.log("onInputChange");
    }


    const onEditorChange = function(e) {                
        task.data = JSON.stringify(e);                
    }

    const onEditorError = function() {
        alert("JSON EDITOR ERROR!!!!!!!");
    }

    const save = function() {        
        setSendToServer(true);
    }

    const onTaskDataGenerator = (data) => {
        let tmp = task;
        task.data = data;
        // setTask(task);
        // console.log("JJSOB",JSON.stringify(data));
    }

    if(isLoading) {
        return <Loader/>
    }
    
    let c = {"options": [], "answer": []}
    if (task.data) {
        try {
            c = JSON.parse(task.data);
        } 
        catch (err ) 
        {
            console.log(err);
        }
    }
    // console.log("RND")

    // console.log("JJSON",JSON.stringify(c));


    const taskTypes = {
        0 : "NULL",
        1 : "SINGLESELECT",
        2 : "MULTISELECT",
        3 : "ORDER_THE_LIST",
        4 : "VIDEO",
        5 : "DRESS_UP_THE_CHARACTER",
        6 : "DIAL_THE_NUMBER" ,
        7 : "ORDER_THE_LETTERS",
        8 : "HANGMAN",
        9 : "COMBINE"
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <h4>Task</h4>        
                </div>    
            </div>

            {serverStatusMessage}

            <fieldset className="form-group">
                <div className="row">
                    <legend className="col-form-label col-sm-2 pt-0">Type</legend>
                    <div className="col-sm-9">
                        <select defaultValue={task.type} className="form-control" onChange={onInputChange}>
                            {Object.keys(taskTypes).map((e,idx) => {
                                return <option key={idx} value={e}>{taskTypes[e]}</option>                                
                            })}
                        </select>
                    </div>
                    <div className="col-sm-1">
                        <TaskDataOptionsHelp />
                    </div>  
                </div>
            </fieldset>

            {/* <fieldset className="form-group">
                <div className="row">
                <legend className="col-form-label col-sm-2 pt-0">Data</legend>
                <div className="col-sm-10">              
                    <TaskDataGenerator data={c} onChange={(e) => onTaskDataGenerator(e)} />
                </div>
                </div>
            </fieldset> */}

            <fieldset className="form-group" style={{height: "600px"}}>
                <div className="row" style={{height: "600px"}}>
                    <legend className="col-form-label col-sm-2 pt-0">Data</legend>
                    <div className="col-sm-10" style={{height: "600px"}}>              
                        <JSONEditor value={c} onChange={onEditorChange}/>
                        {/* <Editor value={c} onChange={onEditorChange} onError={onEditorError} ace={ace} theme="ace/theme/github" mode={Editor.modes.code}/> */}
                    </div>
                </div>
            </fieldset>

            <button onClick={save} className="btn btn-primary">Update</button>
        </>
    );
}

export function TaskDataOptionsHelp() {    
    let helpArray = `    
    {
        "options": [
          {
            "id": 1,
            "values": [
              {
                "content": "Pelasta vaarassa olevat ja estä lisäonnettomuudet",
                "value": 1
              },
              {
                "content": "Tee hÃ¤tÃ¤ilmoitus",
                "value": 2
              },
              {
                "content": "Anna tarvittava ensiapu",
                "value": 3
              },
              {
                "content": "Tee tilannearvio",
                "value": 4
              },
              {
                "content": "Opasta sairaskuljetus perille",
                "value": 5
              }
            ]
          }
        ],
        "answer": [
          {
            "id": 1,
            "value": [
              4,
              2,
              1,
              3,
              5
            ]
          }
        ]
      }        
    `;

    let helpSingle = `
    {
        "options": [
          {
            "id": 1,
            "content": "<img src=https://cdn.taitaen.fi/demo/turvallisuusmerkit/3.jpg><br><br>",
            "values": [
              {
                "content": "Ensiapuvälineiden sijaintiin opastava merkintä",
                "value": 1
              },
              {
                "content": "Pakollinen kulkusuunta",
                "value": 2
              },
              {
                "content": "UloskÃ¤ynti",
                "value": 3
              }
            ]
          },
          {
            "id": 2,
            "content": "<br><br><img src=https://cdn.taitaen.fi/demo/turvallisuusmerkit/1.jpg><br><br>",
            "values": [
              {
                "content": "Sydämentahdistimen opastekyltti",
                "value": 1
              },
              {
                "content": "Defibrillaattorin eli sydäniskurin opastekyltti",
                "value": 2
              },
              {
                "content": "Työterveyshoitajan vastaanotto",
                "value": 3
              }
            ]
          },
          {
            "id": 3,
            "content": "<br><br><img src=https://cdn.taitaen.fi/demo/turvallisuusmerkit/4.jpg><br><br>",
            "values": [
              {
                "content": "Saniteettitilat",
                "value": 1
              },
              {
                "content": "Desinfiointi",
                "value": 2
              },
              {
                "content": "Hätäsuihku",
                "value": 3
              }
            ]
          }
        ],
        "answer": [
          {
            "id": 1,
            "value": 1
          },
          {
            "id": 2,
            "value": 2
          },
          {
            "id": 3,
            "value": 3
          }
        ]
      }
    `
    return (
        <Help>
            <h3>Task data json</h3>
            <h4>Single method</h4>
            <pre>            
                {helpSingle}                       
            </pre>

            <h4>Array method</h4>
            <pre>            
                {helpArray}                       
            </pre>
        </Help>
    );
}