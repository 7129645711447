import React, { useState, useEffect } from "react";
import { Get } from "../../../backend";
import Loader from "../../../components/Loader";

export default function StateManagement(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [trainigState, setTrainigState] = useState([]);

  useEffect(() => {
    wow(props.user.id);
  }, [props.user.id]);

  const wow = async (userid) => {
    let result = await Get(`/userstatemanagement/${userid}`);
    setTrainigState(result.body);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="card">
      <div className="card-body">State: {trainigState.length} </div>

      {trainigState.map((row, i) => {
        return <TrainingState key={i} {...row} />;
      })}
    </div>
  );
}

const TrainingState = (props) => {
  return (
    <div>
      Training ID = {props.trainingId} - State= {props.trainingState}
      {props.courseStates.map((row, i) => {
        return <CourseState key={i} {...row} />;
      })}
    </div>
  );
};

const CourseState = (props) => {
  return (
    <div>
      Course ID = {props.courseId}
      <br />
      Course State= {props.courseState.state}
      Course startTime= {props.courseState.state}
      Course completionTime= {props.courseState.state}
      <br />
    </div>
  );
};
