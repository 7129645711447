import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, useParams } from "react-router-dom";
// import List from './List';
import { Get } from '../../backend';
import Form from './Form';
import Overview from './Overview';
import StructureForm from './StructureForm';
import SortStructures from './SortStructures';
import CreateStructureForm from './StructureForm/CreateStructureForm';
import Loader from '../../components/Loader';
import { Context } from '../../components/BreadCrumb/BreadCrumbContext';
import { TrainingContext } from '../../components/Contexts/TrainingContext';
import CourseProvider, {useCourseUpdate} from '../../components/Contexts/CourseContext';

function getCourseId() {
    // xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
    // 08d95e4a-c281-49dd-8fa5-de978277e653
    //let re = /course\/((.{8})\-(.{4})\-(.{4})\-(.{17}))/;
    let re = /course\/([(0-9)|(a-f)|\-]{36})/;
    let result = re.exec(window.location.pathname);
    if (result != null) {
        return result[1];
    }
    
    return null;
}

export default function Index() {

    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [training, setTraining] = useState(null);
    const [, dispatch] = useContext(Context);

    const [error, setError] = useState('');

    let params = useParams();

    useEffect(() => {
        async function Load(tId, cId) {            
            let data1 = await Get(`/training/${tId}`);
            let data2 = null;
            if (cId != null ) {
                data2 = await Get(`/course/${cId}`);           
                setCourse(data2.body)
                
            }
            
            setTraining(data1.body);
            setIsLoading(false);
            // TODO! Add error handling!! 
            // Facts: 
            //    Training should always be available
            //    Course may or may not be available
        }    
        let courseId = getCourseId();
        let trainingId = params.trainingId
        if ( trainingId !== null) {
            Load(trainingId, courseId);
        }
        
    }, []
    );
    
    if (isLoading) {
        return <Loader />
    }
    if (error.length > 0) {
        return <div>Error: {error}</div>
    }

    let base = `/training/${training.id}`

    return (
        <TrainingContext.Provider value={training}>
            <CourseProvider course={course}>
            
            {/* <CourseContext.Provider value={course}> */}
                <div>
                    <Switch>
                        <Route path={`${base}/course/create/`}>
                            <Form isNewCourse={true} />
                        </Route>

                        <Route path={`${base}/course/:courseId/form/`}>
                            <Form isNewCourse={false} />
                        </Route>

                        <Route path={`${base}/course/:courseId/overview`}>
                            <Overview course={course} />
                        </Route>

                        <Route path={`${base}/course/:courseId/structuresort`}>
                            <SortStructures course={course} training={training} />
                        </Route>

                        <Route path={`${base}/course/:courseId/structureform/:structureId`}>
                            <StructureForm />
                        </Route>

                        <Route path={`${base}/course/:courseId/structureform`}>
                            <CreateStructureForm />
                        </Route>
                    </Switch>
                </div>
            {/* </CourseContext.Provider> */}
            </CourseProvider>
        </TrainingContext.Provider>
    );
}