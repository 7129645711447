import React, { useEffect, useState, useRef } from 'react';
import { Get, Insert, Delete } from '../../../backend';
import { useParams, Redirect } from "react-router-dom";
import MainForm from "./MainForm";
import Tabs from "./Tabs";
import { confirmAlert } from 'react-confirm-alert';

import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import HeaderAndContent from '../../../components/HeaderAndContent';


export default function Index() {
  const [isLoading, setIsLoading] = useState(false);
  const [needsRedirect, setNeedsRedirect] = useState(false);
  const [redirectAfterDelete, setRedirectAfterDelete] = useState(false)
  const [updateToServer, setUpdateToServer] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [content, setContent] = useState({});
  const [deletePage, setDeletePage] = useState(false);
  let params = useParams();
  const Id = params.id ?? null;
  const isChild = params.isChild ?? false;

  useEffect(() => {
    async function Load() {
      setIsLoading(true);
      if (Id != null && !isChild) {
        let result = await Get(`/cms/${Id}`);
        if (result.ok) {
          setContent(result.body);
        } else {
          setServerStatusMessage(<StatusMessage serverResponse={result} />);
        }
      }
      setUpdateToServer(false);
      setIsLoading(false);
    }

    async function Save() {
      let data = content;
      data.sorting = parseInt(content.sorting);

      if (isChild) {
        data.parentId = Id;
        data.id = null;
      }
      let result = await Insert('/cms', data);

      if (isChild || Id == null) {
        setNeedsRedirect(true);
        setContent(result.body);
      }
      setServerStatusMessage(<StatusMessage serverResponse={result} />);
    }

    async function DeletePage() {
      let result = await Delete(`/cms/${Id}`);
      if ( result.ok ) {
        setRedirectAfterDelete(true);
      } else {
        setServerStatusMessage(<StatusMessage serverResponse={result} />);
      }
    }
    if (deletePage) {
      DeletePage();
    } else {
      if (updateToServer)
        Save();
      Load();
    }
  },
    [updateToServer, deletePage]);


  const onSubmit = () => {
    setUpdateToServer(true);
  }

  if (isLoading) {
    return <Loader />
  }

  if (needsRedirect) {
    const newUrl1 = `/content/${content.id}`;
    return <Redirect to={newUrl1}></Redirect>
  }
  if (redirectAfterDelete) {
    const newUrl2 = `/content/`;
    return <Redirect to={newUrl2}></Redirect>
  }


  if (!Id || isChild) {
    return (
      <div className="content-wrapper">
        <div className="page-header">
          <h3 className="page-title">Content </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MainForm content={content} onContentSave={onSubmit} setContent={setContent} />
          </div>
        </div>
      </div>
    );
  }

  const deleteCourseOnClick = function () {
    confirmAlert({
      title: 'Delete?',
      message: 'Are you sure to do this. This cannot be undone!',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setDeletePage(true);
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  return <>
    {serverStatusMessage}
    <Tabs content={content} onContentSave={onSubmit} setContent={setContent} onDelete={deleteCourseOnClick} />
  </>
    ;
}