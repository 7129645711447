import React from 'react';

import CourseListRow from './CourseListRow';

export default function CourseList(props) {


  if ( props.training == undefined || props.training == null ) {
    return (
      <blockquote className="blockquote">
        ERROR: No training passed
      </blockquote>
    );
  }
  if ( props.items == null || props.items.length === 0 )  {
      return (
        <blockquote className="blockquote">
          No courses 
        </blockquote>
      );
  }

  return (
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th> # </th>
          <th> Name </th>          
          
          <th  className="text-right"> Actions </th>                
        </tr>
      </thead>
      <tbody>
        {props.items.map((elem, idx) => {          
          return <CourseListRow key={idx} data={elem} training={props.training} index={idx + 1} />
        })}
      </tbody>
    </table>

  );
}