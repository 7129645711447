import React, { useEffect, useState } from "react";
import { Get } from "../../../backend";
import { useParams } from "react-router-dom";
import Progress from "../../../components/Progress";
export default function Index() {
  let params = useParams();
  const trainingId = params.trainingId;

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  useEffect(async () => {
    let r = await Get(`/reporting/gettrainingstateforallusers/${trainingId}`);

    setResult(r);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (result == null) {
    return <div>Report not done</div>;
  }

  if (!result.ok) return <div>Result query failed!</div>;

  return <ResultRenderer>{result.body}</ResultRenderer>;
}

const ResultRenderer = ({ children }) => {
  return (
    <div>
      <ul>
        <li>CTR = Completed training count</li>
        <li>CCC = Last Training Completed Course Count</li>
        <li>CC = Number of courses in training</li>
      </ul>
      <table className="mt-4 table table-striped table-bordered">
        <thead className="table-dark">
          <tr>
            <th>User</th>
            <th width="70%">Progress</th>
            <th title="Completed training count">CTR</th>
            <th title="Last Training Completed Course Count">CCC</th>
            <th title="Number of courses in training">CC</th>
          </tr>
        </thead>
        <tbody>
          {children.map((row, idx) => {
            let completionPercent = 0;
            let isComplete = false;

            if (row.lastTrainingStartDate !== null) {
              if (row.completedTrainingCount > 0) {
                isComplete = true;
                completionPercent = 100;
              } else {
                completionPercent = 0;
                if (row.lastTrainingCompletedCourseCount > 0) {
                  let courseCount = parseInt(
                    row.lastTrainingCompletedCourseCount,
                    10
                  );
                  let completed = parseInt(row.numberOfCoursesInTraining, 10);
                  completionPercent = (courseCount / completed) * 100;
                }
              }
            }

            return (
              <tr>
                <td>{row.loginName}</td>
                <td>
                  <Progress
                    completed={isComplete}
                    value={completionPercent}
                  ></Progress>
                </td>
                {/* <td>{row.trainingName}</td>
                <td>{row.lastTrainingCompletedDate}</td>
                <td>{row.lastTrainingStartDate}</td> */}
                <td>{row.completedTrainingCount}</td>
                <td>{row.lastTrainingCompletedCourseCount}</td>
                <td>{row.numberOfCoursesInTraining}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
