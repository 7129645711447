import React from 'react'
import {CONTENT_TYPES} from '../index.js'


export default function Empty(props) {

    const styles = {
        display:'flex',
        width:'100%',
        justifyContent: 'center',
        flexDirection:'column',
        alignItems:'center'
    }
    
    return (
        <div style={styles}>
            <div>        
                Choose content type
            </div>
            <div>
                {CONTENT_TYPES.map(m => 
                    {
                        return (
                            <div key={m.type} >
                                <button  onClick={() => props.onChange(m.type)} className="btn btn-outline-primary mt-1" >
                                    <img src={m.icon} className="bs-icon mr-2" alt="Widget image" />
                                    {m.name}
                                </button>
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )

}