import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import GroupsDropDown from './GroupsDropDown';
export default function Index() {

    const [isLoading, setIsLoading] = useState(true);    
    const [sendToServer, setSendToServer] = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    const [mappingRows, setMappingRows] = useState([]);

    const save = (e) => {
        e.preventDefault();
        setSendToServer(true);

    }
    const addRow = (e) => {
        e.preventDefault();
        
        let x = ({localGroupId: "", externalGroupId: ""});
        setMappingRows([...mappingRows, x]);
    }
    const removeRow = (e, idx) => {
        e.preventDefault();
        
        let f = mappingRows.filter( (element,arrayidx) => {
            return arrayidx != idx;
        });
        setMappingRows(f);
    }

    const setLocalGroupId = (e, idx) => {
        
        let rows = mappingRows;
        rows[idx].localGroupId = e;
        setMappingRows(rows);
    }
    const setExternalGroupId = (e, idx) => {
        let rows = mappingRows;
        rows[idx].externalGroupId = e;
        setMappingRows(rows);
    }

    useEffect(() => {
        async function load() {
            setIsLoading(true);
            let response = await Get("/tenantsettings/getexternalgroupmappings?method=provari");
            if ( response.ok ) {
                if (response.body != null) {
                    setMappingRows(response.body);
                }
            }
            setIsLoading(false);
        }


        async function save() {

            setIsLoading(true);
            let data = mappingRows            
            let response = await Insert("/tenantsettings/setexternalgroupmappings?method=provari", data);
            
            setIsLoading(false);
            setSendToServer(false);
        }
        if (sendToServer) 
            save();
        load();
    }, [sendToServer]);

    if (isLoading) {
        return <Loader />
    }

    
    return (
        <form>
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">Settings </h3>
                    <BreadCrumb />
                </div>
                {serverStatusMessage}
                <div className="row">

                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">External Group mapping</h4>
                                <p className="card-description">External authentication group mapping.</p>

                                <div className="row mb-3" style={{borderBottom: "1px solid black"}}>
                                    <div className="col-md-4">Local group name</div>
                                    <div className="col-md-8">External Group id</div>
                                </div>                                
                                <div>
                                    {mappingRows.map((e,i) => {                                        
                                        return <div className="row mt-1" key={i}>
                                        <div className="col-sm-4"><GroupsDropDown onChange={ (e) => setLocalGroupId(e, i)}  selected={e.localGroupId}/></div>
                                        <div className="col-sm-7"><input type="text" className="form-control" defaultValue={e.externalGroupId}  onChange={ (e) => setExternalGroupId(e.target.value, i)}/></div>
                                        <div className="col-sm-1">
                                            <button type="button" className="btn btn-danger btn-rounded btn-icon" onClick={(e) => removeRow(e, i) }>
                                                <i className="icon-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    })}
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <button className="btn btn-info btn-sm"  onClick={addRow}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <button className="btn btn-primary" onClick={save}>Save</button>
                </div>
            </div>
        </form>
    );
}