//import {Get} from '../backend';

// var aclList = [];

// export function ReloadACL() {
//     aclList = [];

//     let userState = JSON.parse(window.localStorage.getItem("userState"));
//     console.log("USERSTATE!", userState.acl);
//     if (userState?.acl) {
//         aclList = userState.acl;
//     }
// }

// export async function GetMyACL() {
//     let result = await Get("/me/getacl");
//     if ( result.ok )
//       return result.body;
//     return [];
//   }

export function HasAccess(input) {
  let aclList = window.tenantACL ?? [];

  let found = aclList.indexOf(input) > -1 ? true : false;
  // console.log(`Checking : ${input}, list length: ${aclList.length}`, found);
  return found;
}
