import React, { useState, useEffect } from 'react';

import { useParams } from "react-router-dom";
import { Get, Insert } from '../../../backend';

import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import HeaderAndContent from '../../../components/HeaderAndContent';
export default function ContentTab() {

  // const [ingress, setIngress] = useState({});
  // const [introduction1, setIntroduction1] = useState({});
  // const [introduction2, setIntroduction2] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);
  const [contentList, setContentList] = useState({});
  let params = useParams();

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let data = await Get(`/contentlist/trainings/${params.trainingId}`);
      
      if (data.ok) {
        setContentList(data.body);  
      }
      setIsLoading(false);
    }

    async function Save() {
      
      setIsLoading(true);

      let data = await Insert(`/contentlist/trainings/${params.trainingId}`,  {contentList: contentList});
      setServerStatusMessage(<StatusMessage serverResponse={data} />)      
      setIsLoading(false);
      setUpdateToServer(false);
    }

    if (updateToServer) {
      Save();
    }

    if (params.trainingId) {
      Load();      
    }

  }, [updateToServer, params.trainingId]);

  const onHeaderAndContentChange = (blockName, data) => {    
    contentList[blockName].header = data.header;
    contentList[blockName].htmlContent = data.content;
  }

  const onSubmit = function() {    
    setUpdateToServer(true);
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {serverStatusMessage}
      <div className="row">
        {Object.keys(contentList).map( (x) => {
          let data = contentList[x];          
          let output = "";
          if ( data != null) {
            output = <HeaderAndContent key={x} title={x} onChange={(data) => {onHeaderAndContentChange(x, data)}} header={data.header} content={data.htmlContent} />
          } else {
            output = <HeaderAndContent key={x} title={x} onChange={(data) => {onHeaderAndContentChange(x, data)}}/> 
          }
          return (
             output
          )
        })}        
      </div>

      <div className="row">
        <div className="col">
          <button type="button" className="btn btn-primary mr-2" onClick={onSubmit}>Submit</button>          
        </div>
      </div>
    </>
  );
}
