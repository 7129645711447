import React, {useEffect,useState} from 'react';
import Form from './Form';
import Overview from './Overview';
import { Route, Switch } from "react-router-dom";

export default function Index(props) {
  const rowStyle = {
    width: '100%',
    paddingLeft: '20px',
    borderTop: '1px solid green'
  }

  return (
    <Switch>
      <Route path="/content/form/:id?/:isChild?">
        <Form />
      </Route>
      <Route path="/content">
        <Overview />
      </Route>
    </Switch>
  );
}