import React, {useEffect, useRef, useState } from 'react';
import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
export default function MainForm(props) {

  const tmpl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  
    
  const headerOnChange = (e) => {
    props.setContent({...props.content, header:e.target.value});
  }
  
  const deleteButton = <div className="col text-right"><button type="button" className="btn btn-danger mr-2" onClick={props.onDelete}>Delete page</button></div>


  if(isLoading) {
    return <Loader />
  }

  return (    
      <div className="row">        
        <div className="col-md-12">
          <div className="row">
            <div className="col">            
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input type="text" className="form-control" id="title" placeholder="Title" defaultValue={props.content.header} onChange={headerOnChange}/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">            
              <div className="form-group">
                <label htmlFor="title">Sort order</label>
                <input type="number" className="form-control" id="sorting" placeholder="Sorting" defaultValue={props.content.sorting} onChange={(e) => {props.content.sorting = e.target.value}}/>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col">
              <button type="button" className="btn btn-primary mr-2" onClick={props.onContentSave}>Submit</button>
            </div>
            {props.content.id ? deleteButton : ''}
          </div>
        </div>
      </div>
  );
}