import React, { useState, useEffect } from "react";
import TabContainer from "../../../components/TabContainer";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { Get } from "../../../backend";
import { confirmAlert } from "react-confirm-alert";
import UserForm from "./userform";
import UserOptions from "./useroptions";
import Reporting from "./reporting";
import UserAddressData from "./useraddressdata";
import UserAdminNote from "./useradminnote";
import StateManagement from "./statemanagement";

import { useHistory } from "react-router-dom";

export default function Index() {
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  let params = useParams();

  useEffect(() => {
    async function LoadUser(userId) {
      let data = await Get(`/user/${userId}`);

      if (data.ok) {
        setUser(data.body);
        if (data.body.id != null) {
          setIsNewUser(false);
        }
      } else {
        setErrorMessage("User load failed!");
      }
    }

    if (params.userId) {
      LoadUser(params.userId);
    }
    setIsLoading(false);
  }, []);

  const deleteUserOnClick = function () {
    confirmAlert({
      title: "Delete user?",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Get(`/user/${params.userId}/softdeleteuser`).then((e) => {
              history.push("/users");
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessage != null) {
    return <div>{errorMessage}</div>;
  }

  let tabData = "";
  if (isNewUser) {
    tabData = (
      <UserForm
        TabName="Details"
        TabId="1"
        TabActive={true}
        key="1"
        isNewUser={isNewUser}
        user={{}}
      />
    );
  } else {
    tabData = (
      <TabContainer>
        <UserForm
          TabName="Details"
          TabId="1"
          TabActive={true}
          key="1"
          isNewUser={isNewUser}
          user={user}
        />
        <Reporting
          user={user}
          TabName="Reporting"
          TabId="repo"
          TabActive={false}
          key="repo"
        />
        <UserAddressData
          user={user}
          TabName="Company & Address"
          TabId="company"
          TabActive={false}
          key="company"
        />
        <UserOptions
          user={user}
          TabName="Options"
          TabId="options"
          TabActive={false}
          key="options"
        />
        <StateManagement
          user={user}
          TabName="State Mngmnt"
          TabId="state"
          TabActive={false}
          key="state"
        />
        <UserAdminNote
          user={user}
          TabName="Admin note"
          TabId="adminnote"
          TabActive={false}
          key="adminnote"
        />
      </TabContainer>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">
          User details: {user?.identityData?.displayName}
        </h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">{tabData}</div>
        <div className="col-md-12 grid-margin stretch-card">
          {!isNewUser && (
            <button className="btn btn-danger" onClick={deleteUserOnClick}>
              Delete user
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
