import React from 'react';
import Pages from './Pages';
import {Link} from "react-router-dom";

export default function PageRow(props) {
  
  const d = (props.depth || 0 ) * 20;

  const rowStyle = {   
    borderTop: '1px solid #c2c2c2',

  }

  const url = `/content/form/${props.page.id}`;
  const childUrl = `/content/form/${props.page.id}/true`;

  let p = props.depth || 0;
  let s = [];
  while(p--) {
    s.push(<i className="icon-arrow-right" key={p}></i>);
  }
  return ( 
    <>
    <tr>    
      <td>
          <Link to={url}><i className="icon-doc"></i> {s} {props.page.header}</Link>  
      </td>
        <td>
          <Link to={childUrl} className="btn btn-sm btn-outline-primary ml-5">Create subpage</Link>
        </td>
      </tr>
      <Pages ParentId={props.page.id} depth={props.depth+1} />
    </>
  );
}