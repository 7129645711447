import React from "react";
import { Route } from "react-router-dom";
import Form from "./Form";

export default function TenantPage() {
  return (
    <div>
      <Route path="/tenant/create-new-form">
        <Form />
      </Route>
    </div>
  );
}
