import React from 'react';
import { Route, Switch } from "react-router-dom";
import LoginOptions from './LoginOptions';
import ExternalGroupMapping from './ExternalGroupMapping';
import TemplateOptions from './TemplateOptions';
import TemplateForm from './TemplateOptions/Form';
import TenantSettingsForm from './TenantSettingsForm';
import RegistrationOptions from './RegistrationOptions';

export default function TrainingsPage() {

  return (
    <div>
      <Switch>
        <Route exact path="/settings/loginoptions">
          <LoginOptions />
        </Route>
        <Route exact path="/settings/groupmapping/">
          <ExternalGroupMapping />
        </Route>
        <Route exact path="/settings/templates">
          <TemplateOptions />
        </Route>
        <Route exact path="/settings/templates/form/:templateId?">
          <TemplateForm />
        </Route>
        <Route exact path="/settings/tenantsettings">
          <TenantSettingsForm />
        </Route>

        <Route exact path="/settings/registrationoptions">
          <RegistrationOptions />
        </Route>
      </Switch>
    </div>
  );
}