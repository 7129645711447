import React, { useState, useEffect, useContext } from 'react';

import { useParams } from "react-router-dom";
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import HeaderAndContent from '../../../components/HeaderAndContent';
import { CourseContext } from '../../../components/Contexts/CourseContext';
export default function ContentTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);
  const [contentList, setContentList] = useState({});

  const course = useContext(CourseContext);

  // let params = useParams();

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let data = await Get(`/contentlist/courses/${course.id}`);
      
      if (data.ok) {
        setContentList(data.body);  
      }
      setIsLoading(false);      
    }

    async function Save() {
      
      setIsLoading(true);
      let data = await Insert(`/contentlist/courses/${course.id}`,  {contentList: contentList});
      setServerStatusMessage(<StatusMessage serverResponse={data} />)      
      setUpdateToServer(false);
      setIsLoading(false);
    }

    if (course.id) {
      Load();
    }
    if (updateToServer) {
      Save();
    }
  }, [updateToServer]); // eslint-disable-line react-hooks/exhaustive-deps


  const onSubmit = function() {    
    setUpdateToServer(true);
  }

  const onHeaderAndContentChange = (blockName, data) => {    
    contentList[blockName].header = data.header;
    contentList[blockName].htmlContent = data.content;
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {serverStatusMessage}
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <h4>Course</h4>          
        </div>

        {Object.keys(contentList).map( (x) => {
          let data = contentList[x];          
          let output = "";
          if ( data != null) {
            output = <HeaderAndContent key={x} title={x} onChange={(data) => {onHeaderAndContentChange(x, data)}} header={data.header} content={data.htmlContent} />
          } else {
            output = <HeaderAndContent key={x} title={x} onChange={(data) => {onHeaderAndContentChange(x, data)}}/> 
          }
          return (
             output
          )
        })} 
      </div>

      <div className="row">
        <div className="col">
          <button type="button" className="btn btn-primary mr-2" onClick={onSubmit}>Submit</button>          
        </div>
      </div>
    </>
  );
}
