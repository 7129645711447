import React, { useState, useEffect } from 'react';

import TextContentList from './TextContentList';

function getOptionTemplate() {
    return {
        id : 0,
        content : "",
        answers : [],
    };
}

function getAnswerTemplate() {
    return {
        id: 0,
        content : "",
        value: undefined,
        isCorrect: false
    };
}

export default function TaskDataGenerator(props) {
    
    const [obj,setObj] = useState(props.data || {}); 
    const [options, setOptions] = useState([]);
    const [showHide, setShowHide] = useState([]);

    useState( () => {
        if ( props?.data?.options?.length > 0 ) {
            let o = props.data.options.map(e =>{
                    let answers = e.values.map( a => {
                        return {
                            value: a.value,
                            content: a.content,                            
                        }
                    })                    
                    return {id: e.id, content: e.content, answers: answers}
            });
            setOptions(o);            
        }

    },[]); 
    
    const addOption = () => {
        let tmp = [];        
        tmp = tmp.concat(options); //  
        
        let x = getOptionTemplate();
        x.id = tmp.length + 1;
        x.content = "";

        tmp.push(x);             
        setOptions(tmp);        
    }

    const answersModified = (id, e) => {
        
        for(let x in obj.options ) { 
            if (obj.options[x].id == id ){        
                obj.options[x].values = e;
            }
        }
        
        onChange();
    }
    const toggle = (id) => {
        let hidden = showHide.find( x => x == id);
        if ( hidden ) 
            setShowHide([...showHide.filter( x => x != id )])
        else {
            setShowHide([...showHide, id])
        }
    }

    const contentChanged = (id, event) => {

        for(let x in obj.options ) { 
            if (obj.options[x].id == id ){        
                obj.options[x].content = event.target.value;
            }
        }
        
        onChange();
    }

    const onChange = () => {
        if( props.onChange ) {
            let clean = {}
            clean.options = obj.options.map( x => {
                let n = {
                    id:x.id, 
                    values: x.values.map(v => {                    
                        return {
                            content: v.content,
                            selected: v.selected,
                            value: v.value
                        }
                    }
                )}
                return n;
            });            
            props.onChange(clean);
        }
    }

    const optionOutputTemplate = (obj) => {
        
        let id = obj.id
        let content = obj.content;
        let hidden = showHide.find( x => x == id);

        let cl = "mt-2";
        if ( hidden ) 
            cl += " d-none";

        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-2">
                            <input type="text" readOnly defaultValue={id} className="form-control" />
                        </div>
                        <div className="col">
                            <input type="text" placeholder="Option content" className="form-control" defaultValue={content} onChange={(ev) => contentChanged(obj.id, ev)}/>
                            <div className={cl}>                        
                                <TextContentList list={obj.answers} onChange={(e) => answersModified(obj.id, e)}></TextContentList>                        
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 text-center pt-2">
                    <span onClick={() => (toggle(id))}>{hidden?<i className="icon-arrow-down"></i>:<i className="icon-arrow-up"></i>}</span>
                </div>
            </div>
        );
    }

    return (        
        <div className="row">
            <div className="col">
                <button onClick={addOption}>Add option</button>
                <h3>{options.length} options</h3>
                {options.map((e,i) => {
                    
                    let color = i%2==0 ? '#a2a2a2' : '#e2e2e2';
                    let s = {backgroundColor: color, padding:"0.4rem", marginBottom: "0.4rem"};

                    return (                        
                        <div key={i} style={s}>            
                            {optionOutputTemplate(e)}                            
                        </div>
                    );
                })} 
            </div>                
        </div>        
    );
}