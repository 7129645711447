import React, {useState, useEffect} from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import { Link } from "react-router-dom";
export default function Index() {

    const [isLoading, setIsLoading] = useState(true);
    const [usergroups, setUserGroups] = useState([]);
    const [settings, setSettings] = useState({});    
    const [sendToServer, setSendToServer] = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    //let settings = {};    

    useEffect(() => {

        if (sendToServer) {
            SaveToDb();            
            LoadFromDb();
            setSendToServer(false);
        } else {
            LoadFromDb();
        }

        async function SaveToDb() {
            setIsLoading(true);
            let result = await Insert("/tenantsettings", settings);
            setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>)
            setIsLoading(false);
            
        }
        async function LoadFromDb() {
            
            let result = await Get("/tenantsettings");                        
            if ( result.ok ) {                
                if (result.body != null) {                    
                    // settings = result.body;                    
                    setSettings(result.body);
                }
            }
        }
    }, [sendToServer]); 

    const save = function(e) {
        e.preventDefault();
        setSendToServer(true);
    }

    const setSettingValue = function(loginMethod, key, value ) {
        
        if ( settings.allowedLoginMethods === undefined || settings.allowedLoginMethods === null  ) {
            settings.allowedLoginMethods = availableLoginMethods;
        } 
        if (settings.allowedLoginMethods[loginMethod] === undefined ) {
            settings.allowedLoginMethods[loginMethod] = {
                id:loginMethod,                
                enabled: false, 
                defaultGroup: ""   
            }
        }
        settings.allowedLoginMethods[loginMethod][key] = value;     
    }

    const getSettingValue = function(loginMethod, key) {
        
        if (settings === undefined) {            
            return null;
        }
        if (settings.allowedLoginMethods === undefined || settings.allowedLoginMethods == null) {            
            return null;
        }
        if (settings.allowedLoginMethods[loginMethod] === undefined ) {            
            return null;
        } 
        if (settings.allowedLoginMethods[loginMethod][key] === undefined ) {            
            return null;
        }        
        return settings.allowedLoginMethods[loginMethod][key];
    }
 

    const selectDefaultGroup = function(e) {
        setSettingValue(e.target.name, "defaultGroup", e.target.value);
    }

    const onCheckboxChange = function(e) {
        setSettingValue(e.target.name, "enabled",e.target.checked ? true : false);        
    }

    const availableLoginMethods = {
        'normal' : {
            id:"normal",
            name: "User and password",
            enabled: false, 
            defaultGroup: "",     
        },
        'facebook' :{
            id:"facebook",
            name: "Facebook",
            enabled: false,  
            defaultGroup: "",          
        },
        'google' : {
            id:"google",
            name: "Google",
            enabled: false,  
            defaultGroup: "",          
        },
        'twitter' : {
            id:"twitter",
            name: "Twitter",            
            enabled: false,
            defaultGroup: "",
        },
        'provari' : {
            id:"provari",
            name: "Proväri",            
            enabled: false,
            defaultGroup: "",
        }                
    };

    useEffect(() => {
      async function load() {
        setIsLoading(true);
        let data = await Get("/usergroups");
        setUserGroups(data.body);
        setIsLoading(false);
      }
      load();
    }, []);

    if(isLoading) {
        return <Loader />
    }
    
    return (
        <form>
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title"> Settings </h3>
                <BreadCrumb />
            </div>
            {serverStatusMessage}
            <div className="row">
                
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Login options</h4>                            
                            {Object.entries(availableLoginMethods).map((o,idx) => {

                                let method = o[1];

                                let checkboxName = method.id;
                                let checkboxId = "method"+idx+checkboxName;
                                let methodIdx = "method"+idx+checkboxName;
                                let selectName = method.id;                                
                                let checked = getSettingValue(method.id, "enabled");                                
                                let defaultGroupValue = getSettingValue(method.id, "defaultGroup") ?? "";
                                
                                return (
                                    <div className="row" key={methodIdx}>
                                        <div className="col-md-4">
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor={checkboxId}>
                                                <input type="checkbox" id={checkboxId} className="form-check-input" name={checkboxName} onChange={(e) => {onCheckboxChange(e)}} defaultChecked={checked}/> {method.name} <i className="input-helper"></i></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Default group</label>
                                                <div className="col-sm-8 d-flex">
                                                    {usergroups.length < 1 ? <div className="align-self-center text-danger">No user groups defined</div> : 
                                                        <select className="form-control" name={selectName} onChange={ (e) => {selectDefaultGroup(e)}}>
                                                            <option>Choose default group</option>
                                                            {
                                                                usergroups.map((elem,idx) => {
                                                                    return <option value={elem.id} key={idx} selected={elem.id===defaultGroupValue?' selected':''}>{elem.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">{method.id == "provari"? <Link to={{ pathname: `/settings/groupmapping/` }} className="text-dark ml-auto mb-3 mb-sm-0"><button type="button" className="btn btn-sm btn-outline-dark btn-icon-text"><i className="icon-doc btn-icon-prepend"></i>Group mapping</button></Link>: ''}</div>
                                    </div>  
                                )
                            })}                       
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <button className="btn btn-primary" onClick={save}>Save</button>
            </div>
        </div>
        </form>
    );
}