import React from 'react';

export const languageOptions = [
    {
        id:0,
        name: "Finnish"
    },
    {
        id:1,
        name: "Enkku"
    }
];



export default function LanguageSelector(props) {

//   const [isLoading, setIsLoading] = useState(false);
//   const [newTrainingId, setNewTrainingId] = useState("");
//   const [serverStatusMessage, setServerStatusMessage] = useState("");
//   const [updateToServer, setUpdateToServer] = useState(false);

//   const [name, setName] = useState("");
//   const [languageCode, setLanguageCode] = useState("");

    return (
        <div>
            <select className="form-control" onChange={(e) => {props.onChange(e.target.value)}} defaultValue={props.value || null }>
                {languageOptions.map((lang) => { 
                    return <option value={lang.id} key={lang.id}>{lang.name}</option>
                })}
            </select>
            {/* <input type="text" className="form-control"  onChange={(e) => {setLanguageCode(e.target.value)}}/> */}            
        </div>
    )

}
