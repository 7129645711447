import React from 'react';
import {Route} from "react-router-dom";
import List from './List';
import Form from './Form';

export default function Index() {
  return (
    <div>
      <Route path="/usergroups/form/:id?">
        <Form />
      </Route>
      <Route exact path="/usergroups">
        <List />
      </Route>
    </div>
  );
}