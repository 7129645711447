import React, {useState} from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import './JsonEditor.css';


/**
 * @type JSONEditor
 * @property {object} [value=null] - Json content to be rendered / modified
 * @property {Function} [onChange] - Callback function for editor change 
 */
export default function JSONEditor(props) {
    
    const [jsonData,] = useState(props.value || {});
    // const cont = useRef();
    // const tool = useRef();

    // useEffect(() => {
    //     if ( tool.current != null ) {
    //         let e = tool.current;
    //         e.addEventListener( 'mousedown', () => {});
    //         e.addEventListener( 'mouseup', () => {});
    //         e.addEventListener( 'mousemove', () => {});
    //     }
    //      console.log("tooli", tool)


    // }, []);


    const onEditorChange = (e) => {
        // console.log("JSON EDITOR Change", typeof(props.onChange) );
        if ( typeof(props.onChange) == 'function') {
            props.onChange(e);
        }
    }    

    return (     
        <div style={{height:"600px"}}>
        <div className="json-editor-container" >
            <Editor value={jsonData}  ace={ace} theme="ace/theme/github" mode={Editor.modes.code} onChange={onEditorChange} />    
            {/* <div className="json-editor-resizer" ref={tool}>Grab here</div> */}
        </div>
        </div>
    );
}