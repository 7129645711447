import React, { useState, useEffect, useContext } from 'react';
import { Link,Redirect  } from "react-router-dom";
import {Insert } from '../../../backend';
import { UIContext } from '../../../components/Contexts/UIContext';
import LanguageSelector from '../../../components/LanguageSelector';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function Index() {

  const { setRefetchTrainings } = useContext(UIContext);

  const [isLoading, setIsLoading] = useState(false);
  const [newTrainingId, setNewTrainingId] = useState("");
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);

  const [name, setName] = useState("");
  const [languageCode, setLanguageCode] = useState("");


  useEffect(() => {

    async function Save() {
      
      setIsLoading(true);
      var training = {name: name, languagecode: languageCode || "0"};      
      let result = await Insert(`/training/`, training);
      
      if ( result.ok ){
        
        setNewTrainingId(result.body.id);
        setRefetchTrainings(true);
      }

      setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>)            
      setIsLoading(false);
    }

    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    }

  }, [updateToServer]); 

  if (isLoading) {
    return <Loader />
  }

  if( newTrainingId.length > 0 ) {
    // /trainings/overview/08d9105a-6a52-41e7-8c70-9ed8bd68af6e
    let r = "/trainings/overview/" + newTrainingId ;
    return <Redirect to={r} />

  }

  const onSubmit = function (e) {
    e.preventDefault();
    setUpdateToServer(true);
  }

  
  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Create new training</h3>
        <Link         
            to={{pathname: `/`}}
          ><button type="button" className="btn btn-primary btn-rounded btn-icon">
          <i className="icon-home"></i>
        </button></Link>
      </div>
      
      {serverStatusMessage}

      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input type="text" className="form-control" id="name" placeholder="Name" onChange={(e) => {setName(e.target.value)}}/>
      </div>
      <div className="form-group">
        <label htmlFor="languagecode">Language</label>
        {/* <input type="text" className="form-control" id="languagecode" placeholder="0" defaultValue="0" onChange={(e) => {setLanguageCode(e.target.value)}}/> */}
        <LanguageSelector onChange={(e) => {setLanguageCode(e);} }/>
      </div>
      <button type="button" onClick={onSubmit} className="btn btn-primary mr-2">Submit</button>
    </div>
      
      
  );
}