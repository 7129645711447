import { createContext, useState } from 'react'

export const UserContext = createContext({
  user: null,
  setUser: () => null,
});

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const response = {
    user,
    setUser,
  };

  return (
    <UserContext.Provider value={response}>
      {children}
    </UserContext.Provider>
  )
}
