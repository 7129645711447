import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function UserGroupsTab() {

  const [isLoading, setIsLoading] = useState(true);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);
  const [groups, setGroups] = useState([]);
  const [acl, setAcl] = useState([]);
  //let selectedGroups = [];
  const [selectedGroups, setSelectedGroups] = useState([]);

  let params = useParams();

  useEffect(() => {

    async function Load() {
      setIsLoading(true);
      let usergroups = await Get(`/usergroups/`);      
      if ( usergroups.ok ) 
        setGroups(usergroups.body);

        let aclResponse = await Get(`/acl/Training/${params.trainingId}`);
        if ( aclResponse.ok ) {
          setAcl(aclResponse.body);
        }      
      setIsLoading(false);
    }

    async function Save() {

      let o = {
        Id: params.trainingId,
        Type: 'Training',
        GroupIds: selectedGroups,
        Access: 1
      }
      setIsLoading(true);                  
      let result = await Insert(`/acl`,o);      
      
      setIsLoading(false);
      setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>)      
      await Load();
    }
    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    } else {
      Load();
    }
  }, [updateToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loader />
  }

  const onSubmit = function (e) {
    e.preventDefault();    
    let boxes = e.target.querySelectorAll(".custom-control-input:checked");
    
    let vals = Array.from(boxes).map( x => x.value );
    setSelectedGroups(vals);
    setUpdateToServer(true);
  }

  return (
    <>
      {serverStatusMessage}
      <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col">
          {groups.map( (elem,idx) => {            
            
            let _id = elem.id;
            // let _checked = selectedGroups.indexOf(_id) > -1?true:false;
            let _checked = false;
            acl.forEach( (a) => {
              if ( a.userGroupId == elem.id ) _checked = true;
            });
            
            return (
              <div className="form-check" key={idx}>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id={_id} value={elem.id}  defaultChecked={_checked} />
                  <label className="custom-control-label" htmlFor={_id}>{elem.name}</label>
                </div>
              </div>
            );
          })}
          <button className="btn btn-primary" type="submit">Save</button>          
        </div>
      </div>
      </form>
    </>
  );
}