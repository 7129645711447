import React, { useState, useEffect } from 'react';
import { Get, Update } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function UserAddressData(props) {

    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    
    const onSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
    }

    useEffect(() => {        
        async function LoadUserData(userId) {
            let result = await Get(`/user/${userId}/useraddressdata`);
            if ( result.ok && result.body ) {                
                setUserData(result.body);
            }
        }
        if (props.user?.id !== undefined || props.user?.id !== null ){
            setIsLoading(true);
            LoadUserData(props.user.id);
            setIsLoading(false);
        }

        // setUser(props.user);
    }, [props.user]);

    useEffect(() => {

        async function Save(userId) {
            let result = await Update(`/user/${userId}/useraddressdata`, userData);
            setServerStatusMessage(<StatusMessage serverResponse={result}/>);
        }
        if (isSaving) {            
            Save(props.user.id);
            setIsSaving(false);
        }
    }, [isSaving])


    if (isLoading ) {
        return <Loader />
    }

    return (
        <div className="card">
            <div className="card-body">
                {serverStatusMessage}
                <form className="forms-inline" onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col">

                            <div className="row">
                                <div className="col-12 bg-primary p-3 mb-3">
                                    <strong>Company details</strong>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_name">Company name</label>
                                        <input type="text" className="form-control" id="company_name" placeholder="Company name" onChange={(e) => { userData.companyName = e.target.value}} defaultValue={userData.companyName} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_phone">Company phone number</label>
                                        <input type="text" className="form-control" id="company_phone" placeholder="Phone number"  onChange={(e) => { userData.companyPhone = e.target.value}} defaultValue={userData.companyPhone} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_street_address">Company street address</label>
                                        <input type="text" className="form-control" id="company_street_address" placeholder="Street address" onChange={(e) => { userData.companyStreetAddress = e.target.value}} defaultValue={userData.companyStreetAddress}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_zip_code">Company zip code</label>
                                        <input type="text" className="form-control" id="company_zip_code" placeholder="Zip code"  onChange={(e) => { userData.companyZipCode = e.target.value}} defaultValue={userData.companyZipCode} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_city">Company city</label>
                                        <input type="text" className="form-control" id="company_city" placeholder="City" onChange={(e) => { userData.companyCity = e.target.value}} defaultValue={userData.companyCity} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="company_country">Company country</label>
                                        <input type="text" className="form-control" id="company_country" placeholder="Country"  onChange={(e) => { userData.companyCountry = e.target.value}} defaultValue={userData.companyCountry} />
                                    </div>
                                </div>                               
                            </div>
                        </div>
                        <div className="col ml-1">
                            <div className="row">
                                <div className="col-12 bg-primary p-3 mb-3">
                                    <strong>Personal details</strong>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_name">Name</label>
                                        <input type="text" className="form-control" id="personal_name" placeholder="Name" onChange={(e) => { userData.personalName = e.target.value}} defaultValue={userData.personalName}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_phone">Personal phone number</label>
                                        <input type="text" className="form-control" id="personal_phone" placeholder="Personal phone number" onChange={(e) => { userData.personalPhone = e.target.value}} defaultValue={userData.personalPhone}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_street_address">Personal street address</label>
                                        <input type="text" className="form-control" id="personal_street_address" placeholder="Personal Street address" onChange={(e) => { userData.personalStreetAddress = e.target.value}} defaultValue={userData.personalStreetAddress}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_zip_code">Personal zip code</label>
                                        <input type="text" className="form-control" id="personal_zip_code" placeholder="Personal Zip code" onChange={(e) => { userData.personalZipCode = e.target.value}} defaultValue={userData.personalZipCode}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_city">Personal city</label>
                                        <input type="text" className="form-control" id="personal_city" placeholder="Personal City" onChange={(e) => { userData.personalCity = e.target.value}} defaultValue={userData.personalCity}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="personal_country">Personal country</label>
                                        <input type="text" className="form-control" id="personal_country" placeholder="Personal Country" onChange={(e) => { userData.personalCountry = e.target.value}} defaultValue={userData.personalCountry}/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">                    
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary mr-2">Submit</button>                        
                        </div>
                    </div>
                </form>
            </div>                        
        </div>
            
        
    );
}
