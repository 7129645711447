import React, { useEffect, useState, useRef } from 'react';
import {Get, Insert} from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';

export default function ACL(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [updateToServer, setUpdateToServer] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");  
  const [groups, setGroups] = useState([]);
  const [acl, setAcl] = useState([]);  
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isInherited, setIsInherited] = useState(false);
  const checkboxesEl = useRef(null);

  const hasInheritedFlag = (aclList) => {
    for( var x in aclList ) {
      if ( aclList[x].access == 10) 
        return true;
    }
    return false;
  }


  useEffect(() => {

    async function Load(contentid) {
      setIsLoading(true);
      let usergroups = await Get(`/usergroups/`);      
      if ( usergroups.ok ) 
        setGroups(usergroups.body);
      
        let aclResponse = await Get(`/acl/CMSPage/${contentid}`);
        if ( aclResponse.ok ) {
          setAcl(aclResponse.body);
          
          if ( hasInheritedFlag(aclResponse.body) ) {
            setIsInherited(true);
          }
        }      
      setIsLoading(false);
    }

    async function Save() {

      let o = {
        Id: props.content.id,
        Type: 'CMSPage',
        GroupIds: selectedGroups,
        Access: 1,
        ParentType: 'CMSPage',
        ParentId: props.content.parentId
      }      
      if (isInherited) {
        o.Access = 10;
        o.GroupIds = [];        
      }
      
      setIsLoading(true);                  
      let result = await Insert(`/acl`,o);      
      
      setIsLoading(false);
      setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>)      
      await Load();
    }
    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    } else {
      if(props?.content?.id )
        Load(props.content.id);
    }
  }, [updateToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loader />
  }
  if ( props.content === null || props.content.id === undefined ) {
    return <div>props.content is invalid</div>
  }

  const onSubmit = function (e) {
    e.preventDefault();    
    let boxes = e.target.querySelectorAll(".custom-control-input:checked");
    let vals = Array.from(boxes).map( x => x.value );
    setSelectedGroups(vals);
    setUpdateToServer(true);
  }

  const onChangeInheritedCheckBox = (e) => {
    if ( e.checked ) {
      checkboxesEl.current.style.display = 'none';
      setIsInherited(true);
    } else {
      checkboxesEl.current.style.display = 'block';
      setIsInherited(false);
    }
  }

  var inheritHtml = 
    <div className="row">
      <div className="col">
        <div className="form-check">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="inherit" value="inherit" onChange={ (e) => {onChangeInheritedCheckBox(e.target)}} defaultChecked={isInherited}/>
            <label className="custom-control-label" htmlFor="inherit">Inherited from parent</label>
          </div>
        </div>
      </div>
    </div>
  ;

  return (
    <>
      {serverStatusMessage} 
      <form onSubmit={onSubmit}>

      {props.content.parentId == null ? '': inheritHtml}
      
      <div className="row" ref={checkboxesEl} style={{display:isInherited?'none':'block'}}>
        <div className="col">
          {groups.map( (elem,idx) => {            
            
            let _id = elem.id;            
            let _checked = false;
            acl.forEach( (a) => {
              if ( a.userGroupId === elem.id ) _checked = true;
            });
            
            return (
              <div className="form-check" key={idx}>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id={_id} value={elem.id}  defaultChecked={_checked} />
                  <label className="custom-control-label" htmlFor={_id}>{elem.name}</label>
                </div>
              </div>
            );
          })}
          
          
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button className="btn btn-primary" type="submit">Save</button>
        </div>
      </div>
      </form>
    </>
  );
}