

const Reducer = (state, action) => {    
    let s = {}    
    switch (action.type) {        
        case 'SET_COURSE':
            s = {
                ...state,
                course: action.payload
            }            
            window.localStorage.setItem("breadcrumbState", JSON.stringify(s));      
            return s;
        case 'SET_TRAINING':
            
            s = {
                ...state,
                training: action.payload,
                course: {}
            }            
            window.localStorage.setItem("breadcrumbState", JSON.stringify(s));      
            return s;
        default:                        
            return state;
    }
};

export default Reducer;