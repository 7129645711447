import React, { useState, useEffect } from "react";
import { Post } from "../../../backend";
import Loader from "../../../components/Loader";
import DateDisplay from "../../../components/DateDisplay";
import "./reporting.css";

export default function Reporting(props) {
  useEffect(() => {
    async function load() {
      let data = {
        UserId: props.user.id,
      };
      let result = await Post("/reporting/detaileduserreport", data);
      if (result.ok) {
        setReportData(result.body);
      }
      setIsLoading(false);
    }

    load();
  }, []);

  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return <Loader />;
  }

  if (!reportData || reportData.length == 0) {
    return (
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row border p-2">Nothing to report</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="row reporting-header">
              <div className="col-3">Name</div>
              <div className="col-2">Started at</div>
              <div className="col-2">Completed at</div>
              <div className="col-4">Progress</div>
            </div>

            <div className="even_odd_children">
              {reportData.map((row, idx) => (
                <TrainingRow key={idx} data={row}></TrainingRow>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TrainingRow(props) {
  let isComplete = true;
  let value = 100;

  //   console.log(props);

  if (props.data.completedAt == null) {
    isComplete = false;
    value = 0;
    if (props.data.numberOfUniqueCoursesCompleted > 0) {
      value =
        (props.data.numberOfUniqueCoursesCompleted /
          props.data.numberOfCourses) *
        100;
    }
  }

  let children = "";
  if (props.data.courseStates != null) {
    children = props.data.courseStates.map((row, idx) => (
      <CourseRow key={idx} data={row}></CourseRow>
    ));
  }
  return (
    <>
      <div className="row reporting-training-row">
        <div className="col-3">
          <strong>{props.data.trainingName}</strong>
        </div>
        <div className="col-2">
          <DateDisplay>{props.data.startedAt}</DateDisplay>
        </div>
        <div className="col-2">
          <DateDisplay>{props.data.completedAt}</DateDisplay>
        </div>
        <div className="col-4">
          <Progress value={value} completed={isComplete}></Progress>
        </div>
      </div>
      {children}
    </>
  );
}

export function CourseRow(props) {
  const [toggle, setToggle] = useState(false);

  let isComplete = true;
  let value = 100;
  if (props.data.completedAt == null) {
    isComplete = false;
    value = 0;
    if (props.data.numberOfCompletedCourseStructures > 0) {
      value =
        (props.data.numberOfCompletedCourseStructures /
          props.data.numberOfCourseStructures) *
        100;
    }
  }

  let children = props.data.courseStructureStates.map((row, idx) => (
    <CourseStructureRow key={idx} data={row}></CourseStructureRow>
  ));

  return (
    <>
      <div className="row reporting-course-row">
        <div className="col-3" onClick={() => setToggle(!toggle)}>
          {props.data.courseName}
        </div>
        <div className="col-2">
          <DateDisplay>{props.data.startedAt}</DateDisplay>
        </div>
        <div className="col-2">
          <DateDisplay>{props.data.completedAt}</DateDisplay>
        </div>
        <div className="col-4">
          <Progress value={value} completed={isComplete}></Progress>
        </div>
      </div>
      {toggle && children}
    </>
  );
}

export function CourseStructureRow(props) {
  return (
    <div className="row reporting-coursestructure-row">
      <div className="col-3">
        <i>{props.data.courseStructureName}</i>
      </div>
      <div className="col-2">
        <DateDisplay>{props.data.startedAt}</DateDisplay>
      </div>
      <div className="col-2">
        <DateDisplay>{props.data.completedAt}</DateDisplay>
      </div>
    </div>
  );
}

// export function DateDisplay(props) {
//     if ( !props.children || props.children.length < 10 )
//         return null;

//     let dateString = props.children;
//     let d = new Date(dateString);

//     let short = d.getDate() + "." + (d.getMonth()+1) + "." + d.getFullYear();
//     let long = short + " " + d.getHours().toString().padStart(2,"0") + ":" + d.getMinutes().toString().padStart(2,"0") + ":" + d.getSeconds().toString().padStart(2,"0");
//     return <div title={long}>{d.getDate()}.{d.getMonth()+1}.{d.getFullYear()}</div>
// }

export function Progress(props) {
  let style = {
    width: props.value + "%",
  };

  let color = props.completed ? "bg-primary" : "bg-danger";
  let classes = "progress-bar " + color;

  return (
    <div className="progress">
      <div
        className={classes}
        role="progressbar"
        aria-valuenow={props.value}
        aria-valuemin="0"
        aria-valuemax="100"
        style={style}
      ></div>
    </div>
  );
}
