import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { Get, Insert, Update } from "../../../backend";
import StatusMessage from "../../../components/StatusMessage";

// import UserForm from './userform';

export default function Index() {
  let params = useParams();

  const [isBusy, setIsBusy] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [formData, setFormData] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();

    let data = {
      name: formData.name,
      apikey: formData.apikey,
    };

    let r = await Insert("/tenant/create-new-tenant", data);
    setIsComplete(true);
  };

  if (isBusy) {
    return <Loader />;
  }

  if (isComplete) {
    return (
      <div className="content-wrapper">
        <div className="page-header">
          <h3 className="page-title">Create new tenant</h3>
          <BreadCrumb />
        </div>
        <div className="card">
          Tenant created!
          <br />
          Logout & login to see changes
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Create new tenant</h3>
        <BreadCrumb />
      </div>
      <div className="card">
        <div className="card-body">
          {serverStatusMessage}
          <div className="row">
            <div className="col-6">
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Tenant name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Tenant name"
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                    }}
                    defaultValue={formData?.name}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="apikey">Tenant api key</label>
                  <input
                    type="text"
                    className="form-control"
                    id="apikey"
                    name="apikey"
                    placeholder="ApiKey"
                    onChange={(e) => {
                      setFormData({ ...formData, apikey: e.target.value });
                    }}
                    defaultValue={formData?.apikey}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
