import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { constants } from 'smooth-dnd';
const { wrapperClass } = constants;


class Draggable extends React.Component {
	render() {
		if (this.props.render) {
			return React.cloneElement(this.props.render(), { className: wrapperClass });
		}
		
		const clsName = `${this.props.className ? (this.props.className + ' ') : ''}`
		return (
			<div {...this.props} className={`${clsName}${wrapperClass}`} >
                <i className="icon-cursor-move" style={{color:"#1bdbe0", marginRight: "1rem"}}></i>
				{this.props.children}
			</div>
		);
	}
}

export default Draggable;