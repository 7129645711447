import React from 'react';
import {Route} from "react-router-dom";
import List from './List';
import Form from './Form';
import Import from './Import';

export default function UsersPage() {
  return (
    <div>
      <Route path="/users/form/:userId?">
        <Form />
      </Route>
      <Route exact path="/users/import">
        <Import />
      </Route>
      <Route exact path="/users">
        <List />
      </Route>
    </div>
  );
}