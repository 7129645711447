import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Get, Update, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import StatusMessage from '../../../components/StatusMessage';
import { TrainingContext } from '../../../components/Contexts/TrainingContext';
import { CourseContext, useCourseUpdate } from '../../../components/Contexts/CourseContext';
import LanguageSelector from '../../../components/LanguageSelector';

export default function FormTab(props) {

    const history = useHistory();
    // const trainingId = props.trainingId;
    // const courseId = props.courseId;

    const [courseData, setCourseData]                   = useState({});
    const [updateToServer, setUpdateToServer]           = useState(false);
    const [isLoading, setIsLoading]                     = useState(false);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    const [redirectAfterSave, setRedirectAfterSave]     = useState("");

    const training = useContext(TrainingContext);
    const course = useContext(CourseContext);

    

    // const creatingNewCourse = props.isNewCourse;

    useEffect(() => {

        async function Load() {
            let data = {};
            if (course != null && course?.id != null ) {
                setIsLoading(true);
                data = await Get(`/course/${course.id}`);
                setCourseData(data.body);
                setIsLoading(false);
            } else {
                data = {
                    name: ""
                }
                setCourseData(data);
            }
        }

        async function Save() {

            let result = null;
            if ( props.isNewCourse) {
                courseData.trainingId = training.id;
                if ( courseData.languageCode == undefined || courseData.languageCode == null ) courseData.languageCode = "0"; 
                result = await Insert(`/course`, courseData);
                if (result.ok ) {
                    let id = result.body.id;
                    setCourseData(result.body);
                    setRedirectAfterSave(`/training/${training.id}/course/${id}/form`);                     
                }
            } else {
                // courseData.id = course.Id
                result = await Update(`/course/${course.id}`, courseData);  
                // TODO: Update CourseContext after update
            }
            setServerStatusMessage(<StatusMessage serverResponse={result}></StatusMessage>);
            setIsLoading(false);
            setUpdateToServer(false);
        }

        if (updateToServer) {
            Save();
        }
        Load();

    }, [updateToServer, course?.id, training.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const nameChange = function (e) {
        courseData.name = e.target.value;
    }

    const languageCodeChange = function (e) {
        courseData.languageCode = e;
    }

    const onSubmit = function (e) {
        e.preventDefault();
        setUpdateToServer(true);
    }

    let updt = useCourseUpdate()
    updt(courseData);
    if (isLoading) {
        return <Loader />
    }

    if ( redirectAfterSave ) {
        return <Redirect to={redirectAfterSave}></Redirect>
    }

    return (
        <>
            {serverStatusMessage}
        
            <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" placeholder="Course name" onChange={nameChange} defaultValue={courseData.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="language">Language</label>
                    <LanguageSelector onChange={languageCodeChange} value={courseData.languageCode} />                     
                </div>
                <button type="submit" className="btn btn-primary mr-2">Submit</button>
            </form>
        </>
    );
}