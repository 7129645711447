import React, {useEffect,useState, Suspense} from 'react';
import { Get, Insert } from '../../backend';
// import StatusMessage from '../StatusMessage';
import Loader from '../Loader';


export function RenderWidget(props) {
    
    const onWidgetChange = (e) =>  {
        if ( typeof(props.onChange) === "function" ) {
            props.onChange(e);
        }
    }

    const Component = React.lazy(() => import(`./Widgets/${props.type}.js`));
    let content = props.content;
    return (
        <Suspense fallback={<div>Loading...</div>}>                
            <Component onChange={(e) => {onWidgetChange(e)}} content={content} />                
        </Suspense>
    )
}

export default function Index(props) {


    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [config, setConfig] = useState({});
    //const [configContent, setConfigContent] = useState([]);
    const [contents, setContents] = useState([])

    useEffect(() => {
        async function load() {
            let conf = await Get(`/tenant/getconfigurationpart/${props.ConfigurationPart}`);            
            if ( conf.ok ) {
                setConfig(conf.body);
            }

            let data = await Get(`/configurablecontent/${props.OwnerName}/${props.OwnerId}`);            
            if ( data.ok ) {
                if ( data.body != null )
                    setContents(JSON.parse(data.body.value));
            }
            setIsLoading(false);
        }
        load();
    }, [])

    useEffect(() => {
        async function save() {            
            setIsLoading(true);
            let data = {
                value : JSON.stringify(contents)
            }
            let result = await Insert(`/configurablecontent/${props.OwnerName}/${props.OwnerId}`, data);
            
            setIsSaving(false);
            setIsLoading(false);
        }
        
        if ( isSaving) {            
            save();
        }
    }, [isSaving])

    const getConfigContent = () => {
        if (config.content !== undefined && config.content !== null) {
            return config.content;
        } else if (config[props.ConfigurationPropertyName] !== undefined && config[props.ConfigurationPropertyName].content !== null) {
            return config[props.ConfigurationPropertyName].content;
        }
    }


    const onSubmit = () => {
        setIsSaving(true);
        
    }


    const collectInput = (id,value) => {
        
        let isNewValue = true;

        contents.map( (e) => {
            if ( e.id == id) {
                e.value = value
                isNewValue = false;
            }
        });

        if ( isNewValue ) {
            contents.push( {id,value});
        }
    }

    if (isLoading) 
        return <Loader />

    if ( config === undefined || config === null ) {
        return <p>Configuration missing</p>
    }    

    let configContent = getConfigContent();
    if ( configContent.length === 0 ) {
        return <p>Configuration content missing</p>
    }    
    
    return (
        <div>
            {configContent.map( (conf) =>  {
                let content = contents.find( (el) => el.id == conf.id )?.value;
                

                return (
                    <div className="row" key={conf.id}>
                        <div className="col-xs-12 col-sm-2">
                            <strong>{conf.description}</strong>
                            {conf.required == true && <span className="text-danger">*</span>}
                        </div>
                        <div className="col-xs-12 col-sm-10">
                            <RenderWidget type={conf.type} content={content} onChange={(value) => {collectInput(conf.id, value)}}/>
                        </div>
                    </div>
                )}
            )}            

            <button type="button" className="btn btn-primary mr-2" onClick={onSubmit}>Submit</button>
        </div>
    )
}