import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Switch = (props) => {
  const [checked, setChecked] = useState(props.checked || false);

  const invertChecked = (e) => {
    let v = !checked;

    setChecked(v);
    if (props.onChange) props.onChange(v);
  };

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={props.id}
        checked={checked}
        onChange={invertChecked}
      />
      <label className="custom-control-label" htmlFor={props.id}>
        {props.children}
      </label>
    </div>
  );
};

// Switch.propTypes = {
//   id: PropTypes.string,
//   label: PropTypes.string,
//   checked: PropTypes.bool,
//   onChange: PropTypes.func,
//   children: PropTypes.string,
// };

export default Switch;
