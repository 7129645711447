import React, {useState, useEffect} from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get, Insert } from '../../../backend';
import Loader from '../../../components/Loader';
import { useParams } from "react-router-dom";

import JSONEditor from '../../../components/JsonEditor';
import Help from '../../../components/Help';
export default function Index(props) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);    
    const [templateObj, setTemplateObj] = useState({});
    const [dirty,setDirty] = useState(false);

    let params = useParams();

    useEffect(() => {
        async function load() {
        
            let result = await Get(`/tenant/getconfigurationtemplate`);
            if ( result.ok ) {
                // setTemplate(result.body);
                setTemplateObj(result.body);
            }                         
            setIsLoading(false);
        }
        load();
    },[]) 

    useEffect( () => {
        async function save() {
            let data = templateObj;
            let result = await Insert(`/tenant/saveconfigurationtemplate`, data );
            

            // let result = null;
            // let data = {
            //     name:template?.name,
            //     templateJson:JSON.stringify(templateObj)
            // }
            // if(template?.id != undefined &&template?.id != null)
            //     result = await Update(`/cms/template/${template.id}`, data);
            // else
            //     result = await Insert(`/cms/template`,data);
             
            // if( result.ok ) {
            //     setTemplate(result.body);
            //     setTemplateObj(JSON.parse(result.body.templateJson));
            // }
        
            setDirty(false);
            setIsSaving(false);
        }
        if (dirty)
            save();
    }, [isSaving] );

    const onSave = (e) => {
        e.preventDefault();
        setIsSaving(true);
    }

    const onEditorChange = (e) => {
        setDirty(true);
        setTemplateObj(e);
    }

    if(isLoading || isSaving) {
        return <Loader />
    }
    // if(isRemovingComplete) {
    //     return <Redirect to="/settings/templates"/>;
    // }
    
    return (
        <form>
            <div className="content-wrapper">
                <div className="page-header">
                    <h3 className="page-title">Tenant setting form </h3>
                    <BreadCrumb />
                </div>            
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="float-right"><TemplateOptionsHelp /></div>
                                <h4 className="card-title">Settings</h4>                               

                                <fieldset className="form-group">
                                    <div className="row">
                                        <legend className="col-form-label col-sm-2 pt-0">Data</legend>
                                        <div className="col-sm-10">                                            
                                            <JSONEditor value={templateObj} onChange={onEditorChange}/>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col">
                                        {dirty&&<button type="button" className="btn btn-primary mr-2" onClick={onSave}>Save</button>}
                                    </div>                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}


export function TemplateOptionsHelp() {

    let help = `
    {
        "tenantConfiguration": {
          "availableLoginMethods": [
            {
              "id": "normal",
              "method": "normal",
              "description": "Tunnus & salasana"
            },
            {
              "id": "facebook",
              "method": "facebook",
              "description": "Facebook kirjautuminen"
            },
            {
              "id": "custom-1",
              "method": "Custom",
              "description": "Räätälöity autentikaatio"
            }
          ]
        },
        "trainingConfiguration": {
            "content": [
              {
                "id": "mainImage",
                "type": "Image",
                "description": "Pääkuva",
                "required": false
              },
              {
                "id": "ingress",
                "type": "HtmlEditor",
                "description": "Koulutuksen kuvaus",
                "required": true
              }
            ]
        },
        "courseConfiguration": {
          "content": [
            {
              "id": "mainImage",
              "type": "Image",
              "description": "Pääkuva",
              "required": false
            },
            {
              "id": "mainImages",
              "type": "ImageCollection",
              "description": "Karuselllli",
              "required": false
            },
            {
              "id": "ingress",
              "type": "HtmlEditor",
              "description": "Kurssin kuvaus",
              "required": true
            },
            {
              "id": "headerImage",
              "type": "Image",
              "description": "Kuvaus 1 (näkyy kohdassa x)",
              "required": false
            },
            {
              "id": "themeColor",
              "type": "Color",
              "description": "Teeman väri",
              "required": true
            },
            {
              "id": "themeGradient",
              "type": "Gradient",
              "description": "Teeman liukuväri",
              "required": true
            }
          ]
        }        
      }
    `;


    return (
        <Help>
            <p>Tenant settings help</p>
            <pre>            
                {help}                       
            </pre>
        </Help>
    );
}
