import React, { useState, useEffect } from 'react';

import { Update } from '../../../../backend';
import Loader from '../../../../components/Loader';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

export default function JsonEditor(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [sendToServer, setSendToServer] = useState(false);
    const [data,] = useState(props.data);

    useEffect(() => {

        // async function Load() {
        //     setIsLoading(true);
        //     let data = await Get(`/CourseStructureDataContainer/${data.id}`);             
        //     setData(data.body);
        //     setIsLoading(false);
        // }

        async function Save() {


            setIsLoading(true);            
            let response = await Update(`/CourseStructureDataContainer/${data.id}`, data);             
            setIsLoading(false);
        }
        if( sendToServer) {
            Save();
        }        
    }, [sendToServer]); // eslint-disable-next-line react-hooks/exhaustive-deps

    
    const save = function() {        
        setSendToServer(true)
    }
    const onEditorChange = function(e) {                
        data.data = JSON.stringify(e);        
    }

    const onEditorError = function() {
        alert("JSON EDITOR ERROR!!!!!!!");
    }

    if(isLoading) {
        return <Loader/>
    }
    
    return (
        <>

        <div className="col-md-12 grid-margin stretch-card">
          <h4>JSON DATA</h4>
        </div>
        <Editor value={JSON.parse(data.data)} onChange={onEditorChange} onError={onEditorError} ace={ace} theme="ace/theme/github" mode={Editor.modes.code}/>
        <button onClick={save} className="btn btn-primary">Update</button>
        </>
    );

}