import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
import { Link } from "react-router-dom";
export default function Index() {


    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    
    useEffect(() => {
        async function load() {
            setIsLoading(true);
            let data = await Get(`/managementuser`);
            setUsers(data.body);
            setIsLoading(false);
        }
        load();
    }, []);

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">Management Users</h3>
                <BreadCrumb />
            </div>
            <div className="row">
                <div className="col-lg-6 grid-margin stretch-card">

                    <div className="card card-secondary">

                        <span className="card-body ">
                            <h4 className="card-title mb-sm-0">Links</h4>
                            <Link to={{ pathname: `/managementusers/form` }}>
                                <button type="button" className="btn btn-outline-info btn-icon-text btn-icon-text m-2 "><i className="icon-doc btn-icon-prepend"></i> Create a user</button>
                            </Link>
                        </span>
                    </div>
                </div>

                <div className="col-lg-6 grid-margin stretch-card">
                    <div className="card card-secondary">
                        <span className="card-body">
                            <h4 className="card-title mb-sm-0">Details </h4>

                            <table className="table table-bordered mt-2">
                                <tbody>
                                    <tr>
                                        <td>Number of users</td>
                                        <td>n/a</td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th> # </th>
                                        <th>LoginName</th>
                                        
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th className="text-right"> Actions </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((obj, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td> {idx + 1} </td>
                                                <td> {obj.login}</td>                                                
                                                <td> {obj.name}</td>
                                                <td> {obj.email}</td>
                                                <td className="text-right">
                                                    <Link to={{ pathname: `/managementusers/form/${obj.id}` }}>
                                                        <button type="button" className="btn btn-sm btn-outline-dark btn-icon-text">
                                                            <i className="icon-doc btn-icon-prepend"></i>Configure</button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}