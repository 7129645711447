import React from "react";
import BreadCrumb from "../components/BreadCrumb";

export default function Page(props) {
  const header = props.header ?? null;

  return (
    <div className="content-wrapper">
      {header && (
        <div className="page-header">
          <h3 className="page-title">{header}</h3>
        </div>
      )}

      {props.children}

      {/* <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card-body bg-white">{props.children}</div>
        </div>
      </div> */}
    </div>
  );
}
