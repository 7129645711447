
import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../../components/BreadCrumb/BreadCrumbContext';

import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
import { useParams, Link } from "react-router-dom";
import CourseList from './CourseList';

export default function Overview() {

  let params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [training, setTraining] = useState({});
  const [linkedGroups, setLinkedGroups] = useState([]);
  const [courses, setCourses] = useState({});

  const [,dispatch] = useContext(Context);

  let isOnline = false;

  const isOnlineCheck = () => {
    // quick and dirty!!!!
    if ( training != null ) {
      var d = new Date();
      var n = d.getFullYear() + "-" + (d.getMonth()+1).toString().padStart(2, 0) + "-" + d.getDate().toString().padStart(2,0);

      var s = training.publishDate.split("T")[0];
      var e = training.unpublishDate.split("T")[0];
      
      if ( n >= s && n <= e ) {
        isOnline = true;
      } else {
        isOnline = false;
      }
    }
  } 

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      let data1 = await Get(`/training/${params.trainingId}`);
      setTraining(data1.body);

      dispatch({type:'SET_TRAINING', payload: data1.body});

      let data2 = await Get(`/courses/${params.trainingId}`);
      setCourses(data2.body);
      setIsLoading(false);      

      let linkedGroups = await Get(`/traininggroups/${params.trainingId}`);           
      if ( linkedGroups.ok ) {
        setLinkedGroups(linkedGroups.body.map( x => x.userGroupName)); 
      }

    }
    load();
  }, [params.trainingId]);

  if (isLoading) {
    return <Loader />
  }

  isOnlineCheck();

  return (
    <div className="content-wrapper">

      <div className="page-header">
        <h3 className="page-title">{training.name} </h3>        
      </div>


      <div className="row">
        <div className="col-lg-6 grid-margin stretch-card">
          
          <div className="card card-secondary">
            
            <span className="card-body ">
              <h4 className="card-title mb-sm-0">Links</h4>
              <Link  to={{pathname: `/trainings/form/${params.trainingId}`}}>
                <button type="button" className="btn btn-outline-info btn-icon-text btn-icon-text m-2 "><i className="icon-doc btn-icon-prepend"></i> Configure {training.name} </button>
              </Link> 
              <Link  to={{pathname: `/training/${params.trainingId}/course/create`}} className="text-dark ml-auto mb-3 mb-sm-0">                  
                  <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text m-2"><i className="icon-people btn-icon-prepend"></i> Add new course </button>
              </Link>
              <Link to={{pathname: `/trainings/sortcourses/${params.trainingId}`}} className="text-dark ml-auto mb-3 mb-sm-0">
                <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text m-2 "><i className="icon-equalizer btn-icon-prepend"></i> Sort courses</button>
              </Link>

              <Link to={{pathname: `/reporting/${params.trainingId}`}} className="text-dark ml-auto mb-3 mb-sm-0">
                <button type="button" className="btn btn-outline-info btn-icon-text  btn-icon-text m-2 "><i className="icon-equalizer btn-icon-prepend"></i>Reporting</button>
              </Link>
            </span>
          </div>
        </div>

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card card-secondary">
            <span className="card-body">
            <h4 className="card-title mb-sm-0">Details </h4>

              <table className="table table-bordered mt-2">
                <tbody>
                <tr>
                  <td width="25%">Status</td>
                  <td>
                    {
                    isOnline? 
                      <div className="badge badge-success p-1">Online</div>
                      :
                      <div className="badge badge-danger p-1">Offline</div>
                    }                    
                  </td>
                </tr>
                <tr>
                  <td>Language</td>
                  <td>{training.languageCode}</td>
                </tr>
                <tr>
                  <td>Publish date</td>
                  <td>{training.publishDate}</td>
                </tr>
                <tr>
                  <td>Unpublish date</td>
                  <td>{training.unpublishDate}</td>
                </tr>
                <tr>
                  <td>User groups</td>
                  <td>{linkedGroups.map((e, idx) => <span key={idx}>{e}<br/></span>)}</td>
                </tr>
                </tbody>
              </table>
            </span>
          </div>
        </div>

      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-sm-flex align-items-center mb-4">
                <h4 className="card-title mb-sm-0">Courses</h4>                         
              </div>
              <CourseList items={courses} training={training}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}