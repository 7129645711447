import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Get, Update, Insert } from "../../../backend";
import StatusMessage from "../../../components/StatusMessage";

export default function UserForm(props) {
  const [user, setUser] = useState({});
  const [identityData, setUserIdentityData] = useState({});
  const [userGroups, setUserGroups] = useState([]);

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serverStatusMessage, setServerStatusMessage] = useState("");
  const [updateToServer, setUpdateToServer] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    setUser(props.user);

    if (
      props.user?.identityData !== undefined ||
      props.user?.identityData !== null
    ) {
      setUserIdentityData(props.user.identityData);
    }

    if (props.user?.groups !== undefined || props.user?.groups !== null) {
      setUserGroups(props.user.groups);
    }
  }, [props.user]);

  useEffect(() => {
    async function LoadGroups() {
      setIsLoading(true);
      let groups = await Get(`/usergroups`);
      setGroups(groups.body);
      setIsLoading(false);
    }
    LoadGroups();
  }, []);

  useEffect(() => {
    async function Save() {
      setIsLoading(true);
      let result = null;

      user.identityData = identityData;
      user.groups = userGroups;

      if (user.id != null) {
        result = await Update(`/user/${user.id}`, user);
      } else {
        result = await Insert(`/user/`, user);
      }

      if (!result.ok) {
        setServerStatusMessage(
          <StatusMessage serverResponse={result}></StatusMessage>
        );
      } else {
        if (result.body?.id != null) {
          setRedirectUrl(`/users/form/${result.body.id}`);
        }
      }

      setIsLoading(false);
    }
    if (updateToServer) {
      Save();
      setUpdateToServer(false);
    }
  }, [updateToServer, identityData, user, userGroups]); // eslint-disable-next-line react-hooks/exhaustive-deps

  let readonly = user?.identityData?.authenticationSource != null;

  const onSubmit = (e) => {
    e.preventDefault();

    let checkedGroupCheckboxes = [];
    for (let x = 0; x < e.target.elements.length; x++) {
      let elem = e.target.elements[x];
      if (elem.type === "checkbox") {
        if (elem.checked) {
          checkedGroupCheckboxes.push(elem.value);
        }
      }
    }
    setUserGroups(checkedGroupCheckboxes);
    setUpdateToServer(true);
  };

  if (redirectUrl != null && redirectUrl.length > 1) {
    ////console.log("Redirecting to", redirectUrl);
    window.location.href = redirectUrl;
    return <Redirect to={redirectUrl} path={redirectUrl}></Redirect>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (identityData == undefined) {
    setUserIdentityData({ loginName: "", password: "" }); /// dirty...
    return <div>Please wait..</div>;
  }

  return (
    <div className="card">
      <div className="card-body">
        {serverStatusMessage}
        <form
          className="forms-inline"
          onSubmit={onSubmit}
          autocomplete="new-password"
        >
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="taitaenloginname">Login name</label>
                <input
                  autocomplete="new-password"
                  type="text"
                  className="form-control"
                  id="taitaenloginname"
                  placeholder="Username"
                  onChange={(e) => {
                    identityData.loginName = e.target.value;
                  }}
                  defaultValue={identityData.loginName}
                  readOnly={readonly}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="taitaenpassword">Password</label>
                <input
                  type="password"
                  autocomplete="new-password"
                  className="form-control"
                  id="taitaenpassword"
                  placeholder="Password"
                  onChange={(e) => {
                    identityData.password = e.target.value;
                  }}
                  readOnly={readonly}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div style={{ marginLeft: 1.5 + "em" }}>
                <strong>Linked groups </strong>
                {groups.map((obj, idx) => {
                  let k = "checkbox_" + idx;
                  let isGroupSelected = false;
                  if (userGroups !== undefined) {
                    isGroupSelected = userGroups.indexOf(obj.id) > -1;
                  }

                  return (
                    <div className="form-group" key={idx}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={obj.id}
                          id={k}
                          defaultChecked={isGroupSelected}
                        />
                        <label className="form-check-label" htmlFor={k}>
                          {obj.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!readonly && (
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              )}
            </div>
            <div className="col">
              <UserExtraDetails
                identityData={identityData}
                readonly={readonly}
                setIdentityData={setUserIdentityData}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export function UserExtraDetails(props) {
  const [identityData, setIdentityData] = useState({});

  useEffect(() => {
    setIdentityData(props.identityData);
  }, [props.identityData]);

  if (
    Object.keys(identityData).length === 0 &&
    identityData.constructor === Object
  ) {
    return <div>Populating user extra details</div>;
  }

  const updateToParent = () => {
    props.setIdentityData(identityData);
  };

  if (props.readonly) {
    return (
      <div className="row">
        <div className="col-md-6">
          <table className="table">
            <tbody>
              <tr>
                <th colSpan="2">External login info</th>
              </tr>
              <tr>
                <td>Authentication source</td>
                <td>{identityData?.authenticationSource}</td>
              </tr>
              <tr>
                <td>Display Name</td>
                <td>{identityData?.displayName}</td>
              </tr>
              <tr>
                <td>EmailAddress</td>
                <td>{identityData?.emailAddress}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="alert alert-secondary" role="alert">
            User details
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="displayName">Display Name</label>
            <input
              type="text"
              className="form-control"
              id="displayName"
              placeholder=""
              defaultValue={identityData.displayName}
              onChange={(e) => {
                identityData.displayName = e.target.value;
                updateToParent();
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="emailAddr">Email Address</label>
            <input
              type="text"
              className="form-control"
              id="emailAddr"
              placeholder=""
              defaultValue={identityData.emailAddress}
              onChange={(e) => {
                identityData.emailAddress = e.target.value;
                updateToParent();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
