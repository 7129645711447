
import React, { useEffect, useState } from 'react';
import { Get } from '../../../backend';
import Loader from '../../../components/Loader';
import BreadCrumb from '../../../components/BreadCrumb';
import EditButton from '../../../components/EditButton';
import OverviewButton from '../../../components/OverviewButton';


export default function List() {

  const [isLoading, setIsLoading] = useState(true);
  const [trainings, setTrainings] = useState([]);
  useEffect(() => {
    async function load() {
      setIsLoading(true);
      let data = await Get("/trainings");
      setTrainings(data.body);
      setIsLoading(false);
    }
    load();
  }, []);

  if (isLoading) {
    return <Loader />
  }



  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Trainings</h3>
        <BreadCrumb />
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Name </th>
                    <th> Tenant </th>
                    <th> Language </th>
                    <th> Status </th>
                  </tr>
                </thead>
                <tbody>
                  {trainings.map((elem, idx) => {
                    return (
                      <tr key={idx}>
                        <td> {idx+1} </td>
                        <td> {elem.name} </td>
                        <td> {elem.tenantId} </td>
                        <td> FI </td>
                        <td> <OverviewButton path={`/courses/${elem.id}`} />
                         <EditButton path={`/trainings/form/${elem.id}`} /></td>
                      </tr>
                    );

                  })}                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}