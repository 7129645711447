import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import { TrainingContext } from './Contexts/TrainingContext';
import { CourseContext } from './Contexts/CourseContext';

export default function BreadCrumb(props) {


    const training = useContext(TrainingContext);
    const course = useContext(CourseContext);

    let t = '';
    let c = '';
    if ( training != null ) 
      t = <li className="breadcrumb-item"><span><Link to={{ pathname: `/trainings/overview/${training.id}`}} >{training.name}</Link></span></li>
    
    if ( course != null ) 
      c = <li className="breadcrumb-item"><span><Link to={{ pathname: `/training/${training.id}/course/${course.id}/overview`}} >{course.name}</Link></span></li>
 
    return (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {t} 
            {c}
          </ol>
        </nav>
    );
}