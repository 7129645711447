import React, {useState, useEffect, useCallback} from 'react';
import {Delete} from '../backend';

import './FileDetails.css'
import { confirmAlert } from 'react-confirm-alert'; 

export default function FileDetails(props) {

    const [deleteFile, setDeleteFile] = useState(false);

    useEffect(() => {
        async function DeleteFile(filedetails) {
            await Delete("/file/removefile", {path: filedetails});
        }

        if( deleteFile ) {
            DeleteFile(props.file.path + "/" + props.file.filename);
            setDeleteFile(false);
            onClose();
        }

    },[deleteFile]);


    const removeFile = () => {
        confirmAlert({
            title: 'Delete?',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  setDeleteFile(true);
                }
              },
              {
                label: 'No',                
              }
            ]
          });
    }

    const onClose = () => {        
        if( props.onClose ) {
            props.onClose();            
        }        
    }

    let c = "file-details-container"
    let file = props.file;
    if( file != undefined && file.filename != undefined && file.filename.length > 0 ) {
        c += " open";
    }
    




    return (        
           <div className={c}>
               <div className="close" onClick={() => onClose() } ></div>
               
               <div className="file-details">                   
                    <div className="file-header"><h4>File details</h4></div>     

                    {file && file.filename} 

                    <div className="file-actions">
                        <button type="button" onClick={removeFile} className="btn btn-sm btn-danger btn-rounded btn-fw">Remove file</button>
                    </div>
               </div>
           </div> 
    );
}
