import TabContainer from '../../../components/TabContainer';
import MainForm from './MainForm';
import TemplateContentManager from '../../../components/TemplateContentManager';
import ACL from './ACL';


export default function Tabs(props) {

    if (props.content === undefined || props.content === null) {
        return <div>No content passed to props</div>
    }

    const updateContentId = (templateContentId) => {
        props.setContent({ ...props.content, templateContentId: templateContentId });
        props.onContentSave();
    }

    return (
        <div className="content-wrapper">
            <div className="page-header">
                <h3 className="page-title">Content</h3>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">                    
                    <TabContainer>
                        <MainForm TabName="Details" TabId="1" TabActive={true} key="1" content={props.content} onDelete={props.onDelete} setContent={props.setContent} onContentSave={props.onContentSave} />
                        <TemplateContentManager TabName="Contents" TabId="2" key="2" TabActive={false} contentId={props.content.templateContentId} onContentSave={updateContentId} ></TemplateContentManager>
                        <ACL TabName="User groups" TabId="4" TabActive={false} key="4" content={props.content} />
                    </TabContainer>
                </div>
            </div>
        </div>
    );
}