import React, { useEffect, useState } from "react";
import TabContainer from "../../../components/TabContainer";
import ConfigurableContent from "../../../components/ConfigurableContent";

import DetailsTab from "./DetailsTab";
import ContentTab from "./ContentTab";
// import ConfigurationTab from './ConfigurationTab';
import { useParams, Link } from "react-router-dom";
import UserGroupsTab from "./UserGroupsTab";
import { confirmAlert } from "react-confirm-alert";
import { Delete } from "../../../backend";

export default function TrainingsPage(props) {
  let params = useParams();

  const [deleteTraining, setDeleteTraining] = useState(false);
  const trainingId = params.trainingId;

  useEffect(() => {
    async function doDeleteRequest() {
      await Delete(`/training/${trainingId}`);
      window.location = "/";
    }

    if (deleteTraining && trainingId != null) {
      doDeleteRequest();
    }
  });

  const deleteTrainingOnClick = function () {
    confirmAlert({
      title: "Delete?",
      message: "Are you sure to do this. This cannot be undone!",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setDeleteTraining(true);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="content-wrapper">
      <div className="page-header">
        <h3 className="page-title">Trainings</h3>
        <Link to={{ pathname: `/trainings/overview/${params.trainingId}` }}>
          <button
            type="button"
            className="btn btn-primary btn-rounded btn-icon"
          >
            <i className="icon-home"></i>
          </button>
        </Link>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <TabContainer>
            <DetailsTab TabName="Details" TabId="1" TabActive={true} key="1" />
            <ContentTab
              TabName="Contents"
              TabId="2"
              TabActive={false}
              key="2"
            />
            {/* <ConfigurationTab TabName="Configurations" TabId="3" TabActive={false} key="3" /> */}
            <UserGroupsTab
              TabName="User groups"
              TabId="4"
              TabActive={false}
              key="4"
            />
            <ConfigurableContent
              TabName="Conf content"
              TabId="5"
              TabActive={false}
              key="5"
              OwnerName="Training"
              OwnerId={trainingId}
              ConfigurationPart="trainingConfiguration"
            />
          </TabContainer>
        </div>
      </div>
      <div className="col-md-12 grid-margin stretch-card">
        <button className="btn btn-danger" onClick={deleteTrainingOnClick}>
          Delete training
        </button>
      </div>
    </div>
  );
}
